import gql from 'graphql-tag';

export const sites = {
	query: gql`
		{
			sites {
				siteAddress
				siteName
				_id
				isEnabled
				siteInfo
				clientId
				client {
					_id
					clientName
					isEnabled
					managers {
						name
						contactNumber
						email
					}
				}
				defaultManager {
					name
					contactNumber
					email
				}
				emailFrequency
				emailList
			}
		}
	`,
	update: data => {
		data.sites.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		return data.sites;
	},
};

export const enabledSites = {
	query: gql`
		{
			enabledSites {
				siteAddress
				siteName
				_id
				isEnabled
				siteInfo
				clientId
				client {
					_id
					clientName
					isEnabled
					managers {
						name
						contactNumber
						email
					}
				}
				defaultManager {
					name
					contactNumber
					email
				}
				emailFrequency
				emailList
			}
		}
	`,
	update: data => {
		data.enabledSites.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		data.enabledSites.sort((a, b) => a.siteName.localeCompare(b.siteName));
		return data.enabledSites;
	},
};

export const disabledSites = {
	query: gql`
		{
			disabledSites {
				siteAddress
				siteName
				_id
				isEnabled
				siteInfo
				clientId
				client {
					_id
					clientName
					isEnabled
					managers {
						name
						contactNumber
						email
					}
				}
				defaultManager {
					name
					contactNumber
					email
				}
				emailFrequency
				emailList
			}
		}
	`,
	update: data => {
		data.disabledSites.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		data.disabledSites.sort((a, b) => a.siteName.localeCompare(b.siteName));
		return data.disabledSites;
	},
};

export const addSite = gql`
	mutation (
		$siteName: String!
		$siteAddress: String!
		$siteInfo: String
		$defaultManager: ClientManagersInput
		$clientId: ID
		$isEnabled: Boolean!
		$emailFrequency: String
		$emailList: [String]
	) {
		addSite(
			siteName: $siteName
			siteAddress: $siteAddress
			siteInfo: $siteInfo
			defaultManager: $defaultManager
			clientId: $clientId
			isEnabled: $isEnabled
			emailFrequency: $emailFrequency
			emailList: $emailList
		) {
			_id
			isEnabled
			emailFrequency
		}
	}
`;

export const updateSite = gql`
	mutation (
		$id: ID!
		$siteName: String!
		$siteAddress: String!
		$siteInfo: String
		$defaultManager: ClientManagersInput
		$clientId: ID
		$isEnabled: Boolean!
		$emailFrequency: String
		$emailList: [String]
	) {
		updateSite(
			siteName: $siteName
			siteAddress: $siteAddress
			id: $id
			siteInfo: $siteInfo
			defaultManager: $defaultManager
			clientId: $clientId
			isEnabled: $isEnabled
			emailFrequency: $emailFrequency
			emailList: $emailList
		) {
			siteName
			siteAddress
			_id
			isEnabled
			emailFrequency
		}
	}
`;

export const deleteSite = gql`
	mutation ($id: ID!) {
		deleteSite(id: $id) {
			_id
		}
	}
`;
