import gql from "graphql-tag";

export const vehicles = {
  query: gql`
    {
      vehicles {
        rego
        color
        regoExpiry
        wofExpiry
        nextServiceDate
        make
        model
        year
        _id
        notes
        photos
      }
    }
  `,
  //   update: (data) => data.vehicles,

  update: (data) => {
    data.vehicles.forEach((item) => {
      if (item["_id"]) {
        item.id = item["_id"];
      }
    });
    return data.vehicles;
  },
};

export const addVehicle = gql`
  mutation (
    $rego: String
    $color: String!
    $regoExpiry: String
    $wofExpiry: String
    $nextServiceDate: String
    $make: String!
    $model: String!
    $year: String
    $notes: String
    $photos: [String]
  ) {
    addVehicle(
      rego: $rego
      color: $color
      regoExpiry: $regoExpiry
      wofExpiry: $wofExpiry
      nextServiceDate: $nextServiceDate
      make: $make
      model: $model
      year: $year
      notes: $notes
      photos: $photos
    ) {
      rego
      color
      regoExpiry
      wofExpiry
      nextServiceDate
      make
      model
      year
      _id
      notes
      photos
    }
  }
`;

export const updateVehicle = gql`
  mutation (
    $id: ID!
    $rego: String
    $color: String
    $regoExpiry: String
    $wofExpiry: String
    $nextServiceDate: String
    $make: String
    $model: String
    $year: String
    $notes: String
    $photos: [String]
  ) {
    updateVehicle(
      rego: $rego
      color: $color
      regoExpiry: $regoExpiry
      wofExpiry: $wofExpiry
      nextServiceDate: $nextServiceDate
      make: $make
      model: $model
      year: $year
      id: $id
      notes: $notes
      photos: $photos
    ) {
      rego
      color
      regoExpiry
      wofExpiry
      nextServiceDate
      make
      model
      year
      _id
      notes
      photos
    }
  }
`;

export const deleteVehicle = gql`
  mutation ($id: ID!) {
    deleteVehicle(id: $id) {
      rego
      color
      regoExpiry
      wofExpiry
      nextServiceDate
      make
      model
      year
      _id
      notes
      photos
    }
  }
`;
