<template>
	<v-col v-if="isAdmin" style="height: 100%">
		<div :style="isMobile() ? `width: 100%` : `width: 70vw`" class="container">
			<div :class="isMobile() ? `text-center` : `d-flex`">
				<v-icon :size="isMobile() ? `40` : `50`" color="secondary"
					>mdi-office-building-marker</v-icon
				>
				<h1 v-if="!isMobile()" class="ma-4">Sites</h1>
				<h3 v-else class="ma-4">Sites</h3>
			</div>
			<v-fade-transition>
				<v-row>
					<v-col :cols="isMobile() ? `` : `6`" class="mt-4">
						<!-- @keyup="initSearch()" -->
						<v-text-field
							v-model="search"
							outlined
							dense
							label="Search Sites"
							@input="searchList = []"
						>
						</v-text-field>
					</v-col>

					<!-- dropdown filter -->
					<v-col cols="3" class="text-center mt-4" v-if="!isMobile()">
						<v-select
							v-model="siteStatus"
							:items="filterSites"
							color="#FF9900"
							width="100%"
							height="40px"
							outlined
							dense
						>
						</v-select>
					</v-col>

					<!-- add site button -->
					<v-col cols="3" class="text-center mt-4" v-if="!isMobile()">
						<v-btn color="#FF9900" width="100%" height="40px" @click="initAddSite()">
							<v-icon small>mdi-plus</v-icon>
							Add Site
						</v-btn>
					</v-col>
				</v-row>
			</v-fade-transition>

			<v-dialog v-model="siteDialog" style="z-index: 999999" max-width="800" persistent>
				<v-card height="70vh" style="overflow-y: scroll">
					<v-row class="ma-4" v-if="selectedSite">
						<v-col cols="6">
							<h2>{{ mainDialog ? `Site Details` : `Site Checkpoints` }}</h2>
						</v-col>
						<v-col cols="6" :class="$vuetify.breakpoint.width < 600 ? `text-center` : `text-right`">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										size="32"
										@click="mainDialog = !mainDialog"
										color="secondary"
										class="ml-3"
										v-bind="attrs"
										v-on="on"
										>{{ mainDialog ? `mdi-map-marker-multiple` : `mdi-list-box` }}</v-icon
									>
								</template>
								<span>
									{{ mainDialog ? `View Checkpoints` : `View Site Details` }}
								</span>
							</v-tooltip>
							<!-- <v-tooltip bottom v-if="!isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="deleteSiteConfirmation()"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete-alert</v-icon
                    >
                  </template>
                  <span>Remove Site</span>
                </v-tooltip> -->

							<!-- enable / disable site -->
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										size="32"
										@click="selectedSite.isEnabled = !selectedSite.isEnabled"
										:color="selectedSite.isEnabled ? `green darken-1` : `red`"
										class="ml-3"
										v-bind="attrs"
										v-on="on"
										>{{
											selectedSite.isEnabled
												? `mdi-office-building-marker`
												: `mdi-office-building-marker`
										}}</v-icon
									>
								</template>
								<span>
									{{ selectedSite.isEnabled ? `Disable Site` : `Enable Site` }}
								</span>
							</v-tooltip>

							<!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="selectedSite.isEnabled = !selectedSite.isEnabled"
                      :color="selectedSite.isEnabled ? `green darken-1` : `red`"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >{{
                        selectedSite.isEnabled
                          ? `mdi-account`
                          : `mdi-account-cancel`
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    selectedSite.isEnabled ? `Disable Client` : `Enable Client`
                  }}</span>
                </v-tooltip> -->

							<!-- save / update site button -->
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										@click="
											() => {
												isAdd ? addSite() : updateSite();
											}
										"
										color="secondary"
										class="ml-3"
										v-bind="attrs"
										:loading="loading"
										icon
										v-on="on"
									>
										<v-icon size="32">mdi-content-save</v-icon>
									</v-btn>
								</template>
								<span>{{ isAdd ? `Add Site` : `Update Site` }}</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										@click="
											siteDialog = false;
											mainDialog = true;
										"
										large
										color="secondary"
										class="ml-3"
										v-bind="attrs"
										v-on="on"
										>mdi-close</v-icon
									>
								</template>
								<span>Exit</span>
							</v-tooltip>
						</v-col>
					</v-row>
					<div class="pa-6" v-if="selectedSite" v-show="selectedSite && mainDialog">
						<!-- <v-row>
							<v-col cols="12" :class="$vuetify.breakpoint.width < 600 ? `text-center` : ``">
								<h2>
									{{ isAdd ? `Add Site` : `Update Site` }}
								</h2>
							</v-col>
						</v-row> -->

						<v-form ref="form" lazy-validation>
							<v-row>
								<v-col>
									<p class="ma-0 mb-2" style="font-size: 14px">Site Name</p>
									<v-text-field
										v-model="selectedSite.siteName"
										:rules="[rules.required]"
										outlined
										label="Site Name"
									/>
									<p style="font-size: 14px">Site Address</p>
									<v-text-field
										v-model="selectedSite.siteAddress"
										:rules="[rules.required]"
										outlined
										label="Site Address"
									/>

									<v-row>
										<!-- clients -->
										<v-col cols="12" lg="6" md="6" sm="12">
											<p style="font-size: 14px">Client</p>
											<v-select
												v-model="selectedSite.client"
												item-text="clientName"
												:items="enabledClients"
												return-object
												single-line
												outlined
											></v-select>
										</v-col>

										<!-- site manager -->
										<v-col cols="12" lg="6" md="6" sm="12">
											<p style="font-size: 14px">Site Manager</p>
											<v-select
												:disabled="clientManagerList.length == 0"
												item-text="name"
												v-model="selectedSite.defaultManager"
												:hint="
													selectedSite.defaultManager
														? selectedSite.defaultManager.contactNumber +
														  ', ' +
														  selectedSite.defaultManager.email
														: ``
												"
												:items="clientManagerList"
												:persistent-hint="hints"
												return-object
												single-line
												outlined
											></v-select>
										</v-col>

										<!-- email frequency -->
										<v-col cols="12" lg="6" md="6" sm="12">
											<p style="font-size: 14px">Email Frequency</p>
											<v-select
												v-model="selectedSite.emailFrequency"
												item-text="frequency"
												:items="frequency"
												return-object
												single-line
												outlined
											></v-select>
										</v-col>
										<!-- email list -->
										<v-col cols="12" lg="6" md="6" sm="12" v-if="enabledClients">
											<p style="font-size: 14px">
												Email To
												<span style="color: #ff9900; font-size: 11px">
													(If Left Blank The Site Manager Will Be Emailed)</span
												>
											</p>

											<v-select
												v-model="selectedEmailToList"
												item-text="name"
												:items="clientManagerList"
												return-object
												single-line
												outlined
												multiple
											>
												<template v-slot:item="{ item, on, attrs }">
													<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
														<v-list-item-action>
															<v-checkbox :ripple="true" :input-value="active"></v-checkbox>
														</v-list-item-action>
														<v-list-item-content>
															<v-list-item-title>
																<v-row no-gutters align="center">
																	<div class="mt-3">
																		<h4>{{ item.name }}</h4>
																		<p style="font-size: 12px; color: grey">
																			{{ item.email }}
																		</p>
																	</div>
																</v-row>
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</template>
												<template v-slot:selection="{ item, index }">
													<v-chip v-if="index === 0" class="chip-overflow" color="primary">
														<span>{{ item.name }}</span>
													</v-chip>
													<v-chip
														v-if="index === 1 && !isMobile()"
														class="chip-overflow"
														color="primary"
													>
														<span>{{ item.name }}</span>
													</v-chip>
													<span v-if="index === 2" class="grey--text text-caption">
														(+{{ selectedSite.emailList.length - 2 }} others)
													</span>
												</template>
											</v-select>
										</v-col>
									</v-row>

									<p style="font-size: 14px">Site Description</p>
									<v-textarea
										filled
										v-model="selectedSite.siteInfo"
										label="Site information here as guard instructions"
									></v-textarea>

									<v-dialog color="red" v-model="deleteSiteDialog" persistent max-width="320">
										<v-card color="red">
											<v-card-title class="text-h5">
												<v-icon class="mr-2" color="yellow" large> mdi-alert </v-icon>
												Delete Confirmation
											</v-card-title>
											<v-card-text class="black--text mt-2"
												><b> Are you sure you want to delete this site? </b></v-card-text
											>
											<v-card-actions class="justify-center">
												<v-spacer></v-spacer>
												<v-btn color="black " text @click="deleteSiteDialog = false">
													Cancel
												</v-btn>
												<v-btn
													class="white--text"
													color="yellow darken-1"
													text
													:loading="loading"
													@click="deleteSite()"
												>
													Confirm
												</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-col>
							</v-row>
						</v-form>
					</div>
					<div v-show="selectedSite && mainDialog == false">
						<v-data-table
							dense
							:headers="checkpointHeaders"
							:items="checkpointsList"
							item-key="_id"
							class="elevation-1"
							mobile-breakpoint="0"
							:items-per-page="25"
							:footer-props="{
								'items-per-page-options': [25, 40, 50, -1],
							}"
							sort-by="dateCreated"
							sort-desc
						>
							<template v-slot:top>
								<v-row class="pa-4 d-flex">
									<v-col v-show="!isMobile()" cols="12" lg="12" md="12" sm="12" xs="12">
										<v-btn @click="initCheckpoint()" color="#EA690B" class="white--text" block>
											New Checkpoint
										</v-btn>
									</v-col>
									<v-fab-transition>
										<v-btn
											v-show="isMobile()"
											color="secondary"
											dark
											fixed
											bottom
											right
											fab
											class="mb-12"
											@click="initCheckpoint()"
										>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</v-fab-transition>
								</v-row>
							</template>

							<template v-slot:item="row">
								<tr>
									<td>
										<v-img
											:src="row.item.qrCodeUrl"
											height="50"
											width="50"
											:aspect-ratio="1"
											style="cursor: pointer"
											@click="viewQrCode(row.item.qrCodeUrl)"
										/>
									</td>
									<td>
										{{ row.item.checkpoint }}
									</td>

									<td>
										<v-icon @click="initCheckpoint(row.item)" small> mdi-eye </v-icon>
									</td>
								</tr>
							</template>
						</v-data-table>
					</div>
				</v-card>
			</v-dialog>

			<div
				:style="
					isMobile()
						? `
          max-height: 61vh;
          overflow-y: scroll;
          border-top-style: solid;
          border-bottom-style: solid;
          border-color: gray;
          border-width: 1px;
          `
						: ``
				"
				class="hide-scrollbar pa-1"
			>
				<v-data-table
					:headers="headers"
					:items="siteList"
					:search="search"
					:items-per-page="15"
					:footer-props="{
						'items-per-page-options': [15, 25, 50, -1],
					}"
					class="elevation-1 mt-1 mb-5"
					item-key="name"
					mobile-breakpoint="0"
					loading-text="Loading... Please wait"
				>
					<template #[`item.edit`]="{ item, index }">
						<td>
							<v-icon @click="editSite(item, index)" class="ml-1" small>mdi-eye</v-icon>
						</td>
					</template>
				</v-data-table>
			</div>

			<v-fab-transition>
				<v-btn
					v-show="isMobile()"
					color="secondary"
					dark
					fixed
					bottom
					right
					fab
					class="mb-12"
					@click="initAddSite()"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>
		</div>
		<!-- View Existing QR Code Dialog -->
		<v-dialog v-model="viewQrCodeDialog" height="350" width="350">
			<v-card flat>
				<v-img :src="this.qrCodeUrl" />
			</v-card>
		</v-dialog>

		<!-- Checkpoint Dialog -->
		<v-dialog v-model="checkpointDialog" v-if="selectedCheckpoint" width="600" persistent>
			<v-card>
				<v-card-title>
					{{ this.isAdd == true ? `Create Checkpoint` : `Viewing Checkpoint` }}
					<v-spacer></v-spacer>
					<v-btn
						v-if="this.isAdd == false"
						@click="initDeleteCheckpoint()"
						class="mr-2"
						color="primary"
						small
					>
						<v-icon> mdi-delete </v-icon>
					</v-btn>
					<v-btn @click="printQrCode()" class="mr-2" color="primary" small>
						<v-icon> mdi-printer </v-icon>
					</v-btn>
					<v-btn @click="checkpointDialog = false" color="primary" small>
						<v-icon> mdi-close </v-icon>
					</v-btn>
				</v-card-title>
				<v-form ref="qrForm">
					<v-container class="pa-6">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="selectedCheckpoint.checkpoint"
									label="Checkpoint Name"
									outlined
									:rules="requiredRule"
									:readonly="this.isAdd == true ? false : true"
								/>
								<!-- <v-select
									v-if="this.isAdd == true"
									v-model="selectedCheckpoint.siteLocation"
									:items="enabledSites"
									item-text="siteName"
									label="Site Location"
									hide-no-data
									cache-items
									outlined
									return-object
									:rules="requiredRule"
								/>
								<v-text-field
									v-else
									v-model="selectedCheckpoint.siteLocation"
									label="Site Location"
									outlined
									readonly
								/> -->
								<div v-if="this.isAdd == true && !this.qrCodeUrl" class="text-center">
									<v-btn :loading="this.isLoading" @click="generateQrCode()" color="primary">
										Generate QR Code
									</v-btn>
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-container v-if="this.isAdd == true" class="d-flex justify-center">
								<v-expand-transition>
									<v-card v-if="this.isGenerate == true" height="250" width="250" flat>
										<v-img :src="this.qrCodeUrl" />
									</v-card>
								</v-expand-transition>
							</v-container>
							<v-container v-else class="d-flex justify-center">
								<v-expand-transition>
									<v-card height="250" width="250" flat>
										<v-img :src="this.selectedCheckpoint.qrCodeUrl" />
									</v-card>
								</v-expand-transition>
							</v-container>
						</v-row>
					</v-container>
				</v-form>
			</v-card>
		</v-dialog>

		<!--  Print Dialog -->
		<v-dialog v-model="printDialog" fullscreen>
			<v-card flat>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn @click="printDialog = false" id="noPrint" icon>
						<v-icon> mdi-close </v-icon>
					</v-btn>
				</v-card-title>
				<v-container
					v-if="this.isGenerate == true && this.isAdd == true"
					id="toPrint"
					ref="printContentRef"
					class="text-center"
				>
					<v-row no-gutters>
						<v-col cols="12">
							<img class="guardexLogo" :src="require('../../public/img/Guardex-Logo.png')" />
						</v-col>
						<v-col cols="12" class="d-flex justify-center align-center">
							<v-img :src="this.qrCodeUrl" max-height="300" max-width="300" />
						</v-col>
						<v-col v-if="this.selectedCheckpoint" cols="12">
							<p style="font-size: 36px; font-weight: 600">
								{{ this.selectedCheckpoint.siteLocation.siteName }}
							</p>
						</v-col>
						<v-col v-if="this.selectedCheckpoint" cols="12">
							<p style="font-size: 62px; font-weight: 700">
								{{ this.selectedCheckpoint.checkpoint }}
							</p>
						</v-col>
					</v-row>
				</v-container>
				<v-container
					v-if="this.isGenerate == true && this.isAdd == false"
					id="toPrint"
					ref="printContentRef"
					class="text-center"
				>
					<v-row no-gutters>
						<v-col cols="12">
							<img class="guardexLogo" :src="require('../../public/img/Guardex-Logo.png')" />
						</v-col>
						<v-col cols="12" class="d-flex justify-center align-center">
							<v-img :src="this.selectedCheckpoint.qrCodeUrl" max-height="300" max-width="300" />
						</v-col>
						<v-col v-if="this.selectedCheckpoint" cols="12">
							<p style="font-size: 36px; font-weight: 600">
								{{ this.selectedCheckpoint.siteLocation }}
							</p>
						</v-col>
						<v-col v-if="this.selectedCheckpoint" cols="12">
							<p style="font-size: 62px; font-weight: 700">
								{{ this.selectedCheckpoint.checkpoint }}
							</p>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<!-- Delete Confirmation Dialog -->
		<v-dialog v-model="deleteConfirmationDialog" height="350" width="350">
			<v-card flat>
				<v-card-title> Confirm Delete </v-card-title>
				<v-card-text> Are you sure you want to delete this checkpoint? </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="deleteConfirmationDialog = false" text> Cancel </v-btn>
					<v-btn @click="deleteCheckpoint()" color="primary"> Confirm </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-col>
</template>

<script>
	import {
		sites,
		addSite,
		updateSite,
		deleteSite,
		disabledSites,
		enabledSites,
	} from '../graphql/sites';
	import { enabledClients } from '../graphql/clients';
	import { addCheckpoint, deleteCheckpoint, filterCheckpoints } from '../graphql/checkpoint';
	import QRCode from 'qrcode';

	const pako = require('pako');

	export default {
		methods: {
			initDeleteCheckpoint() {
				this.deleteConfirmationDialog = true;
			},
			async printQrCode() {
				if (!this.isGenerate) return;
				this.printDialog = true;
				setTimeout(() => {
					print();
				}, 1000);
				onafterprint = async () => {
					//  close the dialog when printer dialog is closed
					this.printDialog = false;
				};
			},
			toBase64(data) {
				const toConvert = `${data.checkpoint}#${data.siteLocation.siteName}#${data.siteId}`;
				return btoa(toConvert);
			},
			generateQrCode() {
				let isValid = this.$refs.qrForm.validate();
				if (!isValid) return;
				try {
					this.isLoading = true;
					this.isGenerate = true;
					let data = this.selectedCheckpoint;
					let encode = this.toBase64(data);
					let qrData = [
						{
							data: encode,
							mode: 'byte',
						},
					];
					let qrUrl;
					QRCode.toDataURL(qrData, function (err, url) {
						qrUrl = url;
						if (err) {
							console.warn(err);
						}
					});
					this.qrCodeUrl = qrUrl;
					this.createCheckpoint();
				} catch (error) {
					console.warn(`Error: ${error}`);
				}
				this.isLoading = false;
			},
			async createCheckpoint() {
				let data = this.selectedCheckpoint;
				let input = {
					checkpoint: data.checkpoint,
					siteLocation: data.siteLocation.siteName,
					qrCodeUrl: this.qrCodeUrl,
					siteId: data.siteLocation.id,
				};

				try {
					let result = await this.$apollo.mutate({
						mutation: addCheckpoint,
						variables: input,
					});

					this.checkpointsList.push(result.data.addCheckpoint);
				} catch (error) {
					console.warn(error);
				}
			},

			async deleteCheckpoint() {
				try {
					let result = await this.$apollo.mutate({
						mutation: deleteCheckpoint,
						variables: {
							id: this.selectedCheckpoint['_id'],
						},
					});
					if (result) {
						this.deleteConfirmationDialog = false;
						this.checkpointDialog = false;
					}

					let checkpointIndex = await this.checkpointsList.findIndex(obj => {
						return obj['_id'] == this.selectedCheckpoint['_id'];
					});

					this.checkpointsList.splice(checkpointIndex, 1);
				} catch (error) {
					console.warn('Error deleting checkpoint: ', error);
				}
			},

			initCheckpoint(checkpoint) {
				this.qrCodeUrl = '';
				if (!checkpoint) {
					this.isAdd = true;
					this.$store.commit('checkpoint/resetSelectedCheckpoint');
					this.$store.commit('checkpoint/setSelectedCheckpoint', {
						siteId: this.selectedSite.id,
						siteLocation: this.selectedSite,
					});
				} else {
					this.isAdd = false;
					this.isGenerate = true;
					this.$store.commit('checkpoint/setSelectedCheckpoint', checkpoint);
				}
				this.checkpointDialog = true;
			},
			viewQrCode(url) {
				if (!url) return;
				this.qrCodeUrl = url;
				this.viewQrCodeDialog = true;
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},

			showEnabledClients() {
				this.$apollo.addSmartQuery('clientsList', enabledClients);
			},

			showEnabledSites() {
				this.$apollo.addSmartQuery('siteList', enabledSites);
			},

			showDisabledSites() {
				this.$apollo.addSmartQuery('siteList', disabledSites);
			},

			initAddSite() {
				this.isAdd = true;
				this.selectedSite = {
					siteName: null,
					siteAddress: null,
					id: null,
					isEnabled: true,
				};
				this.siteDialog = true;
			},

			async deleteSite() {
				alert('Cannot delete site for now.');
				// this.loading = true;
				// let res = await this.$apollo.mutate({
				//   mutation: deleteSite,
				//   variables: {
				//     id: this.selectedSite.id,
				//   },
				// });
				// this.siteList = await this.siteList.filter(
				//   (obj) => obj.id != res.data.deleteSite["_id"]
				// );
				// this.siteList = await this.siteList.filter(
				//   (obj) => obj["_id"] != res.data.deleteSite["_id"]
				// );
				// this.deleteSiteDialog = false;
				// this.siteDialog = false;
				// (this.loading = false),
				//   (this.color = "red "),
				//   (this.notificationText = "Site Deleted"),
				//   (this.notify = true);
			},

			async addSite() {
				this.loading = true;
				let valid = this.$refs.form.validate();
				if (!valid) {
					this.loading = false;
					return;
				}
				if (this.isAdd) {
					await this.$apollo.mutate({
						mutation: addSite,
						variables: {
							...this.selectedSite,
						},
						update: (store, { data: { addSite } }) => {
							let newSite = {
								...this.selectedSite,
								id: addSite['_id'],
							};
							this.siteList.push(newSite);
						},
					});

					this.siteDialog = false;
					(this.loading = false),
						(this.color = 'green darken-1'),
						(this.notificationText = 'Added Site Successfully'),
						(this.notify = true);
				} else {
					this.updateSite();
				}
			},

			async updateSite() {
				this.mainDialog = true;
				this.$forceUpdate();
				this.loading = true;

				let valid = this.$refs.form.validate();
				if (!valid) {
					this.loading = false;
					return;
				}
				if (valid) {
					await this.$apollo.mutate({
						mutation: updateSite,
						variables: this.selectedSite,
					});

					if (
						(this.siteStatus == 'Active' && this.selectedSite.isEnabled == false) ||
						(this.siteStatus == 'Inactive' && this.selectedSite.isEnabled == true)
					) {
						this.siteList.splice(this.selectedSiteIndex, 1);
						this.selectedSiteIndex = null;
					}

					this.siteDialog = false;
					(this.loading = false),
						(this.color = 'green darken-1'),
						(this.notificationText = 'Site Updated'),
						(this.notify = true);
				}
			},

			async editSite(site, i) {
				let res = await this.$apollo.query({
					query: filterCheckpoints,
					variables: {
						siteId: site.id,
					},
				});

				this.checkpointsList = res.data.filterCheckpoints;

				this.selectedSiteIndex = i;
				this.isAdd = false;
				this.selectedSite = site;

				this.siteDialog = true;
			},
			deleteSiteConfirmation() {
				this.deleteSiteDialog = true;
			},
		},

		apollo: {
			enabledSites: enabledSites,
			enabledClients: enabledClients,
			disabledSites: disabledSites,
		},

		async mounted() {
			this.selectedSite = null;
			this.showEnabledSites();
			this.showEnabledClients();
		},

		computed: {
			selectedCheckpoint: {
				get() {
					return this.$store.getters['checkpoint/selectedCheckpoint'];
				},
			},
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
		},

		watch: {
			selectedEmailToList: function (val) {
				let emailList = [];

				val.forEach(manager => {
					emailList.push(manager.email);
				});

				if (this.selectedSite) {
					this.selectedSite.emailList = emailList;
				}
			},
			'selectedSite.client': function (val) {
				if (val) {
					this.hints = false;
					this.clientManagerList = val.managers;
					this.selectedSite.clientId = val['_id'];

					this.clientManagerList.forEach(manager => {
						if (this.selectedSite.emailList.length > 0) {
							this.selectedSite.emailList.forEach(email => {
								if (manager.email == email) {
									this.selectedEmailToList.push(manager);
								}
							});
						} else {
							return;
						}
					});
				} else {
					this.clientManagerList = [];
					this.selectedEmailToList = [];
				}
			},
			'selectedSite.defaultManager': function (val) {
				if (val) {
					this.hints = true;
					delete val['__typename'];
					this.selectedSite.defaultManager = val;
				}
			},
			siteDialog: function (val) {
				if (!val) {
					this.selectedSite = null;
					this.selectedEmailToList = [];
				}
			},
			siteStatus: function () {
				if (this.siteStatus === 'Active') {
					this.showEnabledSites();
				} else if (this.siteStatus === 'Inactive') {
					this.showDisabledSites();
				} else return this.showEnabledSites();
			},
		},

		data() {
			return {
				deleteConfirmationDialog: false,
				printDialog: false,
				isGenerate: false,
				isLoading: false,
				requiredRule: [v => !!v || 'Required Field'],
				checkpointDialog: false,
				viewQrCodeDialog: '',
				qrCodeUrl: '',
				checkpointsList: [],
				mainDialog: true,
				selectedEmailToList: [],
				frequency: ['Daily', 'Weekly'],
				selectedSiteIndex: null,
				notify: false,
				notificationText: '',
				color: '',
				hints: false,
				clientList: [],
				clientManagerList: [],
				selectedClientName: null,
				selectedClientManager: null,
				expanded: [],
				search: '',
				siteDialog: false,
				confirmationDialog: false,
				isAdd: false,
				loading: false,
				siteList: [],
				selectedSite: null,
				deleteSiteDialog: false,
				siteStatus: 'Active',
				filterSites: ['Active', 'Inactive'],

				//rules
				rules: {
					required: v => !!v || 'This is Required!',
				},
				headers: [
					{
						text: 'Site Name',
						align: 'start',
						sortable: false,
						value: 'siteName',
					},
					{ text: 'Site Address', sortable: false, value: 'siteAddress' },
					{ text: 'Edit', sortable: false, value: 'edit' },
				],

				checkpointHeaders: [
					{
						text: 'QR',
						value: 'qrCodeUrl',
					},
					{
						text: 'Checkpoint',
						sortable: true,
						value: 'checkpoint',
					},
					// {
					// 	text: 'Site Location',
					// 	sortable: true,
					// 	value: 'siteLocation',
					// },
					{ text: 'View', sortable: false, value: 'view' },
				],

				selectedSiteFilter: null,
			};
		},
	};
</script>

<style scoped>
	/* Hide scrollbar for Chrome, Safari and Opera */
	.hide-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.hide-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.chip-overflow {
		max-width: 120px;
		padding-top: 6px;
	}
	::v-deep .v-chip__content {
		display: inline-block !important;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media print {
		#noPrint {
			visibility: hidden;
		}
		#toPrint {
			visibility: visible;
			print-color-adjust: exact;
			-webkit-print-color-adjust: exact;
			/* Force print scaling using css */
			/* transform: scale(1.12); */
		}
	}
</style>
