<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="timesheetList"
      :items-per-page="15"
      :footer-props="{
    'items-per-page-options': [15, 25, 50, -1]
  }"
      class="elevation-1"
      sort-by="clockIn.timestamp"
      sort-desc
    >
      <template #[`item.guard`]="{ item }">
        <td v-if="item.guard">{{ item.guard.firstName + " " + item.guard.lastName }}</td>
        <td v-else>Unknown Guard</td>
      </template>

      <template #[`item.clockIn`]="{ item }">
        <td>{{ convertTimestamp(item.clockIn.timestamp) }}</td>
      </template>
      <template #[`item.clockOut`]="{ item }">
        <td>{{ convertTimestamp(item.clockOut.timestamp) }}</td>
      </template>

      <template #[`item.hours`]="{ item }">
        <td>{{ calculateHours(item) }} Hours</td>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import { listClocking } from "../graphql/clocking";
import moment from "moment";

export default {
  apollo: {
    timesheetList: listClocking,
  },
  data () {
    return {
      headers: [
        {
          text: "Guard",
          align: "start",
          value: "guard",
          sortable: false,
        },
        {
          text: "Clock In Time",
          value: "clockIn",
          sortable: false,
        },
        { text: "Clock Out Time", value: "clockOut", sortable: false },
        { text: "Total Hours", value: "hours", sortable: false },
      ],

      timesheetList: [],
      search: "",
    };
  },

  computed: {
    //
  },

  methods: {
    calculateHours (obj) {
      let timeFrom = parseInt(obj.clockIn.timestamp);
      let timeTo = parseInt(obj.clockOut.timestamp);

      let duration = timeTo - timeFrom;

      let hours = duration / 1000 / 60 / 60;
      return hours.toFixed(2);
    },
    convertTimestamp (param) {
      return moment(parseInt(param)).format("DD/MM/YYYY - HH:mm");
    },
  },
};
</script>