import gql from 'graphql-tag';

export const listTodaysIncident = {
	query: gql`
		{
			listTodaysIncident {
				_id
				guardOnDuty
				guardDetails {
					firstName
					lastName
				}
				siteLocationId
				siteLocation {
					siteName
				}
				policeRequired
			}
		}
	`,
	update: data => data.listTodaysIncident,
};

export const incidentResponseList = {
	query: gql`
		{
			incidentResponseList {
				_id
				dateIssued
				incidentNotificationTime
				siteLocationId
				siteLocation {
					siteName
					siteAddress
				}
				currentGeoLocation
				siteArrivalTime
				guardOnDuty
				guardDetails {
					firstName
					lastName
				}
				incidentDescription
				policeRequired
				policeInvolved {
					officerName
					officerStation
					policeEventNumber
					notes
				}
				anyoneInjured
				peopleInjured {
					firstName
					lastName
					injuryDescription
					notes
				}
				additionalDetails
				damagedProperty {
					propertyDamageDetails
				}
				emailSent
				attachments
				dateCreated
			}
		}
	`,
	// update: (data) => data.incidentResponseList,

	update: data => {
		data.incidentResponseList.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		return data.incidentResponseList;
	},
	fetchPolicy: 'cache-and-network',
};

export const addIncidentResponse = gql`
	mutation (
		$dateIssued: String
		$incidentNotificationTime: String
		$siteLocationId: ID
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$incidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$additionalDetails: String
		$emailSent: Boolean
		$attachments: [String]
		$damagedProperty: [DamagedPropertyInput]
		$dateCreated: String
	) {
		addIncidentResponse(
			dateIssued: $dateIssued
			incidentNotificationTime: $incidentNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			incidentDescription: $incidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			additionalDetails: $additionalDetails
			emailSent: $emailSent
			attachments: $attachments
			damagedProperty: $damagedProperty
			dateCreated: $dateCreated
		) {
			dateIssued
			incidentNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			incidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				notes
				injuryDescription
			}
			additionalDetails
			emailSent
			attachments
			damagedProperty {
				propertyDamageDetails
			}
			dateCreated
		}
	}
`;

export const updateIncidentResponse = gql`
	mutation (
		$id: ID!
		$dateIssued: String
		$incidentNotificationTime: String
		$siteLocationId: ID
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$incidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$additionalDetails: String
		$emailSent: Boolean
		$attachments: [String]
		$damagedProperty: [DamagedPropertyInput]
		$dateCreated: String
	) {
		updateIncidentResponse(
			id: $id
			dateIssued: $dateIssued
			incidentNotificationTime: $incidentNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			incidentDescription: $incidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			additionalDetails: $additionalDetails
			emailSent: $emailSent
			attachments: $attachments
			damagedProperty: $damagedProperty
			dateCreated: $dateCreated
		) {
			_id
			dateIssued
			incidentNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			incidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			additionalDetails
			emailSent
			attachments
			damagedProperty {
				propertyDamageDetails
			}
			dateCreated
		}
	}
`;

export const updateIncidentClean = gql`
	mutation (
		$id: ID!
		$dateIssued: String
		$incidentNotificationTime: String
		$siteLocationId: ID
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$incidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$additionalDetails: String
		$emailSent: Boolean
		$attachments: [String]
		$damagedProperty: [DamagedPropertyInput]
		$dateCreated: String
	) {
		updateIncidentClean(
			id: $id
			dateIssued: $dateIssued
			incidentNotificationTime: $incidentNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			incidentDescription: $incidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			additionalDetails: $additionalDetails
			emailSent: $emailSent
			attachments: $attachments
			damagedProperty: $damagedProperty
			dateCreated: $dateCreated
		) {
			_id
			dateIssued
			incidentNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			incidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			additionalDetails
			emailSent
			attachments
			damagedProperty {
				propertyDamageDetails
			}
			dateCreated
		}
	}
`;

export const deleteIncidentResponse = gql`
	mutation ($id: ID!) {
		deleteIncidentResponse(id: $id) {
			_id
			dateIssued
			incidentNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			incidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			additionalDetails
			damagedProperty {
				propertyDamageDetails
			}
			dateCreated
		}
	}
`;

export const INCIDENT_SUBSCRIPTION = gql`
	subscription {
		incidentSub: incident {
			_id
			guardOnDuty
			guardDetails {
				firstName
				lastName
			}
			siteLocationId
			siteLocation {
				siteName
			}
			policeRequired
		}
	}
`;
