export default {
  namespaced: true,
  state: {
    selectedIncidentReport: null,
  },

  mutations: {
    setSelectedIncidentReport(state, payload) {
      if (payload["_id"]) {
        payload.id = payload["_id"];
        delete payload["_id"];
        for (var i = 0; i < payload.policeInvolved.length; i++) {
          delete payload.policeInvolved[i]["__typename"];
        }
        for (var i = 0; i < payload.peopleInjured.length; i++) {
          delete payload.peopleInjured[i]["__typename"];
        }
        for (var i = 0; i < payload.damagedProperty.length; i++) {
          delete payload.damagedProperty[i]["__typename"];
        }
      }

      state.selectedIncidentReport = payload;
    },

    resetSelectedIncidentReport(state) {
      state.selectedIncidentReport = {
        id: null,
        dateIssued: "",
        incidentNotificationTime: "",
        siteLocationId: "", //6268a33c0f34c0500dfcd6f0
        siteLocation: "",
        currentGeoLocation: "",
        siteArrivalTime: "",
        guardOnDuty: "", //6274a33c0f34b0500dfcd6f0
        incidentDescription: "",
        policeRequired: false,
        policeInvolved: [],
        anyoneInjured: false,
        peopleInjured: [],
        additionalDetails: "",
        damagedProperty: [],
        dateCreated: "",
      };
    },

    resetRequiredPolice(state) {
      state.selectedIncidentReport.policeInvolved = [];
    },

    resetInjuredPerson(state) {
      state.selectedIncidentReport.peopleInjured = [];
    },

    resetDamagedProperty(state) {
      state.selectedIncidentReport.damagedProperty = [];
    },
  },

  actions: {
    //
  },

  getters: {
    selectedIncidentReport: (state) => state.selectedIncidentReport,
  },
};