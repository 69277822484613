<template>
	<v-toolbar color="#EA690B" elevation="3" class="safe pt-1" height="50px">
		<v-toolbar-title class="white--text mb-2">
			{{ `${this.$route.name}` }}
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-toolbar-items class="mb-2">
			<!-- <v-tooltip
        bottom
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            dark
            icon
            @click="downloadApp()"
          >
            <v-icon dark>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Download Android APK APP</span>
      </v-tooltip> -->

			<v-btn dark icon @click="openCamera()" v-if="!isAdmin && isClockedIn">
				<v-icon dark>{{ `mdi-qrcode-scan` }}</v-icon>
			</v-btn>
			<v-tooltip bottom color="secondary">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" dark icon @click="changeTheme()">
						<v-icon dark>{{ isDark ? `mdi-shield-sun-outline` : `mdi-shield-moon` }}</v-icon>
					</v-btn>
				</template>
				<span>{{ isDark ? `Light Mode` : `Dark Mode` }}</span>
			</v-tooltip>
			<v-tooltip color="secondary" bottom>
				<template #activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						dark
						v-click-outside="{
							handler: onClickOutside,
							include: include,
						}"
						@click="(models.base = true), openNotification()"
						icon
					>
						<v-icon dark>mdi-bell</v-icon>
						<v-badge
							v-show="!notificationHasSeen"
							color="red"
							:content="notificationCount > 9 ? `9+` : notificationCount"
							overlap
						>
						</v-badge>
					</v-btn>
				</template>
				<span> Notifications </span>
			</v-tooltip>

			<!-- notification card -->
			<v-snackbar
				v-model="notificationCard"
				class="pt-12"
				top
				right
				color="secondary"
				:timeout="notificationTimeout"
				max-height="80vh"
			>
				<v-card
					class="hideScroll included"
					flat
					color="secondary"
					max-height="75vh"
					style="overflow-y: scroll"
				>
					<v-card-title class="ma-0 pa-0 ml-4">
						<h3>Notifications</h3>
						<v-spacer class="mr-2"></v-spacer>
						<v-card-actions>
							<v-btn text @click="notificationCard = false"> Close </v-btn>
						</v-card-actions>
					</v-card-title>
					<v-divider></v-divider>
					<v-list subheader two-line color="secondary" class="included" max-height="55vh">
						<v-list-item
							v-for="(note, i) in combinedNotificationList"
							:key="note.time"
							@click="redirectNotif(note)"
						>
							<v-list-item-content>
								<v-list-item-title> {{ note.message }} </v-list-item-title>
								<v-list-item-subtitle v-show="note.type == 'global'">
									{{ convertDate(note.time) }}
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-icon
									v-show="note.type == 'global'"
									@click="removeNotification(i)"
									class="mb-6"
									small
								>
									mdi-close
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card>
				<v-container v-show="combinedNotificationList.length == 0">
					<v-row class="ma-2" justify="center">
						<span> No new notifications </span>
					</v-row>
				</v-container>
				<v-btn
					@click="clearNotifications"
					v-show="combinedNotificationList.length > 1"
					class="mt-4"
					text
				>
					Clear Notifications
				</v-btn>
			</v-snackbar>

			<v-tooltip color="secondary" bottom>
				<template #activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" dark icon @click="logout()">
						<v-icon dark>mdi-logout</v-icon>
					</v-btn>
				</template>
				<span> Logout </span>
			</v-tooltip>
		</v-toolbar-items>
		<v-dialog v-model="logoutDialog" persistent max-width="320">
			<v-card>
				<v-card-title class="text-h5">
					<v-icon class="mr-2" color="info" large> mdi-information </v-icon>
					Confirm Logout
				</v-card-title>
				<v-spacer></v-spacer>
				<v-card-text class="mt-2"> Are you sure you want to logout? </v-card-text>
				<v-card-actions class="justify-center">
					<v-btn :disabled="isLoading" text @click="logoutDialog = false"> Cancel </v-btn>
					<v-btn
						:loading="isLoading"
						@click="confirmLogout"
						class="white--text"
						color="green darken-1"
						text
					>
						Logout
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- camera display for guard checkpoint -->
		<v-dialog v-model="cameraDialog" fullscreen>
			<v-card id="videoDisplay">
				<v-card-title style="color: black">
					Scan Checkpoint
					<v-spacer></v-spacer>
					<v-btn @click="closeDisplay()" icon color="primary">
						<v-icon> mdi-close </v-icon>
					</v-btn>
				</v-card-title>
				<div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
					<v-progress-circular
						v-if="cameraDialog == true && cameraDisplay == false"
						indeterminate
						color="primary"
						:size="150"
						:width="7"
					></v-progress-circular>
				</div>
				<StreamBarcodeReader
					v-if="cameraDisplay"
					key="camera1"
					ref="qrCodeReader"
					@decode="onDecode($event)"
					@loaded="onLoaded()"
				></StreamBarcodeReader>
			</v-card>
		</v-dialog>

		<!-- Scan Result Notification -->
		<v-snackbar v-model="notify" style="top: -550%" class="pt-16" :timeout="3000" :color="color">
			<div style="width: 100%; min-height: 100px" class="text-center">
				<h3>{{ notificationText }}</h3>
				<p>{{ color == 'red' ? `Scan Guardex Codes Only.` : `` }}</p>
				<v-icon class="mt-2" x-large :color="color == 'red' ? 'black' : 'white'">{{
					color == 'red' ? `mdi-alert-circle` : `mdi-check-circle-outline`
				}}</v-icon>
			</div>
		</v-snackbar>
	</v-toolbar>
</template>
<script>
	import { updateGuardLocation } from '../graphql/guard';
	import { mapGetters } from 'vuex';
	import moment from 'moment';
	import { StreamBarcodeReader } from 'vue-barcode-reader';
	import { createCheckpointEntry } from '../graphql/checkpointEntry';
	import { guard, guards } from '../graphql/guard';

	export default {
		components: {
			StreamBarcodeReader,
		},
		computed: {
			myGeolocation: {
				get() {
					return this.$store.getters['markers'];
				},
			},
			...mapGetters(['isAdmin', 'userInfo']),
			userInfo: {
				get() {
					return this.$store.getters['userInfo'];
				},
			},
			isClockedIn: {
				get() {
					return this.$store.getters['isClockedIn'];
				},
			},
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
			notificationCount: {
				get() {
					return this.$store.getters['notificationCount'];
				},
			},
			notificationList: {
				get() {
					return this.$store.getters['notificationList'];
				},
			},
			notificationTime: {
				get() {
					return this.$store.getters['notificationTime'];
				},
			},
			notificationHasSeen: {
				get() {
					return this.$store.getters['hasSeen'];
				},
			},
			isDark: {
				get() {
					return this.$vuetify.theme.dark;
				},
				set(val) {
					this.$vuetify.theme.dark = val;
				},
			},
			localNotificationList: {
				get() {
					return this.$store.getters['localNotificationList'];
				},
			},
			combinedNotificationList() {
				return this.notificationList.concat(this.localNotificationList);
			},
		},
		async mounted() {
			this.checkTheme();
		},
		data: () => ({
			guardId: '',
			guardName: '',
			color: '',
			notificationText: '',
			notify: false,
			scanComplete: false,
			cameraDisplay: false,
			cameraDialog: false,
			isLoading: false,
			logoutDialog: false,
			notificationCard: false,
			notificationTimeout: 99999999999,
			models: {
				base: false,
			},
		}),

		watch: {
			//
		},

		methods: {
			base64ToBytes(base64) {
				const binString = atob(base64);
				return binString;
			},
			closeDisplay() {
				this.cameraDialog = false;
				this.cameraDisplay = false;
				this.stopCamera();
			},
			async onDecode(result) {
				this.cameraDisplay = false;
				this.stopCamera();
				let guardExist = false;
				let allGuards = await this.$apollo.query({
					query: guards.query,
					fetchPolicy: 'no-cache',
				});

				this.guardId =
					this.$store.getters.userInfo['http://guardex.com/custom'].app_metadata.guard_id;

				let res = await this.$apollo.query({
					query: guard,
					variables: {
						id: this.guardId,
					},
				});

				this.guardName = res.data.selectedGuard.firstName + ' ' + res.data.selectedGuard.lastName;

				const guardList = allGuards.data.guards;
				guardList.forEach(item => {
					if (item['_id'] == this.guardId) {
						guardExist = true;
					}
				});
				if (guardExist == true) {
					this.createCheckpointEntry(result);
				}
			},
			onLoaded() {
				//  When reader loads up.
			},
			async createCheckpointEntry(param) {
				try {
					const decode = this.base64ToBytes(param);
					const parts = decode.split('#');
					const checkpoint = parts[0];
					const siteLocation = parts[1];
					const siteId = parts[2];

					//get geolocation
					const currentGeoLocation =
						this.myGeolocation[0].location.lat + ',' + this.myGeolocation[0].location.lng;

					let input = {
						checkpoint: checkpoint,
						siteLocation: siteLocation,
						siteId: siteId,
						scanTime: moment(new Date()).toISOString(),
						guard: this.guardName,
						guardId: this.guardId,
						currentGeoLocation: currentGeoLocation,
					};
					let result = await this.$apollo.mutate({
						mutation: createCheckpointEntry,
						variables: input,
					});
					if (result) {
						console.log('result: ', result);
						this.notificationText = 'Checkpoint Scanned';
						this.color = 'primary';
						this.notify = true;
						this.cameraDialog = false;
					}
				} catch (error) {
					this.cameraDialog = false;
					this.notificationText = 'Error scanning checkpoint.';
					this.color = 'red';
					this.notify = true;
					console.log(error);
					console.warn('Error creating checkpoint entry: ', error);
				}
			},
			stopCamera() {
				this.$refs.qrCodeReader.codeReader.stream.getTracks().forEach(function (track) {
					track.stop();
				});
			},
			openCamera() {
				this.cameraDialog = true;
				this.cameraDisplay = true;
			},
			downloadApp() {
				window.open('https://pwa.guardex.co.nz/guardex.apk');
			},
			redirectNotif(message) {
				let msg = message.message;

				if (
					(msg.includes('Incident Response') || msg.includes('Alarm Response')) &&
					this.$route.name != 'Reports'
				) {
					this.$router.push('/Reports');
				} else if (
					(msg.includes('has clocked in') || msg.includes('has clocked out')) &&
					this.$route.name != 'Calendar'
				) {
					this.$router.push('/Calendar');
				} else if (msg.includes('Roster created') && this.$route.name != 'Calendar') {
					this.$router.push('/GuardCalendar');
				} else if (msg.includes('Vehicle') && this.$route.name != 'Vehicles') {
					this.$router.push('/Vehicles');
				}

				this.notificationCard = false;
			},
			openNotification() {
				this.notificationCard = !this.notificationCard;
				this.$store.commit('resetHasSeen');
			},
			onClickOutside() {
				this.notificationCard = false;
				this.models.base = false;
			},
			include() {
				return [document.querySelector('.included')];
			},
			removeNotification(index) {
				this.$store.commit('removeNotification', index);
			},
			clearNotifications() {
				this.$store.commit('clearNotification');
			},
			convertDate(date) {
				return moment(parseInt(date)).format('DD MMM HH:mm');
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			checkTheme() {
				if (this.$vuetify.theme.dark) {
					this.$store.commit('setNightMode');
				} else {
					this.$store.commit('setLightMode');
				}
			},
			changeTheme() {
				this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
				localStorage.setItem('isDark', this.$vuetify.theme.dark);
				if (this.$vuetify.theme.dark) {
					this.$store.commit('setNightMode');
				} else {
					this.$store.commit('setLightMode');
				}
			},
			logout() {
				this.logoutDialog = true;
			},

			async confirmLogout() {
				await this.$store.dispatch('clockOut');
				await this.updateGuard();
				await this.$auth.logout();
			},

			async updateGuard() {
				const guardId =
					this.$store.getters['userInfo']['http://guardex.com/custom'].app_metadata.guard_id;

				let variables = {
					id: guardId,
					isClockedIn: false,
				};

				try {
					await this.$apollo.mutate({
						mutation: updateGuardLocation,
						variables: variables,
					});
				} catch (e) {
					console.log(e);
				}
			},
		},
		//
	};
</script>

<style scoped>
	#videoDisplay {
		width: 500px;
		height: 375px;
		background-color: #ffffff;
	}
	.notificationCard {
		z-index: 99;
		top: 60px;
		position: fixed;
		right: 5px;
		border: 2px solid #003366;
	}
	.safe {
		top: 0;
		z-index: 99;
		width: 100vw;
	}

	/* hide scrollbar but allow scrolling */
	.hideScroll {
		-ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none; /* for Firefox */
		overflow-y: scroll;
	}

	.hideScroll::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
</style>
