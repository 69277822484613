var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAdmin)?_c('div',{staticClass:"pb-16 pt-6",style:(!_vm.isMobile()
      ? `max-width: 70vw; margin: 0 auto`
      : `max-width: 95vw;margin: 0 auto`)},[_vm._m(0),(_vm.markers)?_c('v-card',{staticClass:"mx-auto"},[(_vm.center)?_c('GmapMap',{staticStyle:{"width":"100%","height":"60vh"},attrs:{"center":_vm.center,"zoom":12,"options":_vm.options,"map-type-id":"roadmap"}},[_c('gmap-info-window',{staticStyle:{"color":"black"},attrs:{"options":_vm.markerInfo.options,"position":_vm.markerInfo.position,"opened":_vm.markerInfo.isOpened,"content":_vm.markerInfo.content},on:{"closeclick":function($event){_vm.markerInfo.isOpened = false}}}),_vm._l((_vm.markers),function(m,i){return _c('GmapMarker',{key:m.id,attrs:{"position":m.location,"clickable":true,"draggable":false,"label":m.firstName[0]},on:{"click":function($event){return _vm.openMarkerInfo(m, i)}}})})],2):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mt-8"},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":_vm.$vuetify.theme.dark ? `#003366` : ``}},[_c('v-card-title',[_c('p',[_vm._v("Today's Reports")])]),_c('v-data-table',{staticClass:"elevation-1",style:(_vm.$vuetify.theme.dark
              ? `table-layout: fixed; width: 100%; background-color: #003366`
              : ``),attrs:{"dark":_vm.$vuetify.theme.dark,"headers":_vm.headers,"items":_vm.combinedReportsList,"items-per-page":15,"footer-props":{
  'items-per-page-options': [15, 25, 50, -1]
},"single-expand":"","mobile-breakpoint":"600","disable-sort":"","disable-filtering":"","disable-pagination":""},scopedSlots:_vm._u([{key:`item.reportType`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item["__typename"])+" ")])]}},{key:`item.guardDetails`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.guardDetails.firstName + " " + item.guardDetails.lastName)+" ")])]}},{key:`item.siteLocation`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.siteLocation.siteName)+" ")])]}},{key:`item.policeRequired`,fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.policeRequired ? "Yes" : "No")+" ")])]}}],null,true)})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ma-0 pt-2 d-flex"},[_c('span',{staticClass:"emoji mt-1"},[_vm._v("🔴")]),_c('span',{staticClass:"ml-2 pb-2"},[_vm._v("Guards Locations")])])
}]

export { render, staticRenderFns }