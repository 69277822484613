export default {
  namepaced: true,
  state: {
    selectedClient: null,
  },

  mutations: {
    setSelectedClient(state, payload) {
      if (payload["_id"]) {
        payload.id = payload["_id"];
        delete payload["_id"];
        for (var i = 0; i < payload.managers.length; i++) {
          delete payload.managers[i]["__typename"];
        }
      }
      state.selectedClient = payload;
    },
    resetSelectedClient(state) {
      state.selectedClient = {
        id: null,
        clientName: "",
        isEnabled: true,
        managers: [],
      };
    },
    addManagerDetails(state) {
      state.selectedClient = {
        name: "",
        email: "",
        contactNumber: "",
      };
    },
  },
  actions: {
    //
  },

  getters: {
    selectedClient: (state) => state.selectedClient,
  },
};
