<template>
	<div>
		<v-card-title>
			<h3>Account</h3>
			<v-spacer></v-spacer>
			<!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
          >
            <v-icon color="black"> mdi-check </v-icon>
          </v-btn>
        </template>
        Save Changes
      </v-tooltip> -->
		</v-card-title>
		<v-spacer class="mb-4"></v-spacer>

		<v-container>
			<v-btn outlined color="secondary" @click="dialog = true" class="mr-2">
				Create Admin Account
			</v-btn>
			<v-btn outlined color="secondary" @click="passwordDialog = true"> Change Password </v-btn>
		</v-container>
		<v-dialog v-model="passwordDialog" width="600px">
			<v-card flat>
				<v-card-title>Enter New Password</v-card-title>
				<v-form ref="passwordForm">
					<v-container class="pa-6">
						<!-- first name -->

						<!-- email -->
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2" v-model="password">
								<p class="mt-4">Password</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									outlined
									type="password"
									append-icon="mdi-eye"
									v-model="password"
									:rules="passwordRules"
								/>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-4">Repeat Password</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									outlined
									type="password"
									append-icon="mdi-eye"
									v-model="repeatPassword"
									:rules="[
										v => !!v || 'Required.',
										v => v == this.password || 'Password dont match',
									]"
								/>
							</v-col>
						</v-row>
						<!-- change password -->

						<v-btn :loading="loading" color="primary" @click="changePassword">
							Change Password
						</v-btn>
					</v-container>
				</v-form>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" width="600px" persistent>
			<v-card flat>
				<v-card-title
					>New Admin Account <v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-card-title>
				<v-container class="pa-6">
					<v-form ref="form">
						<!-- first name -->

						<!-- email -->
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-4">Email</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field outlined v-model="email" :rules="emailRules" />
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-4">Password</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field outlined type="password" v-model="password" :rules="passwordRules" />
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" lg="2" md="2" sm="2">
								<p class="mt-4">Repeat Password</p>
							</v-col>
							<v-col cols="12" lg="10" md="10" sm="10">
								<v-text-field
									outlined
									type="password"
									v-model="repeatPassword"
									:rules="[
										v => !!v || 'Required.',
										v => v == this.password || 'Password dont match',
									]"
								/>
							</v-col>
						</v-row>
						<!-- change password -->
						<v-btn color="primary" @click="createNewAdmin()" :loading="loading">
							Create Account
						</v-btn>
					</v-form>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		watch: {
			dialog: function (val) {
				if (val == false) {
					this.reset();
				}
			},
			passwordDialog: function (val) {
				if (val == false) {
					this.reset();
				}
			},
		},
		methods: {
			async changePassword() {
				let valid = this.$refs.passwordForm.validate();
				this.loading = true;

				if (valid) {
					let adminId = this.$store.getters['userInfo'].sub.split('|')[1];
					let credentials = {
						_id: adminId,
						password: this.repeatPassword,
					};

					let res = await axios.patch(
						`https://apigw.guardex.co.nz/admin/users/${adminId}/changePassword`,
						credentials,
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer ' + localStorage.getItem('apollo-token'),
							},
						}
					);
					this.loading = false;
					this.passwordDialog = false;
					this.reset();
				} else {
					this.loading = false;
				}
			},
			async createNewAdmin() {
				this.loading = true;
				let valid = this.$refs.form.validate();
				if (!valid) {
					this.loading = false;
					return;
				}
				let params = {
					password: this.repeatPassword,
					email: this.email,
				};
				let res = await axios.post('https://apigw.guardex.co.nz/admin/users/newAdmin', params, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + localStorage.getItem('apollo-token'),
					},
				});

				this.loading = false;
				this.dialog = false;
				this.reset();
			},

			reset() {
				this.password = '';
				this.repeatPassword = '';
				this.email = '';
			},
		},
		data: () => ({
			loading: false,
			passwordDialog: false,
			dialog: false,
			isEdit: false,
			password: '',
			repeatPassword: '',
			email: null,
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
			],

			passwordRules: [value => !!value || 'Required.', v => v.length >= 8 || 'Min 8 characters'],
		}),
	};
</script>
