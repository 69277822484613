export default {
	state: {
		selectedAlertTime: null,
	},
	mutations: {
		setSelectedAlertTime(state, payload) {
			if (payload && payload['_id']) {
				payload.id = payload['_id'];
				if (!payload.incidentEmailTemplate) {
					payload.incidentEmailTemplate = {
						// emailSendTime: '',
						emailHeader: '',
						emailSubject: '',
						emailBody: '',
						emailFooter: '',
					};
				}
				if (!payload.alarmEmailTemplate) {
					payload.alarmEmailTemplate = {
						// emailSendTime: '',
						emailHeader: '',
						emailSubject: '',
						emailBody: '',
						emailFooter: '',
					};
				}

				if (!payload.dailyEmailTime) {
					payload.dailyEmailTime = {
						hour: '',
						minutes: '',
					};
				}

				if (!payload.weeklyEmailTime) {
					payload.weeklyEmailTime = {
						day: '',
						minutes: '',
						hour: '',
					};
				}

				state.selectedAlertTime = payload;
			} else {
				this.commit('resetSelectedAlertTime');
			}
		},

		resetSelectedAlertTime(state) {
			state.selectedAlertTime = {
				id: null,
				guardId: null,
				coaExpiryNotificationTime: '',
				regoExpiryNotificationTime: '',
				wofExpiryNotificationTime: '',
				vehicleServiceNotificationTime: '',
				incidentEmailTemplate: {
					// emailSendTime: '',
					emailHeader: '',
					emailSubject: '',
					emailBody: '',
					emailFooter: '',
				},
				alarmEmailTemplate: {
					// emailSendTime: '',
					emailHeader: '',
					emailSubject: '',
					emailBody: '',
					emailFooter: '',
				},
				dailyEmailTime: {
					hour: '',
					minutes: '',
				},
				weeklyEmailTime: {
					day: '',
					minutes: '',
					hour: '',
				},
			};
		},
	},

	actions: {},

	getters: {
		selectedAlertTime: state => state.selectedAlertTime,
	},
};
