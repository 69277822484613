<template>
  <v-col
    v-if="isAdmin"
    style="height: 100%"
  >
    <div
      :style="isMobile() ? `width: 100%` : `width: 70vw`"
      class="container"
    >
      <div :class="isMobile() ? `text-center` : `d-flex`">
        <v-icon
          :size="isMobile() ? `40` : `50`"
          color="secondary"
        >mdi-account-multiple
        </v-icon>
        <h1
          v-if="!isMobile()"
          class="ma-4"
        >Clients</h1>
        <h3
          v-else
          class="ma-4"
        >Clients</h3>
      </div>
      <v-fade-transition>
        <v-row>
          <v-col
            :cols="isMobile() ? `` : `6`"
            class="mt-4"
          >
            <!-- @keyup="initSearch()" -->
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Search Clients"
              @input="
                loading = true;
                searchList = [];
              "
            >
            </v-text-field>
          </v-col>

          <!-- dropdown filter -->
          <v-col
            cols="3"
            class="text-center mt-4"
            v-if="!isMobile()"
          >
            <v-select
              v-model="clientStatus"
              :items="filterClients"
              color="#FF9900"
              width="100%"
              height="40px"
              outlined
              dense
            >
            </v-select>
          </v-col>

          <v-col
            cols="3"
            class="text-center mt-4"
            v-if="!isMobile()"
          >
            <!-- <v-btn @click="initAddClient()"> -->
            <v-btn
              color="#FF9900"
              width="100%"
              height="40px"
              @click="initAddClient()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Client
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>

      <!-- Add client form  -->
      <v-dialog
        v-model="addClientDialog"
        style="z-index: 999999"
        max-width="800"
        persistent
      >
        <v-card min-height="65vh">
          <div
            class="pa-6"
            v-if="selectedClient"
          >
            <v-row>
              <v-col
                cols="12"
                :class="
                  $vuetify.breakpoint.width < 600 ? `text-center` : `text-right`
                "
              >
                <!-- <v-tooltip bottom v-if="!selectedClient.isEnabled && !isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="deleteClientConfirmation()"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete-alert</v-icon
                    >
                  </template>
                  <span>Delete Client</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="
                        selectedClient.isEnabled = !selectedClient.isEnabled
                      "
                      :color="
                        selectedClient.isEnabled ? `green darken-1` : `red`
                      "
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >{{
                        selectedClient.isEnabled
                          ? `mdi-account`
                          : `mdi-account-cancel`
                      }}</v-icon>
                  </template>
                  <span>{{
                    selectedClient.isEnabled
                      ? `Disable Client`
                      : `Enable Client`
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      size="32"
                      @click="
                        () => {
                          isAdd ? addClient() : updateClient();
                        }
                      "
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      x-large
                      :loading="loading"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ isAdd ? `Save Client` : `Save Changes` }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="(addClientDialog = false), reset()"
                      large
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >mdi-close</v-icon>
                  </template>
                  <span>Exit</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                :class="$vuetify.breakpoint.width < 600 ? `text-center` : ``"
              >
                <h3>
                  {{ isAdd ? `Add Client` : `Change Client Details` }}
                </h3>
              </v-col>
            </v-row>

            <v-form
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <p
                    class="ma-0 mb-2"
                    style="font-size: 14px; color: black"
                  >
                    Client Details
                  </p>
                  <v-text-field
                    v-model="selectedClient.clientName"
                    :rules="clientNameRules"
                    outlined
                    label="Client Name"
                  />
                  <p style="font-size: 14px; color: black">
                    Site Manager Details
                  </p>

                  <!-- ---------------------------------------------------------------- -->
                  <!--   <v-text-field
                      v-model="selectedClient.managers.name"
                      :rules="managerNameRules"
                      outlined
                      label="Name"
                    />

                    <v-text-field
                      v-model="selectedClient.managers.email"
                      :rules="emailRules"
                      required
                      outlined
                      label="Email"
                    />

                    <v-text-field
                      v-model="selectedClient.managers.contactNumber"
                      :rules="contactNumberRules"
                      outlined
                      label="Contact Number"
                    /> -->
                  <div
                    v-for="(managerDetails, i) in selectedClient.managers"
                    :key="i"
                  >
                    <v-text-field
                      label="Manager Name"
                      v-model="managerDetails.name"
                      :rules="managerNameRules"
                      outlined
                    ></v-text-field>

                    <v-text-field
                      label="Contact Number"
                      v-model="managerDetails.contactNumber"
                      :rules="contactNumberRules"
                      outlined
                    >
                    </v-text-field>

                    <v-text-field
                      label="Email"
                      v-model="managerDetails.email"
                      :rules="emailRules"
                      outlined
                      class="pa-0"
                    ></v-text-field>

                    <v-row class="pb-8 text-center">
                      <v-col
                        cols="12"
                        :class="
                          $vuetify.breakpoint.width < 600 ? `text-center` : ``
                        "
                      >
                        <!-- remove(i) -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              @click="confirmDeleteManager(i)"
                              v-bind="attrs"
                              v-on="on"
                              color="secondary"
                            >mdi-delete</v-icon>
                          </template>
                          <span> Remove {{ managerDetails.name }} </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- remove client dialog -->
                  <v-dialog
                    v-model="deleteClientDialog"
                    persistent
                    max-width="320"
                  >
                    <v-card color="red">
                      <v-card-title class="text-h5">
                        <v-icon
                          class="mr-2"
                          color="yellow"
                          large
                        >
                          mdi-alert
                        </v-icon>
                        Delete Confirmation
                      </v-card-title>
                      <v-card-text class="black--text mt-2"><b>Deleting this Client also removes the associated
                          Managers</b></v-card-text>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="black "
                          text
                          @click="deleteClientDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          class="white--text"
                          color="yellow darken-1"
                          text
                          :loading="loading"
                          @click="deleteClient()"
                        >
                          Confirm
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- remove manager dialog -->
                  <v-dialog
                    color="red"
                    v-model="confirmationDialog"
                    persistent
                    max-width="290"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        Remove Manager
                      </v-card-title>
                      <v-card-text>Are you sure you want to remove this
                        manager?</v-card-text>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="green darken-1"
                          text
                          @click="confirmationDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          class="white--text"
                          color="red darken-1"
                          text
                          :loading="loading"
                          @click="removeManager()"
                        >
                          Confirm
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-col
                    cols="12"
                    :class="
                      $vuetify.breakpoint.width < 600
                        ? `text-center`
                        : `text-center`
                    "
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          @click="add()"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="secondary">mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      Add Manager
                    </v-tooltip>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
      <!-- Add client form  -->

      <div
        :style="
          isMobile()
            ? `
          max-height: 61vh;
          overflow-y: scroll;
          border-top-style: solid;
          border-bottom-style: solid;
          border-color: gray;
          border-width: 1px;
          `
            : ``
        "
        class="hide-scrollbar pa-1"
      >
        <!-- v-model="selectedClient" -->
        <v-data-table
          v-if="selectedClient"
          :headers="headers"
          :items="clientsList"
          :search="search"
          :items-per-page="15"
          :footer-props="{
    'items-per-page-options': [15, 25, 50, -1]
  }"
          class="elevation-1 mt-1 mb-5"
          item-key="name"
          mobile-breakpoint="0"
          loading-text="Loading... Please wait"
        >
          <template #[`item.clientName`]="{ item }">
            <td>{{ item.clientName }}</td>
          </template>

          <template #[`item.name`]="{ item }">
            <td v-if="item.managers.length > 0">{{ item.managers[0].name }}</td>
          </template>

          <template #[`item.contactNumber`]="{ item }">
            <td v-if="item.managers.length > 0">
              {{ item.managers[0].contactNumber }}
            </td>
          </template>

          <template #[`item.email`]="{ item }">
            <td v-if="item.managers.length > 0">
              {{ item.managers[0].email }}
            </td>
          </template>

          <template #[`item.edit`]="{ item, index }">
            <td>
              <v-icon
                @click="editClient(item, index)"
                class="ml-1"
                small
              >mdi-eye</v-icon>
            </td>
          </template>
        </v-data-table>
      </div>

      <v-fab-transition>
        <v-btn
          v-show="isMobile()"
          color="secondary"
          dark
          fixed
          bottom
          right
          fab
          class="mb-12"
          @click="initAddClient()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>
  </v-col>
</template>

<script>
import {
  clients,
  addClient,
  updateClient,
  deleteClient,
  enabledClients,
  disabledClients,
} from "../graphql/clients";

export default {
  methods: {
    showEnabledClients () {
      this.$apollo.addSmartQuery("clientsList", enabledClients);
    },

    showDisabledClients () {
      this.$apollo.addSmartQuery("clientsList", disabledClients);
    },

    reset () {
      this.$store.commit("resetSelectedClient");
    },

    isMobile () {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    initAddClient () {
      this.isAdd = true;
      this.$store.commit("resetSelectedClient");
      this.selectedClient.managers.push({
        name: this.selectedClient.managers.name,
        contactNumber: this.selectedClient.managers.contactNumber,
        email: this.selectedClient.managers.email,
      });
      this.addClientDialog = true;
    },

    async deleteClient () {
      alert("Cannot delete client this time.");
      // this.loading = true;
      // let res = await this.$apollo.mutate({
      //   mutation: deleteClient,
      //   variables: {
      //     id: this.selectedClient.id,
      //   },
      // });
      // this.clientsList = await this.clientsList.filter(
      //   (obj) => obj.id != res.data.deleteClient["_id"]
      // );
      // this.clientsList = await this.clientsList.filter(
      //   (obj) => obj["_id"] != res.data.deleteClient["_id"]
      // );
      // this.addClientDialog = false;
      // this.loading = false;
      // (this.notificationText = "Client Deleted"),
      //   (this.color = "red "),
      //   (this.notify = true);
    },

    async addClient () {
      this.loading = true;
      let valid = this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }
      if (this.isAdd) {
        await this.$apollo.mutate({
          mutation: addClient,
          variables: {
            ...this.selectedClient,
          },
          update: (store, { data: { addClient } }) => {
            this.clientsList.push(addClient);
          },
        });

        this.addClientDialog = false;
        this.loading = false;
        (this.color = "green darken-1"),
          (this.notificationText = "Client Added Successfully"),
          (this.notify = true);
      } else {
        this.loading = false;
        this.updateClient();
      }
    },

    async updateClient () {
      this.loading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        await this.$apollo.mutate({
          mutation: updateClient,
          variables: {
            ...this.selectedClient,
          },
        });
        this.addClientDialog = false;
        this.loading = false;
        (this.color = "green darken-1"),
          (this.notificationText = "Client Updated Successfully"),
          (this.notify = true);
      }

      if (
        (this.clientStatus == "Active" &&
          this.selectedClient.isEnabled == false) ||
        (this.clientStatus == "Inactive" &&
          this.selectedClient.isEnabled == true)
      ) {
        this.clientsList.splice(this.selectedClientIndex, 1);
        this.selectedClientIndex = null;
      }
    },

    editClient (client, i) {
      this.selectedClientIndex = i;
      this.isAdd = false;
      this.$store.commit("setSelectedClient", client);

      this.addClientDialog = true;
    },

    add () {
      this.selectedClient.managers.push({
        name: this.selectedClient.managers.name,
        contactNumber: this.selectedClient.managers.contactNumber,
        email: this.selectedClient.managers.email,
      });
    },

    removeManager () {
      this.loading = true;
      if (this.deleteIndex >= 0) {
        this.selectedClient.managers.splice(this.deleteIndex, 1);
      }
      this.confirmationDialog = false;
      this.loading = false;
    },

    confirmDeleteManager (index) {
      this.confirmationDialog = true;
      this.deleteIndex = index;
    },

    deleteClientConfirmation () {
      this.deleteClientDialog = true;
    },
  },

  // apollo: {
  //   clientsList: clients,
  // },

  components: {
    //
  },

  async mounted () {
    this.$store.commit("resetSelectedClient");
    this.showEnabledClients();
  },

  computed: {
    selectedClient: {
      get () {
        return this.$store.getters["selectedClient"];
      },
    },
    isAdmin: {
      get () {
        return this.$store.getters["isAdmin"];
      },
    },
  },

  watch: {
    addClientDialog: function (val) {
      if (!val) {
        this.$store.commit("resetSelectedClient");
      }
    },
    clientStatus: function () {
      if (this.clientStatus === "Active") {
        this.showEnabledClients();
      } else if (this.clientStatus === "Inactive") {
        this.showDisabledClients();
      } else return this.showEnabledClients();
    },
  },

  data () {
    return {
      selectedClientIndex: null,
      headers: [
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "clientName",
        },
        { text: "Manager Name", sortable: false, value: "name" },
        { text: "Contact Number", sortable: false, value: "contactNumber" },
        { text: "Email", sortable: false, value: "email" },
        { text: "Edit", sortable: false, value: "edit" },
      ],
      expanded: [],
      search: "",
      addClientDialog: false,
      confirmationDialog: false,
      isAdd: false,
      loading: false,
      clientsList: [],
      deleteIndex: null,
      deleteClientDialog: false,
      clientStatus: "Active",
      filterClients: ["Active", "Inactive"],

      // rules
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      clientNameRules: [
        (v) => !!v || "Client name is required",
        // (v) => v.length <= 64 || "",
      ],
      managerNameRules: [(v) => !!v || "Name is required"],

      contactNumberRules: [
        (v) => !!v || "Contact Number is required",
        (v) => /[0-9]/.test(v) || "Must enter only digits",
      ],
      notify: false,
      color: "",
      notificationText: "",
    };
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
