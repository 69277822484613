<template>
	<div v-if="selectedAlertTime">
		<v-col>
			<div :style="isMobile() ? `width: 100%` : ``" class="container">
				<div :class="isMobile() ? `text-center` : `d-flex`">
					<v-icon color="secondary" :size="isMobile() ? `40` : `50`">mdi-tune</v-icon>
					<h1 v-if="!isMobile()" class="ma-4">Settings</h1>
					<h3 v-else class="ma-4">Settings</h3>
				</div>

				<v-card elevation="4" class="mx-auto">
					<v-subheader class="color: secondary white--text"
						>General <v-spacer></v-spacer>
						<v-btn :loading="loading" text @click="initSave()">
							<v-icon color="white">mdi-content-save</v-icon>
						</v-btn>
					</v-subheader>
					<v-tabs
						v-model="tabs"
						:vertical="!isMobile()"
						icons-and-text
						center-active
						color="secondary"
					>
						<v-tab>
							<v-icon left> mdi-bell </v-icon>
							Notification
						</v-tab>
						<v-tab v-if="isAdmin">
							<v-icon left> mdi-email </v-icon>
							Email Templates
						</v-tab>
						<v-tab v-if="isAdmin">
							<v-icon left> mdi-book-clock </v-icon>
							Email Scheduler
						</v-tab>
						<v-tab v-if="isAdmin">
							<v-icon left> mdi-account-supervisor </v-icon>
							Accounts Settings
						</v-tab>

						<!-- Notification tab items -->
						<v-tab-item>
							<v-container>
								<NotificationSettings />
							</v-container>
						</v-tab-item>

						<!-- Email template tab items -->
						<v-tab-item v-if="isAdmin">
							<v-container>
								<EmailTemplate />
							</v-container>
						</v-tab-item>

						<!-- Email template tab items -->
						<v-tab-item v-if="isAdmin">
							<v-container>
								<EmailScheduler />
							</v-container>
						</v-tab-item>

						<!-- account settings -->
						<v-tab-item v-if="isAdmin">
							<v-container>
								<AccountSettings />
							</v-container>
						</v-tab-item>
					</v-tabs>
				</v-card>
				<p class="text-right mt-3 mr-3" style="font-size: 12px">Version: v1.3.0</p>
			</div>
		</v-col>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</div>
</template>

<script>
	import NotificationSettings from '../views/NotificationSettings.vue';
	import EmailTemplate from '../views/EmailTemplate.vue';
	import AccountSettings from '../views/AccountSettings.vue';
	import EmailScheduler from '../views/EmailScheduler.vue';
	import { addSettings, updateSettings } from '../graphql/settings';

	export default {
		methods: {
			async initSave() {
				if (!this.isAdmin) {
					if (
						this.selectedAlertTime.coaExpiryNotificationTime.length == 0 ||
						this.selectedAlertTime.coaExpiryNotificationTime.length > 3
					) {
						(this.notificationText = 'Unable to save settings'),
							(this.color = 'red'),
							(this.notify = true);
						this.loading = false;
					} else {
						this.saveSettings();
					}
				} else {
					if (
						this.selectedAlertTime.regoExpiryNotificationTime.length > 0 &&
						this.selectedAlertTime.regoExpiryNotificationTime.length < 4 &&
						this.selectedAlertTime.vehicleServiceNotificationTime.length > 0 &&
						this.selectedAlertTime.vehicleServiceNotificationTime.length < 4 &&
						this.selectedAlertTime.wofExpiryNotificationTime.length > 0 &&
						this.selectedAlertTime.wofExpiryNotificationTime.length < 4
					) {
						this.saveSettings();
					} else {
						(this.notificationText = 'Unable to save settings'),
							(this.color = 'red'),
							(this.notify = true);
						this.loading = false;
					}
				}
			},
			async saveSettings() {
				this.loading = true;
				try {
					let obj = this.selectedAlertTime;
					if (this.isAdmin) {
						obj.guardId = 'ADMINISTRATOR';
						delete obj['coaExpiryNotificationTime'];
					} else {
						obj.guardId =
							this.$store.getters['userInfo']['http://guardex.com/custom'].app_metadata.guard_id;
					}

					delete obj['_id'];

					if (obj.id) {
						delete obj.incidentEmailTemplate['__typename'];
						delete obj.alarmEmailTemplate['__typename'];
						delete obj.dailyEmailTime['__typename'];
						delete obj.weeklyEmailTime['__typename'];

						let res = await this.$apollo.mutate({
							mutation: updateSettings,
							variables: obj,
						});

						this.notificationText = 'Saved Successfully';
						this.color = 'green darken-1';
						this.notify = true;
					} else {
						delete obj.incidentEmailTemplate['__typename'];
						delete obj.alarmEmailTemplate['__typename'];
						let res = await this.$apollo.mutate({
							mutation: addSettings,
							variables: obj,
						});
						this.$store.commit('setSelectedAlertTime', res.data.addSettings);

						this.notificationText = 'Saved Successfully';
						this.color = 'green darken-1';
						this.notify = true;
					}

					this.loading = false;
				} catch (e) {
					(this.notificationText = 'Unable to save settings1'),
						(this.color = 'red'),
						(this.notify = true);
					this.loading = false;
				}
			},

			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
		},

		components: {
			NotificationSettings,
			EmailTemplate,
			AccountSettings,
			EmailScheduler,
		},

		computed: {
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},

			selectedAlertTime: {
				get() {
					return this.$store.getters['selectedAlertTime'];
				},

				set(val) {
					this.$store.commit('setSelectedAlertTime', val);
				},
			},
		},

		data: () => ({
			loading: false,
			settings: [],
			tabs: null,
			notificationDialog: false,
			notify: false,
			notificationText: '',
			color: '',
		}),
	};
</script>

<style scoped>
	.v-tab {
		letter-spacing: normal !important;
		display: flex !important;
		flex-direction: row !important;
		justify-content: center !important;
		align-items: center !important;
		border: 1px solid #f7f7f7;
		margin: 13px 13px;
	}
</style>
