<template>
  <v-container>
    <v-card min-height="75vh">
      <h2 class="pl-4 pt-4">
        <v-icon
          color="secondary"
          :size="isMobile() ? `40` : `50`"
        >mdi-history</v-icon> Guards Location History
      </h2>

      <div>
        <v-container>
          <!-- guards list dropdown -->
          <v-select
            v-model="selectedGuard"
            class="pt-4"
            label="Select Guard"
            outlined
            :item-text="(item) => item.firstName + ` ` + item.lastName"
            :items="guardsList"
            return-object
            inset
            dense
          > </v-select>

          <!-- calendar -->
          <v-text-field
            :value="convertDate(selectedDate)"
            @click="datePicker = true"
            label="Select Date"
            outlined
            inset
            dense
            readonly
          >
            <template slot="append">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="datePicker = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-calendar
                  </v-icon>
                </template>
                <span>Select Date</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-container>
      </div>
      <!-- date picker dialog -->
      <v-dialog
        ref="locationDateRef"
        v-model="datePicker"
        max-width="300"
      >
        <v-date-picker
          @input="datePicker = false"
          v-model="selectedDate"
          :picker-date.sync="selectedDate"
          show-adjacent-months
          elevation="15"
        >
        </v-date-picker>
      </v-dialog>

      <!-- uncomment if you want message if there's data etc -->
      <!-- <h4
        class="pl-3"
        v-if="selectedGuard && selectedDate"
        v-show="listLocationHistory.length < 1"
      > No data found for {{ selectedGuard.firstName + " " + selectedGuard.lastName }} on {{ selectedDate }}
      </h4> -->
      <!-- map  -->
      <v-container class="pt-0">
        <GmapMap
          :center="center"
          :zoom="10"
          :options="options"
          map-type-id="roadmap"
          style="width: 100%; height: 63vh"
        >
          <gmap-polyline
            v-bind:path.sync="listLocationHistory "
            v-bind:options="{ strokeColor:'#EA690B', strokeWeight: 2, strokeOpacity: 1.0}"
          />
        </GmapMap>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { enabledGuards } from "../graphql/guard";
import { mapGetters } from "vuex";
import { listLocationHistory } from "../graphql/locationHistory"
import moment from 'moment';

export default {
  methods: {
    convertDate (d) {
      return d && d.length > 0 ? moment(d).format("DD/MM/yyyy") : "";
    },

    isMobile () {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    getHistoryList (guard, date) {
      this.$apollo.addSmartQuery("listLocationHistory", {
        query: listLocationHistory,
        variables () {
          return {
            guardId: guard,
            timestamp: date,
          };
        },
        update: (data) => {
          data.listLocationHistory.forEach(el => {
            delete el['_id'];
            delete el['guardId'];
            delete el['timestamp'];
            delete el['__typename']
          });
          return data.listLocationHistory
        },
      });
    }
  },

  watch: {
    selectedGuard: function (val) {
      if (val['_id'] && this.selectedDate) {
        this.getHistoryList(val['_id'], this.selectedDate)
      }
    },
    selectedDate: function (val) {
      if (val && this.selectedGuard) {
        this.getHistoryList(this.selectedGuard['_id'], val)
      }
    },
  },

  mounted () {
    setTimeout(() => {
      // console.log(this.guardsList.map(({ _id }) => _id))
      // console.log(this.locationHistoryList.map(({ guardId }) => guardId))
    }, 1000);
  },

  apollo: {
    guardsList: enabledGuards,
  },

  computed: {
    ...mapGetters(["isAdmin", "userInfo"]),
    google: gmapApi,
    options: {
      get () {
        return this.$store.getters["mapOptions"];
      },
    },
    mapZoom: {
      get () {
        return this.$store.getters["mapZoom"];
      },
      set (val) {
        this.$store.commit("setMapZoom", val);
      },
    },
  },

  data () {
    return {
      center: { lat: parseFloat(-43.5357934), lng: parseFloat(172.6614121) },
      guardsList: [],
      locationHistoryList: [],
      markers: [],
      datePicker: false,
      selectedGuard: null,
      guardCoordinates: [],
      selectedDate: null,
      listLocationHistory: [],
    }
  },
}
</script>

<style>
/*  */
</style>