<template>
  <div>
    <div class="d-flex">
      <h3 class="pb-5 pt-4"> {{ switcher ? `'Daily' email time. ` : `'Weekly' email time` }} </h3>
      <v-spacer></v-spacer>
      <v-switch
        v-model="switcher"
        inset
      />
    </div>

    <v-row v-if="switcher">

      <v-col
        cols="4"
        class="ml-3"
      >
        <h5 class="mb-4">Time</h5>
        <v-autocomplete
          label="Hour"
          outlined
          v-model="selectedAlertTime.dailyEmailTime.hour"
          :items="hourListComputed"
        ></v-autocomplete>
        <v-autocomplete
          label="Minute"
          outlined
          v-model="selectedAlertTime.dailyEmailTime.minutes"
          :items="minutesListComputed"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
      </v-col>
    </v-row>

    <v-row v-if="!switcher">
      <v-col
        cols="4"
        class="ml-3"
      >
        <h5 class="mb-4">Day</h5>
        <v-autocomplete
          label="Day"
          outlined
          :items="dayItems"
          v-model="selectedAlertTime.weeklyEmailTime.day"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <h5 class="mb-4">Time</h5>
        <v-autocomplete
          label="Hour"
          outlined
          v-model="selectedAlertTime.weeklyEmailTime.hour"
          :items="hourListComputed"
        ></v-autocomplete>
        <v-autocomplete
          label="Minute"
          outlined
          v-model="selectedAlertTime.weeklyEmailTime.minutes"
          :items="minutesListComputed"
        ></v-autocomplete>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  computed: {
    hourListComputed() {
      let hourList = [];
      for (var i = 0; i < 24; i++) {
        hourList.push(("0" + i).slice(-2));
      }
      return hourList;
    },

    minutesListComputed() {
      let minutesList = [];
      for (var i = 0; i < 60; i++) {
        minutesList.push(("0" + i).slice(-2));
      }
      return minutesList;
    },
    selectedAlertTime: {
      get() {
        return this.$store.getters["selectedAlertTime"];
      },
    },
  },
  watch: {},
  methods: {},
  data: () => ({
    switcher: true,
    // weeklyTimeHour: "",
    // dailyTimeMinutes: "",
    // dailyTimeHour: "",
    // weeklyTimeMinutes: "",
    // selectedDay: "",
    dayItems: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  }),
};
</script>
