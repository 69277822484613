import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
	icons: {
		iconfont: 'mdi', // default - only for display purposes
	},
	theme: {
		options: { customProperties: true },
		dark: localStorage.getItem('isDark') === "true" ? localStorage.getItem('isDark') === "true" : false,

		themes: {
			light: {
				primary: '#EA690B',
				secondary: '#003366',
				anchor: '#003366',
			},
			dark: {
				primary: '#FF9900',
				secondary: '#EA690B',
				anchor: '#003366',
			}
		}


	},
});
export default vuetify;
