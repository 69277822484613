import gql from 'graphql-tag';

export const listTodaysAlarm = {
	query: gql`
		{
			listTodaysAlarm {
				_id
				guardOnDuty
				guardDetails {
					firstName
					lastName
				}
				siteLocationId
				siteLocation {
					siteName
				}
				policeRequired
			}
		}
	`,
	update: data => data.listTodaysAlarm,
};

export const alarmResponseList = {
	query: gql`
		{
			alarmResponseList {
				_id
				dateIssued
				alarmNotificationTime
				siteLocationId
				siteLocation {
					siteName
					siteAddress
					siteInfo
					clientId
				}
				currentGeoLocation
				siteArrivalTime
				guardOnDuty
				guardDetails {
					firstName
					lastName
				}
				alarmIncidentDescription
				policeRequired
				policeInvolved {
					officerName
					officerStation
					policeEventNumber
					notes
				}
				anyoneInjured
				peopleInjured {
					firstName
					lastName
					injuryDescription
					notes
				}
				damagedProperties {
					propertyName
					propertyAddress
				}
				hasMonitoringCompanyContacted
				monitoringCompanyCombo {
					monitoringCompanyId
					personSpokenTo {
						firstName
						lastName
						telephone
					}
					monitoringCompanyDetails {
						companyName
						address
					}
				}
				hasAlarmReactivated
				additionalDetails
				attachments
				emailSent
				dateCreated
			}
		}
	`,
	update: data => {
		data.alarmResponseList.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		return data.alarmResponseList;
	},
	fetchPolicy: 'cache-and-network',
};

export const addAlarmResponse = gql`
	mutation (
		$dateIssued: String
		$alarmNotificationTime: String
		$siteLocationId: ID
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$alarmIncidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$damagedProperties: [PropertyInput]
		$hasMonitoringCompanyContacted: Boolean
		$monitoringCompanyCombo: MonitoringComboInput
		$hasAlarmReactivated: Boolean
		$additionalDetails: String
		$attachments: [String]
		$dateCreated: String
	) {
		addAlarmResponse(
			dateIssued: $dateIssued
			alarmNotificationTime: $alarmNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			alarmIncidentDescription: $alarmIncidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			damagedProperties: $damagedProperties
			hasMonitoringCompanyContacted: $hasMonitoringCompanyContacted
			monitoringCompanyCombo: $monitoringCompanyCombo
			hasAlarmReactivated: $hasAlarmReactivated
			additionalDetails: $additionalDetails
			attachments: $attachments
			dateCreated: $dateCreated
		) {
			_id
			dateIssued
			alarmNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			alarmIncidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			damagedProperties {
				propertyName
				propertyAddress
			}
			hasMonitoringCompanyContacted
			monitoringCompanyCombo {
				monitoringCompanyId
				monitoringCompanyDetails {
					companyName
					address
				}
				personSpokenTo {
					firstName
					lastName
					telephone
				}
			}
			hasAlarmReactivated
			additionalDetails
			attachments
			dateCreated
		}
	}
`;

export const updateAlarmResponse = gql`
	mutation (
		$id: ID!
		$dateIssued: String
		$alarmNotificationTime: String
		$siteLocationId: String
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$alarmIncidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$damagedProperties: [PropertyInput]
		$hasMonitoringCompanyContacted: Boolean
		$monitoringCompanyCombo: MonitoringComboInput
		$hasAlarmReactivated: Boolean
		$additionalDetails: String
		$attachments: [String]
		$dateCreated: String
	) {
		updateAlarmResponse(
			id: $id
			dateIssued: $dateIssued
			alarmNotificationTime: $alarmNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			alarmIncidentDescription: $alarmIncidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			damagedProperties: $damagedProperties
			hasMonitoringCompanyContacted: $hasMonitoringCompanyContacted
			monitoringCompanyCombo: $monitoringCompanyCombo
			hasAlarmReactivated: $hasAlarmReactivated
			additionalDetails: $additionalDetails
			attachments: $attachments
			dateCreated: $dateCreated
		) {
			_id
			dateIssued
			alarmNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			alarmIncidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			damagedProperties {
				propertyName
				propertyAddress
			}
			hasMonitoringCompanyContacted
			monitoringCompanyCombo {
				monitoringCompanyId
				monitoringCompanyDetails {
					companyName
					address
				}
				personSpokenTo {
					firstName
					lastName
					telephone
				}
			}
			hasAlarmReactivated
			additionalDetails
			attachments
			dateCreated
		}
	}
`;

export const updateAlarmClean = gql`
	mutation (
		$id: ID!
		$dateIssued: String
		$alarmNotificationTime: String
		$siteLocationId: String
		$currentGeoLocation: String
		$siteArrivalTime: String
		$guardOnDuty: ID
		$alarmIncidentDescription: String
		$policeRequired: Boolean
		$policeInvolved: [PoliceInput]
		$anyoneInjured: Boolean
		$peopleInjured: [PeopleInput]
		$damagedProperties: [PropertyInput]
		$hasMonitoringCompanyContacted: Boolean
		$monitoringCompanyCombo: MonitoringComboInput
		$hasAlarmReactivated: Boolean
		$additionalDetails: String
		$attachments: [String]
		$dateCreated: String
	) {
		updateAlarmClean(
			id: $id
			dateIssued: $dateIssued
			alarmNotificationTime: $alarmNotificationTime
			siteLocationId: $siteLocationId
			currentGeoLocation: $currentGeoLocation
			siteArrivalTime: $siteArrivalTime
			guardOnDuty: $guardOnDuty
			alarmIncidentDescription: $alarmIncidentDescription
			policeRequired: $policeRequired
			policeInvolved: $policeInvolved
			anyoneInjured: $anyoneInjured
			peopleInjured: $peopleInjured
			damagedProperties: $damagedProperties
			hasMonitoringCompanyContacted: $hasMonitoringCompanyContacted
			monitoringCompanyCombo: $monitoringCompanyCombo
			hasAlarmReactivated: $hasAlarmReactivated
			additionalDetails: $additionalDetails
			attachments: $attachments
			dateCreated: $dateCreated
		) {
			_id
			dateIssued
			alarmNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			alarmIncidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			damagedProperties {
				propertyName
				propertyAddress
			}
			hasMonitoringCompanyContacted
			monitoringCompanyCombo {
				monitoringCompanyId
				monitoringCompanyDetails {
					companyName
					address
				}
				personSpokenTo {
					firstName
					lastName
					telephone
				}
			}
			hasAlarmReactivated
			additionalDetails
			attachments
			dateCreated
		}
	}
`;

export const deleteAlarmResponse = gql`
	mutation ($id: ID!) {
		deleteAlarmResponse(id: $id) {
			_id
			dateIssued
			alarmNotificationTime
			siteLocationId
			currentGeoLocation
			siteArrivalTime
			guardOnDuty
			alarmIncidentDescription
			policeRequired
			policeInvolved {
				officerName
				officerStation
				policeEventNumber
				notes
			}
			anyoneInjured
			peopleInjured {
				firstName
				lastName
				injuryDescription
				notes
			}
			damagedProperties {
				propertyName
				propertyAddress
			}
			hasMonitoringCompanyContacted
			monitoringCompanyCombo {
				monitoringCompanyId
				personSpokenTo {
					firstName
					lastName
					telephone
				}
				monitoringCompanyDetails {
					companyName
					address
				}
			}
			hasAlarmReactivated
			additionalDetails
			attachments
			dateCreated
		}
	}
`;

export const ALARM_SUBSCRIPTION = gql`
	subscription {
		alarmSub: alarm {
			_id
			guardOnDuty
			guardDetails {
				firstName
				lastName
			}
			siteLocationId
			siteLocation {
				siteName
			}
			policeRequired
		}
	}
`;
