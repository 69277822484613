import { createProvider } from '../vue-apollo';
import { updateGuardLocation } from '../graphql/guard';
import { addLocationHistory } from '../graphql/locationHistory';
import moment from 'moment';
import { clockIn, clockOut } from '../graphql/clocking';

export default {
	state: {
		clockingLoading: false,
		selectedClockIn: null,
		saveGuardLocation: null,
		positionID: null,
		markers: [],
		center: {
			lat: -43.5244148,
			lng: 172.6322926,
		},
		mapZoom: 11,
		mapOptions: {
			gestureHandling: 'greedy',
			disableDefaultUi: false,
			fullscreenControl: true,
			zoomControl: false,
			streetViewControl: false,
			scaleControl: false,
			mapTypeControl: false,
			styles: [
				{
					featureType: 'landscape.man_made',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.man_made',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural.landcover',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural.terrain',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.attraction',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.business',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.government',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.medical',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.place_of_worship',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.school',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.sports_complex',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
			],
		},
	},
	mutations: {
		setSelectedClockIn(state, payload) {
			state.selectedClockIn = payload;
		},
		setClockingLoading(state, payload) {
			state.clockingLoading = payload;
		},
		addMarker(state, payload) {
			const index = state.markers.findIndex(marker => marker.id == payload.id);
			if (index == -1) {
				// if (payload.isClockedIn === true) {
				// 	state.markers.push(payload);
				// }
				state.markers.push(payload);
			} else {
				// if (payload.isClockedIn === true) {
				// 	state.markers.splice(index, 1);
				// 	state.markers.push(payload);
				// }
				state.markers.splice(index, 1);
				state.markers.push(payload);
			}
		},
		setCenterMap(state, payload) {
			state.center = payload;
		},
		setMapZoom(state, paylaod) {
			state.mapZoom = paylaod;
		},
		setLightMode(state) {
			state.mapOptions.styles = [
				{
					featureType: 'landscape.man_made',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.man_made',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'labels.text',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural.landcover',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'landscape.natural.terrain',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.attraction',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.business',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.government',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.medical',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.place_of_worship',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.school',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					featureType: 'poi.sports_complex',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
			];
		},
		setNightMode(state) {
			state.mapOptions.styles = state.mapOptions.styles.concat([
				{
					elementType: 'geometry',
					stylers: [
						{
							color: '#242f3e',
						},
					],
				},
				{
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#242f3e',
						},
					],
				},
				{
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#746855',
						},
					],
				},
				{
					featureType: 'administrative.locality',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#d59563',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#d59563',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'geometry',
					stylers: [
						{
							color: '#263c3f',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#6b9a76',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{
							color: '#38414e',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#212a37',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#9ca5b3',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry',
					stylers: [
						{
							color: '#746855',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#1f2835',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#f3d19c',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'geometry',
					stylers: [
						{
							color: '#2f3948',
						},
					],
				},
				{
					featureType: 'transit.station',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#d59563',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#17263c',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#515c6d',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#17263c',
						},
					],
				},
			]);
		},
	},
	actions: {
		async sendLocationToDatabase({ state }, payload) {
			try {
				await state.saveGuardLocation.defaultClient.mutate({
					mutation: updateGuardLocation,
					variables: payload,
				});
			} catch (e) {
				console.log('error updating location');
			}
		},

		async setMap({ state, commit }, payload) {
			state.mapZoom = 18;
			commit('setCenterMap', payload.location);
			commit('addMarker', payload);
		},

		async locationHistory({ state }, payload) {
			try {
				const locationHistory = {
					guardId: payload.id,
					lat: payload.location.lat,
					lng: payload.location.lng,
					timestamp: moment(parseInt(Date.now())).format('YYYY-MM-DD'),
				};
				await state.saveGuardLocation.defaultClient.mutate({
					mutation: addLocationHistory,
					variables: locationHistory,
				});
			} catch (error) {
				console.log(error);
			}
		},

		async clockIn({ state, commit, rootGetters }) {
			try {
				const date = moment().format('YYYY-MM-DD');
				const guardId = rootGetters['userInfo']['http://guardex.com/custom'].app_metadata.guard_id;

				if (
					!state.selectedClockIn ||
					(state.selectedClockIn.clockOut.lat != null &&
						state.selectedClockIn.clockOut.lng != null &&
						state.selectedClockIn.clockOut.timestamp != null)
				) {
					let clockInParams = {
						guardId: guardId,
						date: date,
						clockIn: {
							lat: state.center.lat,
							lng: state.center.lng,
							timestamp: Date.now().toString(),
						},
					};

					await state.saveGuardLocation.defaultClient.mutate({
						mutation: clockIn,
						variables: clockInParams,
						update: (store, { data: { clockIn } }) => {
							clockIn.id = clockIn['_id'];
							delete clockIn.clockIn['__typename'];
							commit('setSelectedClockIn', clockIn);
						},
					});
				}

				let variables = {
					id: guardId,
					isClockedIn: true,
				};

				await state.saveGuardLocation.defaultClient.mutate({
					mutation: updateGuardLocation,
					variables: variables,
				});

				state.clockingLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		async clockOut({ state, dispatch, rootGetters }) {
			if (rootGetters.isAdmin == 'true' || !state.selectedClockIn) {
				return;
			}

			state.clockingLoading = true;

			delete state.selectedClockIn.clockIn['__typename'];
			delete state.selectedClockIn.clockOut['__typename'];

			state.selectedClockIn.clockOut = {
				lat: state.center.lat,
				lng: state.center.lng,
				timestamp: Date.now().toString(),
			};
			let clockOutParams = {
				...state.selectedClockIn,
			};

			await state.saveGuardLocation.defaultClient.mutate({
				mutation: clockOut,
				variables: clockOutParams,
				update: (store, { data: { clockOut } }) => {
					clockOut.id = clockOut['_id'];
					setTimeout(() => {
						state.selectedClockIn = null;
					}, 300000);
				},
			});

			const guardId = rootGetters['userInfo']['http://guardex.com/custom'].app_metadata.guard_id;

			try {
				let variables = {
					id: guardId,
					isClockedIn: false,
				};

				await state.saveGuardLocation.defaultClient.mutate({
					mutation: updateGuardLocation,
					variables: variables,
				});
			} catch (error) {
				console.log(error);
			}

			dispatch('stopWatchingLocation');

			state.clockingLoading = false;
		},

		async watchCurrentLocation({ state, commit, dispatch, rootGetters }, payload) {
			if (payload) {
				await commit('setSelectedClockIn', payload);
			}
			state.clockingLoading = true;
			commit('setClocking', true, { root: true });
			state.saveGuardLocation = createProvider();

			function watchCurrentPositionPromise(options) {
				return new Promise((resolve, reject) => {
					state.positionID = navigator.geolocation.watchPosition(
						async pos => {
							const guardId =
								rootGetters.userInfo['http://guardex.com/custom'].app_metadata.guard_id;
							const crd = pos.coords;
							const lat = crd.latitude;
							const lng = crd.longitude;
							const isClockedIn = rootGetters['isClockedIn'];

							const myPositionDetails = {
								id: guardId,
								// isClockedIn: isClockedIn,
								location: {
									lat: lat,
									lng: lng,
									timestamp: Date.now().toString(),
								},
							};

							dispatch('sendLocationToDatabase', myPositionDetails);
							dispatch('setMap', myPositionDetails);
							dispatch('locationHistory', myPositionDetails);

							resolve(pos);
						},
						error => reject(error),
						options
					);
				});
			}

			let options = {
				maximumAge: 60000,
				timeout: 5000,
				enableHighAccuracy: true,
			};

			watchCurrentPositionPromise(options)
				.then(() => {
					dispatch('clockIn');
				})
				.catch(error => {
					state.clockingLoading = false;
					commit('setClocking', false, { root: true });
					console.log(error.message);
					dispatch('clockOut');
					alert('Make Sure To Enable Location Permission. or Restart Your System!');
				});
		},

		async stopWatchingLocation({ state, commit }) {
			navigator.geolocation.clearWatch(state.positionID);
			state.center = {
				lat: -43.5244148,
				lng: 172.6322926,
			};
			state.mapZoom = 11;
			commit('setClocking', false, { root: true });
		},
	},

	getters: {
		selectedClockIn: state => state.selectedClockIn,
		clockingLoading: state => state.clockingLoading,
		markers: state => state.markers,
		center: state => state.center,
		mapZoom: state => state.mapZoom,
		mapOptions: state => state.mapOptions,
	},
};
