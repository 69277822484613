import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './auth';
import Guards from './guards';
import Clients from './clients';
import Maps from './maps';
import Vehicles from './vehicles';
import IncidentReports from './incidentReports';
import AlarmReports from './alarmReports';
import Roster from './roster';
import Settings from './settings';
import Notifications from './notifications';
import Checkpoint from './checkpoint';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth: Auth,
		guards: Guards,
		clients: Clients,
		maps: Maps,
		vehicles: Vehicles,
		incidentReports: IncidentReports,
		alarmReports: AlarmReports,
		roster: Roster,
		settings: Settings,
		notifications: Notifications,
		checkpoint: Checkpoint,
	},
});
