<template>
  <v-card
    flat
    v-if="selectedAlertTime"
  >
    <v-form
      ref="validate"
      lazy-validation
    >
      <div v-if="!isAdmin">
        <h3>COA Expiry</h3>
        <p style="font-size: 14px">Set the time before being notified (in days)</p>
        <!-- coa expiry -->
        <v-text-field
          v-model="selectedAlertTime.coaExpiryNotificationTime"
          label="COA Expiry"
          append-icon=""
          :rules="[numberRule]"
          type="number"
        />
      </div>
      <!-- vehicle expiry notifications -->
      <div v-if="isAdmin">
        <h3>Vehicle Expiry Notifications</h3>
        <p style="font-size: 14px">Set the time before being notified (in days)</p>
        <v-spacer class="pa-1"></v-spacer>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="selectedAlertTime.regoExpiryNotificationTime"
              label="Rego Expiry"
              append-icon=""
              :rules="[numberRule]"
              type="number"
            />
            <v-text-field
              v-model="selectedAlertTime.vehicleServiceNotificationTime"
              label="Next Service Date"
              append-icon=""
              :rules="[numberRule]"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="selectedAlertTime.wofExpiryNotificationTime"
              label="WoF Expiry"
              append-icon=""
              :rules="[numberRule]"
              type="number"
            />
          </v-col>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { max } from "moment";
import { listSettings, addSettings, updateSettings } from "../graphql/settings";
export default {
  methods: {
    saveAlert() {
      this.expiryNotificationDialog = false;
    },

    resetFields() {
      this.selectedNumberDate = "";
      this.selectedTime = "";
    },

    addCustomAlert() {
      if (this.selectedTime.length < 3) {
        return false;
      } else if (this.selectedNumberDate.length < 0) {
        return false;
      } else this.customAlert = false;
      this.customTimeframeList.push({
        customLabel:
          this.selectedNumberDate + " " + this.selectedTime + " before",
      });

      this.selectedNumberDate = "";
      this.selectedTime = "";
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 58) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {},

  computed: {
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
    selectedAlertTime: {
      get() {
        return this.$store.getters["selectedAlertTime"];
      },
    },
  },

  watch: {
    selectedAlertTime: function (val) {
      if (!val) {
        this.$store.commit("settings/resetSelectedAlertTime");
      }
    },
  },

  data: () => ({
    numberRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return "Number has to be between 0 and 999";
    },
    expiryNotificationDialog: false,
    customAlert: false,
    alertBefore: false,
    alertBeforeList: ["1 Month Before", "1 Week Before", "1 Day Before"],
    weekBefore: "",
    dayBefore: "",
    monthBefore: "",
    max: 4,
    customTimeframeList: [],
    timesList: ["Days", "Weeks", "Months"],
    selectedNumberDate: "",
    selectedTime: "",
    regoExpiry: null,
    nextService: null,
    wofDue: null,
    coaExpiry: null,
  }),
};
</script>

<style>
.v-text-field input {
  font-size: 1.4em;
}
</style>
