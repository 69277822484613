import gql from "graphql-tag";

export const ROSTER_SUBSCRIPTION = gql`
  subscription {
    roster {
      _id
      guard {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const rosterList = {
  query: gql`
    {
      rosterList {
        _id
        guard {
          _id
          firstName
          lastName
        }
        siteLocation {
          _id
          siteName
          siteAddress
        }
        date
        startTime
        endTime
      }
    }
  `,
  update: (data) => {
    data.rosterList.forEach((item) => {
      if (item["_id"]) {
        item.id = item["_id"];
      }
    });
    return data.rosterList;
  },
};

// export const rosterListByGuard = {
//   query: gql`
//     {
//       rosterListByGuard{
//         _id
//         guard{
//           _id
//             firstName
//             lastName
//         }
//         siteLocation{
//           _id
//             siteName
//             siteAddress
//         }
//         date
//         startTime
//         endTime
//       }
//     }
//   `
// }

export const addRoster = gql`
  mutation (
    $guardId: ID!
    $siteLocationId: ID!
    $date: String
    $startTime: String
    $endTime: String
  ) {
    addRoster(
      guardId: $guardId
      siteLocationId: $siteLocationId
      date: $date
      startTime: $startTime
      endTime: $endTime
    ) {
      _id
      guard {
        _id
        firstName
        lastName
      }
      siteLocation {
        _id
        siteName
        siteAddress
      }
      date
      startTime
      endTime
    }
  }
`;

export const updateRoster = gql`
  mutation (
    $id: ID!
    $guardId: ID!
    $siteLocationId: ID!
    $date: String
    $startTime: String
    $endTime: String
  ) {
    updateRoster(
      id: $id
      guardId: $guardId
      siteLocationId: $siteLocationId
      date: $date
      startTime: $startTime
      endTime: $endTime
    ) {
      _id
      guard {
        _id
        firstName
        lastName
      }
      siteLocation {
        _id
        siteName
        siteAddress
      }
      date
      startTime
      endTime
    }
  }
`;

export const deleteRoster = gql`
  mutation ($id: ID!) {
    deleteRoster(id: $id) {
      _id
    }
  }
`;
