<template>
	<div style="width: 100vw; height: 100vh; background-color: #ff9900; color: #ffffff" class="pa-12">
		<div style="maxwidth: 500px; margin: 0 auto" class="mt-16">
			<v-row>
				<v-col>
					<h2>Looks like its your first time signin in or you password has been resetted...</h2>
				</v-col>
			</v-row>
			<v-row class="mt-12">
				<v-col>
					<h4>Your password needs to be changed.</h4>
					<br />
					<v-form ref="form" lazy-validation>
						Enter New Password
						<v-text-field
							:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="[rules.required, rules.min]"
							:type="show1 ? 'text' : 'password'"
							v-model="passwordFieldOne"
							solo
							dark
							@click:append="show1 = !show1"
							:disabled="loading"
							:loading="loading"
						></v-text-field>
						Retype New Password
						<v-text-field
							:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="[v => v == this.passwordFieldOne || `Password do not match`, rules.min]"
							:type="show2 ? 'text' : 'password'"
							v-model="passwordFieldTwo"
							solo
							dark
							@click:append="show2 = !show2"
							:disabled="loading"
							:loading="loading"
						></v-text-field>
					</v-form>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="text-center">
					<v-btn color="#003366" dark @click="submit()" :loading="loading"> Save Changes </v-btn>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
	export default {
		computed: {
			user: {
				get() {
					return this.$store.getters['userInfo'];
				},
			},
		},
		methods: {
			async submit() {
				let valid = this.$refs.form.validate();
				this.loading = true;

				if (valid) {
					const id = this.user['http://guardex.com/custom']['app_metadata'].guard_id;
					let credentials = {
						_id: id,
						password: this.passwordFieldTwo,
					};

					let res = await axios.patch(
						`https://apigw.guardex.co.nz/admin/users/${id}/changePassword`,
						credentials,
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: 'Bearer ' + localStorage.getItem('apollo-token'),
							},
						}
					);

					if (res.data.statusCode == 200) {
						// this.$store.commit("setUserInfo", res.data.res);
						window.location.reload();
					}
				} else {
					this.loading = false;
				}
			},
		},

		data: () => ({
			loading: false,
			show1: false,
			show2: false,
			rules: {
				required: value => !!value || 'Required.',
				min: v => v.length >= 8 || 'Min 8 characters',
			},
			passwordFieldOne: '',
			passwordFieldTwo: '',
		}),
	};
</script>
