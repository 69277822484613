import gql from 'graphql-tag';

export const monitoringCompanyList = {
	query: gql`
		{
			monitoringCompanyList {
				companyName
				address
				_id
				isEnabled
			}
		}
	`,
	update: data => {
		data.monitoringCompanyList.forEach(company => {
			if (company['_id']) {
				company.id = company['_id'];
			}
		});
		return data.monitoringCompanyList;
	},
};
export const enabledMonitoringCompanyList = {
	query: gql`
		{
			enabledMonitoringCompanyList {
				companyName
				address
				_id
				isEnabled
			}
		}
	`,
	update: data => {
		data.enabledMonitoringCompanyList.forEach(company => {
			if (company['_id']) {
				company.id = company['_id'];
			}
		});
		data.enabledMonitoringCompanyList.sort((a, b) => a.companyName.localeCompare(b.companyName));
		return data.enabledMonitoringCompanyList;
	},
};
export const disabledMonitoringCompanyList = {
	query: gql`
		{
			disabledMonitoringCompanyList {
				companyName
				address
				_id
				isEnabled
			}
		}
	`,
	update: data => {
		data.disabledMonitoringCompanyList.forEach(company => {
			if (company['_id']) {
				company.id = company['_id'];
			}
		});
		data.disabledMonitoringCompanyList.sort((a, b) => a.companyName.localeCompare(b.companyName));
		return data.disabledMonitoringCompanyList;
	},
};

export const addMonitoringCompany = gql`
	mutation ($companyName: String!, $address: String!, $isEnabled: Boolean!) {
		addMonitoringCompany(companyName: $companyName, address: $address, isEnabled: $isEnabled) {
			companyName
			address
			_id
			isEnabled
		}
	}
`;

export const updateMonitoringCompany = gql`
	mutation ($companyName: String!, $address: String!, $id: ID!, $isEnabled: Boolean!) {
		updateMonitoringCompany(
			id: $id
			companyName: $companyName
			address: $address
			isEnabled: $isEnabled
		) {
			_id
			companyName
			address
			isEnabled
		}
	}
`;

export const deleteMonitoringCompany = gql`
	mutation ($id: ID!) {
		deleteMonitoringCompany(id: $id) {
			_id
			companyName
			address
		}
	}
`;
