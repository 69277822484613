import gql from 'graphql-tag';

// export const listLocationHistory = {
// 	query: gql `
// 		{
// 			listLocationHistory {
// 			_id
// 			guardId
//             lng
//             lat
//             timestamp
// 			}
// 		}
// 	`,
// 	update: data => {
// 		return data.listLocationHistory;
// 	},
// };

export const listLocationHistory = gql `
	query listLocationHistory($timestamp: String, $guardId: String) {
		listLocationHistory: listLocationHistory(timestamp: $timestamp, guardId: $guardId) {
		_id
		guardId
		 lng
		 lat
		 timestamp
		}
	}
`;

export const addLocationHistory = gql `
	mutation (
        $guardId: ID!
        $lat: Float
        $lng: Float
        $timestamp: String
	) {
		addLocationHistory(
		guardId:$guardId
        lat:$lat
        lng:$lng
        timestamp:$timestamp
		) {
			guardId
			lng
			lat
			timestamp
		}
	}
`;