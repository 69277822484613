import gql from 'graphql-tag';

export const clients = {
	query: gql`
		{
			clients {
				isEnabled
				clientName
				managers {
					name
					contactNumber
					email
				}
				_id
			}
		}
	`,
	update: data => {
		data.clients.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		return data.clients;
	},
};

export const enabledClients = {
	query: gql`
		{
			enabledClients {
				isEnabled
				clientName
				managers {
					name
					contactNumber
					email
				}
				_id
			}
		}
	`,
	update: data => {
		data.enabledClients.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		data.enabledClients.sort((a, b) => a.clientName.localeCompare(b.clientName));
		return data.enabledClients;
	},
};

export const disabledClients = {
	query: gql`
		{
			disabledClients {
				isEnabled
				clientName
				managers {
					name
					contactNumber
					email
				}
				_id
			}
		}
	`,
	update: data => {
		data.disabledClients.forEach(item => {
			if (item['_id']) {
				item.id = item['_id'];
			}
		});
		data.disabledClients.sort((a, b) => a.clientName.localeCompare(b.clientName));
		return data.disabledClients;
	},
};

export const addClient = gql`
	mutation ($clientName: String!, $managers: [ClientManagersInput], $isEnabled: Boolean!) {
		addClient(clientName: $clientName, managers: $managers, isEnabled: $isEnabled) {
			clientName
			managers {
				name
				contactNumber
				email
			}
			_id
			isEnabled
		}
	}
`;

export const updateClient = gql`
	mutation (
		$clientName: String!
		$managers: [ClientManagersInput]
		$id: ID!
		$isEnabled: Boolean!
	) {
		updateClient(clientName: $clientName, managers: $managers, id: $id, isEnabled: $isEnabled) {
			clientName
			managers {
				name
				contactNumber
				email
			}
			_id
			isEnabled
		}
	}
`;

export const deleteClient = gql`
	mutation ($id: ID!) {
		deleteClient(id: $id) {
			clientName
			managers {
				name
				contactNumber
				email
			}
			_id
			isEnabled
		}
	}
`;
