<template>
	<v-app v-if="isAuthenticated">
		<v-fade-transition group>
			<v-main
				key="component1"
				v-if="!isAdmin && userInfo['http://guardex.com/custom']['app_metadata'].firstTime == true"
			>
				<FirstTimeSignIn />
			</v-main>
			<v-main key="component2" v-else>
				<Toolbar v-if="$route.name != 'RouterPage'" />
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive" />
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive" />
			</v-main>
		</v-fade-transition>
		<v-dialog
			v-model="sosActivated"
			v-if="isClockedIn || isAdmin"
			:width="isMobile() ? `100%` : `50vw`"
			persistent
		>
			<v-card height="75vh" class="text-center">
				<div class="d-flex">
					<v-spacer></v-spacer>
					<v-card-actions>
						<v-btn @click="sosActivated = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-actions>
				</div>
				<h1 class="pt-2 pb-4" style="color: red">SOS Activated!!</h1>

				<v-card-subtitle v-if="sos"
					>Activated by:
					{{ sos.guardDetails.firstName + ' ' + sos.guardDetails.lastName }}
					<br />
					<v-spacer></v-spacer> Date &#38; Time: {{ convertDate(sos.geolocation.timestamp) }} <br />
					<v-spacer></v-spacer> Location
					{{ sos.geolocation.lat + ',' + sos.geolocation.lng }}
				</v-card-subtitle>

				<div v-if="sos">
					<GmapMap
						:center="sos.geolocation"
						:zoom="14"
						map-type-id="roadmap"
						style="width: 100%; height: 60vh"
					>
						<div>
							<GmapMarker :position="sos.geolocation" :clickable="true" :draggable="false" />
						</div>
					</GmapMap>
				</div>
			</v-card>
		</v-dialog>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" centered top :color="color">
			{{ notificationText }}
		</v-snackbar>

		<!-- backOnline  -->
		<v-snackbar v-model="backOnline" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
		<BottomNav v-if="$route.name != 'RouterPage'" />
	</v-app>
	<v-app v-else>
		<div style="margin: 0 auto; margin-top: 300px">
			<v-progress-circular
				:size="70"
				:width="7"
				color="#003366"
				indeterminate
				:timeout="60000"
			></v-progress-circular>
		</div>
	</v-app>
</template>

<script>
	import BottomNav from '@/components/BottomNav';
	import Toolbar from '@/components/Toolbar';
	import { mapGetters } from 'vuex';
	import { updateGuardLocation } from './graphql/guard';
	import FirstTimeSignIn from '@/components/FirstTimeSignIn.vue';
	import { SOS_SUBSCRIPTION } from './graphql/sos';
	import { getSettingsById } from './graphql/settings';
	import { vehicles } from './graphql/vehicles';
	import moment from 'moment';
	import { guard } from './graphql/guard';
	import { gmapApi } from 'vue2-google-maps';
	import { CLOCKING_SUBSCRIPTION } from './graphql/clocking';
	import { INCIDENT_SUBSCRIPTION } from './graphql/incidentReports';
	import { ALARM_SUBSCRIPTION } from './graphql/alarmReports';
	import { ROSTER_SUBSCRIPTION } from './graphql/roster';
	import { SUBSCRIPTION_GUARD_UPDATE } from './graphql/guard';
	import { WATCH_CHECKPOINTS } from './graphql/checkpoint';
	import { WATCH_CHECKPOINT_ENTRIES } from './graphql/checkpointEntry';

	export default {
		name: 'App',

		components: {
			BottomNav,
			Toolbar,
			FirstTimeSignIn,
		},

		async mounted() {
			this.isOnline();
		},

		computed: {
			...mapGetters(['isAuthenticated', 'isAdmin', 'userInfo']),
			google: gmapApi,
			center: {
				get() {
					return this.$store.getters['center'];
				},
				set(val) {
					this.$store.commit('setCenterMap', val);
				},
			},
			userInfo: {
				get() {
					return this.$store.getters['userInfo'];
				},
			},
			isLoading: {
				get() {
					return this.$auth.isLoading;
				},
			},
			isClockedIn: {
				get() {
					return this.$store.getters['isClockedIn'];
				},
			},
		},

		watch: {
			backOnline: function () {
				if (this.connectionStatus === true) {
					console.log('online');
					this.checkConnection();
				} else if (this.connectionStatus === false) {
					console.log('offline');
				}
			},
			isLoading: function (val) {
				if (!val) {
					this.init_auth();
				}
			},
			sosActivated: function (val) {
				if (!val) {
					this.audio.pause();
				}
			},
		},

		apollo: {
			$subscribe: {
				sos: {
					prefetch: false,
					query: SOS_SUBSCRIPTION,
					result(data) {
						let result = data.data.sos;
						this.sos = result;
						this.sosActivated = true;
						console.log(data);
						if (this.isClockedIn || this.isAdmin) {
							console.log(this.$store.getters);
							let guardId =
								this.$store.getters.userInfo['http://guardex.com/custom'].app_metadata.guard_id;

							if (guardId == data.data.sos.guardId) {
								return;
							}
							this.audio.play();
							this.$store.commit('addGlobalNotification', `SOS Activated!!`);
						}
					},
				},
			},
			clocking: {
				prefetch: false,
				query: CLOCKING_SUBSCRIPTION,
				result(data) {
					if (this.isAdmin) {
						let result = data.data.clocking;
						let isCondStr = `${
							result.clockOut && result.clockOut.timestamp ? `has clocked out.` : `has clocked in.`
						}`;
						this.$store.commit(
							'addGlobalNotification',
							`${result.guard.firstName} ${result.guard.lastName} ${isCondStr}`
						);
					}
				},
			},
			incidentSub: {
				prefetch: false,
				query: INCIDENT_SUBSCRIPTION,
				result(data) {
					if (this.isAdmin) {
						let result = data.data.incidentSub;
						this.$store.commit('addGlobalNotification', `Incident Response has been created.`);
					}
				},
			},
			alarmSub: {
				prefetch: false,
				query: ALARM_SUBSCRIPTION,
				result(data) {
					if (this.isAdmin) {
						let result = data.data.alarmSub;
						this.$store.commit('addGlobalNotification', `Alarm Response has been created.`);
					}
				},
			},
			roster: {
				prefetch: false,
				query: ROSTER_SUBSCRIPTION,
				result(data) {
					let result = data.data.roster;
					let userInfo =
						this.$store.getters['userInfo']['http://guardex.com/custom'].app_metadata.guard_id;
					if (userInfo == result.guard['_id']) {
						this.$store.commit(
							'addGlobalNotification',
							`Roster created for ${result.guard.firstName} ${result.guard.lastName}`
						);
					}
				},
			},
			guardUpdates: {
				prefetch: false,
				query: SUBSCRIPTION_GUARD_UPDATE,
				async result(data) {
					// console.log(data.data.guardUpdates);
					let guardUpdate = data.data.guardUpdates;
					// console.log(guardUpdate);
					if (
						guardUpdate['_id'] ==
							this.userInfo['http://guardex.com/custom'].app_metadata.guard_id &&
						!guardUpdate.isEnabled
					) {
						// await this.$store.dispatch("clockOut");
						alert('Your account has been disabled');
						location.reload();
					}
					if (
						guardUpdate['_id'] ==
							this.userInfo['http://guardex.com/custom'].app_metadata.guard_id &&
						data.data.guardUpdates.isClockedIn != this.isClockedIn
					) {
						// await this.$store.dispatch("watchCurrentLocation");
						location.reload();
					}

					// else if (
					//   guardUpdate["_id"] ==
					//     this.userInfo["http://guardex.com/custom"].app_metadata.guard_id &&
					//   data.data.guardUpdates.isClockedIn === false
					// ) {
					//   await this.$store.dispatch("clockOut");
					// }
				},
			},

			checkpoint: {
				prefetch: false,
				query: WATCH_CHECKPOINTS,
				result(data) {
					if (this.isAdmin) {
						let result = data.data.checkpoint;
						this.$store.commit('addGlobalNotification', `Checkpoint Location Added.`);
					}
				},
			},

			checkpointEntries: {
				prefetch: false,
				query: WATCH_CHECKPOINT_ENTRIES,
				result(data) {
					if (this.isAdmin) {
						let result = data.data.checkpointEntries;
						this.$store.commit('addGlobalNotification', `${result.guard} Scanned A Checkpoint`);
					}
				},
			},
		},

		methods: {
			async isOnline() {
				setInterval(async () => {
					let currentOnlineStatus = localStorage.getItem('currentOnlineStatus');
					if (!window.navigator.onLine && currentOnlineStatus == 'online') {
						this.notificationText =
							'No internet connection, please check your internet connection and try again.';
						this.color = 'red';
						this.notify = true;
						localStorage.setItem('currentOnlineStatus', 'offline');
					} else if (window.navigator.onLine && currentOnlineStatus == 'offline') {
						this.notificationText = 'Connection is back online.';
						this.color = 'green darken-1';
						this.backOnline = true;
						localStorage.setItem('currentOnlineStatus', 'offline');
					}

					// avoid CORS errors with a request to your own origin
					const url = new URL(window.location.origin);

					// random value to prevent cached responses
					url.searchParams.set('rand', this.getRandomString());

					try {
						const response = await fetch(url.toString(), { method: 'HEAD' });
						this.notify = false;
						return response.ok;
					} catch {
						return false;
					}
				}, 2000);
				//set interval to 30 seconds
			},

			checkConnection() {
				this.notificationText = 'Connection is back online.';
				this.color = 'green darken-1';
				this.backOnline = true;
				// this.$router.go()
			},

			getRandomString() {
				return Math.random().toString(36).substring(2, 15);
			},
			async init_auth() {
				let isSignedIn = this.$auth.isAuthenticated;

				if (!isSignedIn) {
					try {
						this.$auth.loginWithRedirect();
					} catch (e) {
						console.log(e);
					}
				} else {
					let accessToken = await this.$auth.getTokenSilently();
					localStorage.setItem('apollo-token', accessToken);
					let payload = {
						isAuthenticated: isSignedIn,
						isAdmin: this.$auth.user['http://guardex.com/custom'].app_metadata.isAdmin,
						userInfo: this.$auth.user,
						accessToken: accessToken,
					};
					await this.$store.commit('setAuthentication', payload);
					let notification = JSON.parse(localStorage.getItem('notifications'))
						? JSON.parse(localStorage.getItem('notifications'))
						: [];
					this.$store.commit('setNotificationList', notification);
					localStorage.setItem('currentOnlineStatus', 'online');

					this.$apollo.addSmartQuery('selectedAlertTime', {
						query: getSettingsById,
						variables() {
							return {
								guardId: this.$auth.user['http://guardex.com/custom'].app_metadata.isAdmin
									? 'ADMINISTRATOR'
									: this.$auth.user['http://guardex.com/custom'].app_metadata.guard_id,
							};
						},
						update: data => {
							this.$store.commit('setSelectedAlertTime', data.selectedAlertTime);
							this.checkForNotifications();
						},
					});
				}
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			convertDate(d) {
				return d && d.length > 0 ? moment(parseInt(d)).format('DD/MM/yyyy  HH:mm') : '';
			},

			checkForNotifications() {
				if (this.isAdmin) {
					this.checkExpirationDatesAdmin();
				} else {
					this.checkExpirationDatesGuards();
				}
			},
			async checkExpirationDatesAdmin() {
				let settings = this.$store.getters['selectedAlertTime'];
				let cars = [];
				await this.$apollo.addSmartQuery('vehicles', {
					query: vehicles.query,
					update: data => {
						cars = data.vehicles;
						for (var i = 0; i < cars.length; i++) {
							let notifyRego = moment(cars[i].regoExpiry)
								.subtract(settings.regoExpiryNotificationTime, 'days')
								.format('YYYY-MM-DD');

							let notifyWOF = moment(cars[i].wofExpiry)
								.subtract(settings.wofExpiryNotificationTime, 'days')
								.format('YYYY-MM-DD');

							let notifyService = moment(cars[i].nextServiceDate)
								.subtract(settings.vehicleServiceNotificationTime, 'days')
								.format('YYYY-MM-DD');

							if (moment().isAfter(moment(notifyRego))) {
								this.$store.commit(
									'addNotification',
									`Vehicle rego expiring soon for ${cars[i].rego}`
								);
							}
							if (moment(notifyWOF).isBefore(moment())) {
								this.$store.commit(
									'addNotification',
									`Vehicle WoF expiring soon for ${cars[i].rego}`
								);
							}
							if (moment(notifyService).isBefore(moment())) {
								this.$store.commit(
									'addNotification',
									`Vehicle service required soon for ${cars[i].rego}`
								);
							}
						}
					},
				});
			},
			async checkExpirationDatesGuards() {
				let settings = this.$store.getters['selectedAlertTime'];

				// Get Guard Details
				await this.$apollo.addSmartQuery('guard', {
					query: guard,
					variables() {
						return {
							id: this.$store.getters['userInfo']['http://guardex.com/custom'].app_metadata
								.guard_id,
						};
					},
					update: data => {
						let guard = data.selectedGuard;
						this.$store.commit('setGuardDetails', guard);
						if (
							!settings ||
							!settings.coaExpiryNotificationTime ||
							settings.coaExpiryNotificationTime.length == '0'
						)
							return;
						if (!guard.coaExp) return;
						let notifyCOAExp = moment(guard.coaExp)
							.subtract(settings.coaExpiryNotificationTime, 'days')
							.format('YYYY-MM-DD');
						if (moment(notifyCOAExp).isBefore(moment())) {
							this.$store.commit('addNotification', `Guard COA Expiring!`);
						}
					},
				});
			},
		},

		data: () => ({
			sos: null,
			sosActivated: false,
			connectionStatus: window.navigator.onLine,
			notify: false,
			notificationText: '',
			color: '',
			backOnline: false,
			audio: new Audio('https://freesound.org/data/previews/329/329436_796067-lq.mp3'),
		}),
	};

	window.addEventListener('contextmenu', function (e) {
		e.preventDefault();
	});

	// window.addEventListener("touchstart", (e) => {
	//   // is not near edge of view, exit
	//   if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

	//   // prevent swipe to navigate back gesture
	//   e.preventDefault();
	// });
</script>

<style scoped>
	html,
	body {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		background-color: #acbcc7;
	}
</style>
