import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Guards from '../views/Guards.vue';
import Calendar from '../views/Calendar.vue';
import Vehicles from '../views/Vehicles.vue';
import Settings from '../views/Settings.vue';
import RouterPage from '../views/Login.vue';
import GuardDashboard from '../views/GuardDashboard';
import Reports from '../views/Reports.vue';
import CreateIncidentReport from '../views/CreateIncidentReport';
import CreateAlarmReport from '../views/CreateAlarmReport';
import Organizations from '../views/Organizations';
import GuardCalendar from '../views/GuardCalendar.vue';
import NotificationSettings from '../views/NotificationSettings.vue';
import EmailTemplate from '../views/EmailTemplate.vue';
import AccountSettings from '../views/AccountSettings.vue';
import LocationHistory from '../views/LocationHistory.vue';

Vue.use(VueRouter);

const routes = [{
		path: '/',
		name: 'RouterPage',
		component: RouterPage,
	},
	{
		path: '/Dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			keepAlive: true,
		},
	},
	{
		path: '/GuardDashboard',
		name: 'GuardDashboard',
		component: GuardDashboard,
		meta: {
			keepAlive: true,
		},
	},
	{
		path: '/Guards',
		name: 'Guards',
		component: Guards,
	},
	{
		path: '/GuardCalendar',
		name: 'GuardCalendar',
		component: GuardCalendar,
	},
	{
		path: '/Calendar',
		name: 'Calendar',
		component: Calendar,
	},
	// {
	//   path: "/Clients",
	//   name: "Clients",
	//   component: Clients,
	// },
	{
		path: '/Vehicles',
		name: 'Vehicles',
		component: Vehicles,
	},
	{
		path: '/Settings',
		name: 'Settings',
		component: Settings,
	},
	{
		path: '*',
		redirect: '/',
	},
	{
		path: '/Reports',
		name: 'Reports',
		component: Reports,
	},
	{
		path: '/CreateIncidentReport',
		name: 'Incident Report',
		component: CreateIncidentReport,
	},
	{
		path: '/CreateAlarmReport',
		name: 'Alarm Report',
		component: CreateAlarmReport,
	},
	{
		path: '/Organizations',
		name: 'Organizations',
		component: Organizations,
	},
	{
		path: '/NotificationSettings',
		name: 'Notification Settings',
		component: NotificationSettings,
	},
	{
		path: '/EmailTemplate',
		name: 'Email Template',
		component: EmailTemplate,
	},
	{
		path: '/AccountSettings',
		name: 'Account Settings',
		component: AccountSettings,
	},
	{
		path: '/LocationHistory',
		name: 'Location History',
		component: LocationHistory,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;