export default {
  namepaced: true,
  state: {
    selectedVehicle: null,
  },
  mutations: {
    setSelectedVehicle(state, payload) {
      if (payload["_id"]) {
        payload.id = payload["_id"];
        delete payload["_id"];
      }
      state.selectedVehicle = payload;
    },
    resetSelectedVehicle(state) {
      state.selectedVehicle = {
        id: null,
        rego: "",
        color: "",
        regoExpiry: "",
        wofExpiry: "",
        nextServiceDate: "",
        make: "",
        model: "",
        year: "",
        notes: "",
      };
    },
  },
  actions: {
    //
  },

  getters: {
    selectedVehicle: (state) => state.selectedVehicle,
  },
};
