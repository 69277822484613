export default {
	state: {
		hasSeen: true,
		notificationList: [],
		localNotificationList: [],
		notificationCount: 0,
	},
	mutations: {
		addNotification(state, payload) {
			setTimeout(() => {
				let date = Date.now()
				let notificationObject = {
					message: payload,
					time: date,
					type: "local",
				}
				state.localNotificationList.unshift(notificationObject);
			}, 1)
			if (state.notificationList.length + state.localNotificationList.length >= 25) {
				state.localNotificationList + state.notificationList.pop();

			}
		},
		addGlobalNotification(state, payload) {
			state.hasSeen = false;
			setTimeout(
				() => {
					let date = Date.now()
					let notificationObject = {
						message: payload,
						time: date,
						type: "global",
					}
					state.notificationCount++;
					state.notificationList.unshift(notificationObject);
					localStorage.setItem("notifications", JSON.stringify(state.notificationList))
				}, 1
			)
			if (state.notificationList.length + state.localNotificationList >= 25) {
				state.localNotificationList + state.notificationList.pop()
			}
		},
		resetHasSeen(state) {
			state.hasSeen = true;
			state.notificationCount = 0;
		},
		setNotificationList(state, payload) {
			state.notificationList = payload
		},
		removeNotification(state, payload) {
			state.notificationList.splice(payload, 1)
			localStorage.setItem("notifications", JSON.stringify(state.notificationList))
		},
		clearNotification(state, payload) {
			state.notificationList.splice(payload)
			state.localNotificationList.splice(payload)
			localStorage.setItem("notifications", JSON.stringify(state.notificationList))
		},
	},
	actions: {},
	getters: {
		notificationList: state => state.notificationList,
		hasSeen: state => state.hasSeen,
		notificationCount: state => state.notificationCount,
		localNotificationList: state => state.localNotificationList,
	},
};