export default {
	state: {
		isAuthenticated: false,
		isAdmin: false,
		userInfo: null,
		accessToken: null,
		firstTimeSignedIn: false,
		isClockedIn: false,
		guardDetails: null,
	},
	mutations: {
		setClocking(state, payload) {
			state.isClockedIn = payload;
		},
		setAuthentication(state, payload) {
			if (payload) {
				state.isAuthenticated = payload.isAuthenticated;
				state.isAdmin = payload.isAdmin;
				state.userInfo = payload.userInfo;
				state.accessToken = payload.accessToken;
			}
		},
		setUserInfo(state, payload) {
			state.userInfo = payload;
		},
		setGuardDetails(state, payload) {
			state.guardDetails = payload;
		},
	},
	actions: {},
	getters: {
		isAuthenticated: state => state.isAuthenticated,
		isAdmin: state => state.isAdmin,
		userInfo: state => state.userInfo,
		isClockedIn: state => state.isClockedIn,
		guardDetails: state => state.guardDetails,
	},
};
