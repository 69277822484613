import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { createProvider } from './vue-apollo';
import * as VueGoogleMaps from 'vue2-google-maps';

// Import the Auth0 configuration and plugin
import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from '@/auth/auth0-plugin';

Vue.use(Auth0Plugin, {
	domain,
	clientId,
	audience,
	onRedirectCallback: appState => {
		router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
	},
});

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyDZoVkLJGHntA28ooMmFN5lvvNffaPPup4',
		libraries: 'places',
	},
	installComponents: true,
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	apolloProvider: createProvider(),
	render: h => h(App),
}).$mount('#app');
