import gql from 'graphql-tag';

export const listClocking = {
	query: gql`
		{
			listClocking {
				_id
				date
				guardId
				clockIn {
					lat
					lng
					timestamp
				}
				clockOut {
					lat
					lng
					timestamp
				}
				guard {
					firstName
					lastName
				}
			}
		}
	`,
	update: data => {
		return data.listClocking;
	},
};

export const listClockingByDate = gql`
	query clockInList($clockDate: String!, $guardId: String!) {
		clockInList: listClockingByGuardAndDate(clockDate: $clockDate, guardId: $guardId) {
			_id
			guardId
			date
			clockIn {
				timestamp
				lat
				lng
			}
			clockOut {
				timestamp
				lat
				lng
			}
		}
	}
`;

export const clockIn = gql`
	mutation ($guardId: ID!, $date: String!, $clockIn: LocationInput, $clockOut: LocationInput) {
		clockIn(guardId: $guardId, date: $date, clockIn: $clockIn, clockOut: $clockOut) {
			_id
			guardId
			date
			clockIn {
				lat
				lng
				timestamp
			}
			clockOut {
				lat
				lng
				timestamp
			}
		}
	}
`;

export const clockOut = gql`
	mutation (
		$id: ID!
		$guardId: ID!
		$date: String!
		$clockIn: LocationInput
		$clockOut: LocationInput
	) {
		clockOut(id: $id, guardId: $guardId, date: $date, clockIn: $clockIn, clockOut: $clockOut) {
			_id
		}
	}
`;

export const CLOCKING_SUBSCRIPTION = gql`
	subscription {
		clocking: clocking {
			_id
			guardId
			guard {
				firstName
				lastName
			}
			date
			clockIn {
				lat
				lng
				timestamp
			}
			clockOut {
				lat
				lng
				timestamp
			}
		}
	}
`;
