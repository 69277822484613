import gql from 'graphql-tag';

export const listSOS = {
	query: gql`
		{
			listSOS {
				_id
				guardId
				geolocation {
					lat
					lng
					timestamp
				}
			}
		}
	`,
};

export const addSOS = gql`
	mutation ($guardId: String, $geolocation: LocationInput) {
		addSOS(guardId: $guardId, geolocation: $geolocation) {
			_id
			guardId
			geolocation {
				lat
				lng
				timestamp
			}
		}
	}
`;

export const SOS_SUBSCRIPTION = gql`
	subscription {
		sos {
			_id
			guardDetails {
				firstName
				lastName
			}
			guardId
			geolocation {
				lat
				lng
				timestamp
			}
		}
	}
`;
