export default {
    namespaced: true,
    state: {
        rosterList: []
    },
    mutations: {
        addToRosterList(state, payload) {
            state.rosterList.push(payload)
        }
    },
    actions: {
        //
    },

    getters: {
        rosterList: (state) => state.rosterList,
    },
};
