import gql from 'graphql-tag';

export const listCheckpoints = {
	query: gql`
		{
			listCheckpoints {
				_id
				checkpoint
				siteLocation
				qrCodeUrl
				siteId
			}
		}
	`,
	update: data => data.listCheckpoints,
};

export const filterCheckpoints = gql`
	query filterCheckpoints($siteId: String) {
		filterCheckpoints(siteId: $siteId) {
			_id
			checkpoint
			siteLocation
			qrCodeUrl
			siteId
		}
	}
`;

export const deleteCheckpoint = gql`
	mutation ($id: ID!) {
		deleteCheckpoint(id: $id) {
			_id
			# checkpoint
			# siteLocation
			# qrCodeUrl
			# siteId
		}
	}
`;

export const addCheckpoint = gql`
	mutation ($checkpoint: String, $siteLocation: String, $qrCodeUrl: String, $siteId: String) {
		addCheckpoint(
			checkpoint: $checkpoint
			siteLocation: $siteLocation
			qrCodeUrl: $qrCodeUrl
			siteId: $siteId
		) {
			_id
			checkpoint
			siteLocation
			qrCodeUrl
			siteId
		}
	}
`;

export const WATCH_CHECKPOINTS = gql`
	subscription {
		checkpoint {
			_id
			checkpoint
			siteLocation
			qrCodeUrl
			siteId
		}
	}
`;
