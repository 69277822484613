export default {
  namepaced: true,
  state: {
    selectedGuard: null,
  },
  mutations: {
    setSelectedGuard(state, payload) {
      if (payload["_id"]) {
        payload.id = payload["_id"];
        delete payload["_id"];
        delete payload.nextOfKin["__typename"];
      }
      state.selectedGuard = payload;
    },

    resetSelectedGuard(state) {
      state.selectedGuard = {
        id: null,
        isEnabled: true,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        licenseNumber: "",
        coaNumber: "",
        coaExp: "",
        notes: "",
        nextOfKin: { firstName: "", lastName: "", mobile: "" },
      };
    },
  },
  actions: {
    //
  },

  getters: {
    selectedGuard: (state) => state.selectedGuard,
  },
};
