<template>
  <div
    v-if="isAuthenticated && !isAdmin"
    style="height: 100%; overflow: hidden"
  >
    <div v-if="!isAdmin && isMobile()">
      <GmapMap
        v-if="center"
        :center="center"
        :zoom="mapZoom"
        :options="options"
        map-type-id="roadmap"
        style="width: 100%; height: 60vh"
      >
        <div v-if="isClockedIn">
          <GmapMarker
            :key="m.id"
            v-for="m in markers"
            :position="m.location"
            :clickable="true"
            :draggable="false"
          />
        </div>
      </GmapMap>

      <v-card
        min-height="35vh"
        width="100%"
        style="position: fixed; bottom: 20px"
        elevation="10"
        raised
      >
        <!-- clockInOut() -->
        <v-btn
          fab
          dark
          small
          width="150"
          height="150"
          style="position: absolute; bottom: 24vh; left: 30vw"
          elevation="3"
          :color="isClockedIn ? `secondary` : `primary`"
          :loading="clockingLoading"
          @click="initClockInOut"
        >
          <v-icon size="50">
            {{ isClockedIn ? `mdi-timer-off` : `mdi-timer` }}
          </v-icon>
        </v-btn>

        <div
          style="margin-top: 110px; overflow: hidden"
          class="text-center"
        >
          <v-row style="max-height: 100px">
            <v-col cols="6">
              <h3>Clocked In</h3>
              <h2>
                {{
                  selectedClockIn
                    ? convertTimestampToTime(selectedClockIn.clockIn.timestamp)
                    : `00:00`
                }}
              </h2>
            </v-col>

            <v-col cols="6">
              <h3>Clocked Out</h3>
              <h2>
                {{
                  selectedClockIn
                    ? convertTimestampToTime(selectedClockIn.clockOut.timestamp)
                    : `00:00`
                }}
              </h2>
            </v-col>
          </v-row>
          <br />
          {{ todaysDate() }}
        </div>
      </v-card>

      <v-dialog
        v-model="clockInOutDialog"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title class="text-h5">
            <v-icon
              class="mr-2"
              color="info"
              large
            >
              mdi-information
            </v-icon>
            {{ isClockedIn ? `Confirm Clock-Out` : `Confirm Clock-In`}}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="mt-2">
            {{ isClockedIn ? `Are you sure you want to clock-out?` : `Are you sure you want to clock-in?` }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              @click="clockInOutDialog = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              @click=" () => {
                isClockedIn ? clockOut() : clockIn(); clockInOutDialog = !clockInOutDialog
              }"
              color="green darken-1"
              text
            >
              {{ isClockedIn ? `Clock-Out` : `Clock-In`}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <div
      v-else
      class="ma-12"
    >
      <h3>Available on mobile only.</h3>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
import { listClockingByDate } from "../graphql/clocking";

export default {
  name: "App",

  apollo: {
    clockInList: {
      query: listClockingByDate,
      variables() {
        return {
          guardId:
            this.$store.getters["userInfo"]["http://guardex.com/custom"]
              .app_metadata.guard_id,
          clockDate: moment().format("YYYY-MM-DD"),
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
  methods: {
    convertTimestampToTime(timestamp) {
      if (timestamp == null) {
        return "00:00";
      } else {
        return moment(parseInt(timestamp)).format("HH:mm");
      }
    },
    todaysDate() {
      return moment().format("ddd - DD MMM YYYY");
    },
    initClockInOut() {
      this.clockInOutDialog = true;
    },

    async clockIn() {
      await this.$store.dispatch("watchCurrentLocation");
    },

    async clockOut() {
      await this.$store.dispatch("clockOut");
    },

    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
    async checkCurrentClocking() {
      if (this.$apollo.queries.clockInList.loading == false) {
        this.clockInList.forEach(async (clock) => {
          if (
            !clock.clockOut ||
            (clock.clockOut.lat == null &&
              (clock.clockOut.lng == null) & (clock.clockOut.timestamp == null))
          ) {
            clock.id = clock["_id"];
            await this.$store.dispatch("watchCurrentLocation", clock);
          }
        });
      } else {
        setTimeout(() => {
          this.checkCurrentClocking();
        }, 10);
      }
    },
  },

  async mounted() {
    this.clockingLoading = true;
    if (this.isAdmin) {
      this.$router.push("/Dashboard");
    }

    await this.checkCurrentClocking();

    this.clockingLoading = false;
  },

  computed: {
    clockingLoading: {
      get() {
        return this.$store.getters["clockingLoading"];
      },
      set(val) {
        this.$store.commit("setClockingLoading", val);
      },
    },
    selectedClockIn: {
      get() {
        return this.$store.getters["selectedClockIn"];
      },
    },
    myGeolocation: {
      get() {
        return this.$store.getters["markers"];
      },
    },
    userInfo: {
      get() {
        return this.$store.getters["userInfo"];
      },
    },
    isClockedIn: {
      get() {
        return this.$store.getters["isClockedIn"];
      },
    },
    ...mapGetters(["isAuthenticated", "isAdmin", "userInfo"]),
    options: {
      get() {
        return this.$store.getters["mapOptions"];
      },
    },
    mapZoom: {
      get() {
        return this.$store.getters["mapZoom"];
      },
      set(val) {
        this.$store.commit("setMapZoom", val);
      },
    },
    markers: {
      get() {
        return this.$store.getters["markers"];
      },
    },
    google: gmapApi,
    center: {
      get() {
        return this.$store.getters["center"];
      },
      set(val) {
        this.$store.commit("setCenterMap", val);
      },
    },
  },

  components: {},

  data: () => ({
    clockInOutDialog: false,
    clockInList: [],
    clockDate: null,
    guardId: null,
    myData: null,
    markerInfo: {
      isOpened: false,
      position: null,
      content: null,
      panTo: null,
    },

    sosColor: `#999999`,
    //hard coded for now haha
    headers: [
      {
        text: "Client",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Location", sortable: false, value: "location" },
      { text: "Report Date", value: "reportDate" },
      { text: "Time", value: "time" },
      { text: "Actions", sortable: false, value: "actions" },
    ],
  }),
};
</script>

<style scoped>
.emoji {
  font-size: 10px;
  width: 10px;
  height: auto;
}
</style>
