<template>
  <v-container
    fill-height
    fluid
  >
    <!-- <v-btn
      width="300px"
      height="300px"
      class="btn btn-primary btn-block"
      v-if="!$auth.isAuthenticated"
      @click="login"
    >Log In</v-btn>
    <v-btn
      @click="logout"
      v-else
    >
      logout
    </v-btn> -->
    <!-- <v-row
      style="height: 100vh"
      align="center"
    >
      <v-col cols="12">
        <v-img
          class="mx-auto my-auto mb-2"
          src="@/assets/gaurdexlogo.png"
          max-width="350"
        >
        </v-img>
        <v-container style="max-width: 400px">
          <v-form ref="form">
            <v-text-field
              class="mt-4"
              label="Username"
              color="#EA690B"
              filled
              solo
            >
            </v-text-field>
            <v-text-field
              class="mt-2"
              label="Password"
              type="password"
              filled
              color="#EA690B"
              solo
            >
            </v-text-field>

            <v-btn
              type="submit"
              class="mt-2 pa-8 white--text"
              color="#EA690B"
              large
              block
              rounded
              @click="signIn"
            >
              SignIn
            </v-btn>
            <v-row class="mt-1">
              <v-btn
                class="mx-auto"
                color="grey"
                text
                @click="auth()"
              > Forgot Password </v-btn>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  created() {
    if (this.$store.getters["isAdmin"]) {
      this.$router.push("Dashboard");
    } else {
      this.$router.push("GuardDashboard");
    }
  },

  computed: {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
    },
  },
};
</script>
