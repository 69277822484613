<template>
  <div
    v-if="isAdmin"
    :style="
      !isMobile()
        ? `max-width: 70vw; margin: 0 auto`
        : `max-width: 95vw;margin: 0 auto`
    "
    class="pb-16 pt-6"
  >
    <!-- map card -->
    <p class="ma-0 pt-2 d-flex">
      <span class="emoji mt-1">🔴</span>
      <span class="ml-2 pb-2">Guards Locations</span>
    </p>
    <v-card
      v-if="markers"
      class="mx-auto"
    >
      <GmapMap
        v-if="center"
        :center="center"
        :zoom="12"
        :options="options"
        map-type-id="roadmap"
        style="width: 100%; height: 60vh"
      >
        <gmap-info-window
          :options="markerInfo.options"
          :position="markerInfo.position"
          :opened="markerInfo.isOpened"
          :content="markerInfo.content"
          @closeclick="markerInfo.isOpened = false"
          style="color: black"
        >
        </gmap-info-window>
        <GmapMarker
          :key="m.id"
          v-for="(m, i) in markers"
          :position="m.location"
          :clickable="true"
          :draggable="false"
          :label="m.firstName[0]"
          @click="openMarkerInfo(m, i)"
        />
      </GmapMap>
    </v-card>
    <!-- sos card -->
    <!-- <div class="text-center">
      <p class="text-center"></p>
      <v-avatar
        class="pt-4"
        tile
        width="100%"
        height="100"
        :color="sosColor"
      >
        <h1 class="white--text pb-4">S O S</h1>
      </v-avatar>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="success"
        width="300"
        height="300"
      >
        <v-icon
          dark
          size="150"
        > mdi-car-brake-alert </v-icon>
      </v-btn>
    </div> -->

    <v-row>
      <!-- sos card -->

      <v-col class="mt-8">
        <!-- reports card -->
        <v-card
          class="mx-auto"
          :color="$vuetify.theme.dark ? `#003366` : ``"
        >
          <v-card-title>
            <p>Today's Reports</p>
          </v-card-title>
          <v-data-table
            :dark="$vuetify.theme.dark"
            :headers="headers"
            :items="combinedReportsList"
            :items-per-page="15"
            :footer-props="{
    'items-per-page-options': [15, 25, 50, -1]
  }"
            class="elevation-1"
            :style="
              $vuetify.theme.dark
                ? `table-layout: fixed; width: 100%; background-color: #003366`
                : ``
            "
            single-expand
            mobile-breakpoint="600"
            disable-sort
            disable-filtering
            disable-pagination
          >
            <template v-slot:[`item.reportType`]="{ item }">
              <td>
                {{ item["__typename"] }}
              </td>
            </template>
            <template v-slot:[`item.guardDetails`]="{ item }">
              <td>
                {{
                  item.guardDetails.firstName + " " + item.guardDetails.lastName
                }}
              </td>
            </template>
            <template v-slot:[`item.siteLocation`]="{ item }">
              <td>
                {{ item.siteLocation.siteName }}
              </td>
            </template>
            <template v-slot:[`item.policeRequired`]="{ item }">
              <td>
                {{ item.policeRequired ? "Yes" : "No" }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <!-- reports card -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapGetters, mapMutations } from "vuex";
import {
  getGuardsLocation,
  SUBSCRIPTION_GUARD_LOCATION,
} from "../graphql/guard";
import { listTodaysIncident } from "../graphql/incidentReports";
import { listTodaysAlarm } from "../graphql/alarmReports";

export default {
  name: "App",

  apollo: {
    markers: getGuardsLocation,
    $subscribe: {
      markers: {
        prefetch: false,
        query: SUBSCRIPTION_GUARD_LOCATION,
        result (data) {
          let result = data.data.guardLocation;
          const index = this.markers.findIndex(
            (marker) => marker["_id"] == result["_id"]
          );

          if (result.isClockedIn === true) {
            if (index == -1) {
              this.markers.push(result);
            } else {
              this.markers.splice(index, 1);
              this.markers.push(result);
            }
          } else {
            if (index > -1) {
              this.markers.splice(index, 1);
            }
          }
        },
      },
    },
    incidentReportList: listTodaysIncident,
    alarmReportList: listTodaysAlarm,
  },

  methods: {
    openMarkerInfo (val, index) {
      this.markerInfo.isOpened = true;
      this.markerInfo.position = this.markers[index].location;
      this.markerInfo.options = {
        pixelOffset: {
          width: 0,
          height: -35,
        },
        content: `
        <html>
          <body style="text-left; padding: 20px,20px,20px,20px">
          <h2 style="color: black">${val.firstName + " " + val.lastName}</h2>
          <br>
          <h3><a href="tel:${val.mobile}">${val.mobile}</a></h3>
          <!--------<h4>Last seen: ${val.location.timestamp}</h4>---->
          </body>
        </html>
      `,
      };
      this.$store.commit("setMapZoom", 17);
      this.markerInfo.panTo = val.location;
    },
    sosActivated () {
      this.sosColor = `black`;
    },

    isMobile () {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
  },

  // created() {
  //   this.initializeApp();
  // },

  mounted () {
    if (!this.isAdmin) {
      this.$router.push("/GuardDashboard");
    }
  },

  computed: {
    combinedReportsList () {
      let combined = this.alarmReportList.concat(this.incidentReportList);
      return combined;
    },

    ...mapGetters(["isAdmin", "userInfo"]),
    options: {
      get () {
        return this.$store.getters["mapOptions"];
      },
    },
    mapZoom: {
      get () {
        return this.$store.getters["mapZoom"];
      },
      set (val) {
        this.$store.commit("setMapZoom", val);
      },
    },
    google: gmapApi,
    // center: {
    //   get() {
    //     return this.$store.getters["center"];
    //   },
    //   set(val) {
    //     this.$store.commit("setCenterMap", val);
    //   },
    // },
  },

  components: {
    //
  },

  data: () => ({
    swRegistration: null,
    alarmReportList: [],
    incidentReportList: [],
    center: { lat: parseFloat(-43.5357934), lng: parseFloat(172.6614121) },
    markerInfo: {
      isOpened: false,
      position: null,
      content: null,
      panTo: null,
    },
    markers: [],
    sosColor: `#999999`,
    //hard coded for now haha
    headers: [
      {
        text: "Report Type",
        sortable: true,
        value: "reportType",
      },
      {
        text: "Guard On Duty",
        align: "start",
        sortable: false,
        value: "guardDetails",
      },
      { text: "Site Location", sortable: false, value: "siteLocation" },
      { text: "Police Required", value: "policeRequired" },
      // { text: "View", value: "viewReport" },
    ],
  }),
};
</script>

<style scoped>
.emoji {
  font-size: 10px;
  width: 10px;
  height: auto;
}
</style>
