<template>
	<v-row class="fill-height">
		<v-col>
			<v-sheet height="64">
				<v-toolbar flat>
					<v-btn fab text small color="grey darken-2" @click="prev">
						<v-icon small> mdi-chevron-left </v-icon>
					</v-btn>
					<v-toolbar-title v-if="$refs.calendar">
						{{ $refs.calendar.title }}
					</v-toolbar-title>
					<v-btn fab text small color="grey darken-2" @click="next">
						<v-icon small> mdi-chevron-right </v-icon>
					</v-btn>
					<v-spacer></v-spacer>
				</v-toolbar>
			</v-sheet>
			<v-sheet :height="!isMobile() ? `70vh` : `40vh`">
				<v-calendar
					ref="calendar"
					v-model="focus"
					color="primary"
					type="month"
					@click:more="openDialog()"
					@click:date="openDialog()"
					@click:event="setSelectedRoster"
					:events="events"
					event-color="secondary"
					:weekdays="weekdays"
				>
				</v-calendar>
			</v-sheet>
		</v-col>

		<v-dialog
			v-model="dialog"
			:fullscreen="isMobile()"
			width="80vw"
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card height="80vh" :loading="loading">
				<v-toolbar dark color="secondary" absolute width="100%">
					<!-- <v-btn fab text small color="grey darken-2" @click="prevDay()">
            <v-icon color="white" small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="nextDay()">
            <v-icon small color="white"> mdi-chevron-right </v-icon>
          </v-btn> -->
					<v-toolbar-title>{{ convertDateTitle }}</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-fab-transition>
						<v-btn v-if="!isMobile()" icon dark @click="rosterDialog = true">
							<v-icon>mdi-calendar-plus</v-icon>
						</v-btn>
					</v-fab-transition>
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-calendar
					class="pt-16"
					ref="calendar2"
					v-model="focus"
					color="red"
					type="day"
					@click:date="openDialog()"
					hide-header
					:events="events"
					@click:event="setSelectedRoster"
					event-color="secondary"
					:interval-format="intervalFormat"
				>
					<template v-slot:day="{ date }">
						<div style="background-color: gray" @click="() => {}">
							{{ date }}
						</div>
					</template>

					<template v-slot:event="{ event }">
						<div class="v-event-draggable ma-2">
							<h3>{{ event.name }}</h3>

							<h4>{{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}</h4>
						</div>
					</template>
				</v-calendar>

				<v-fab-transition>
					<v-btn
						v-if="isMobile()"
						color="primary"
						fab
						absolute
						right
						style="bottom: 20px; right: 20px"
						@click="rosterDialog = true"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-fab-transition>
			</v-card>
		</v-dialog>

		<!------------------------------------
        NEW ROSTER DIALOG
    ------------------------------------>
		<v-dialog v-model="rosterDialog" persistent max-width="600px">
			<v-card v-if="selectedRoster">
				<v-card-title>
					<span class="text-h5">{{ !selectedRoster.id ? `New Roster` : `Edit Roster` }}</span>

					<v-spacer></v-spacer>
					<v-tooltip bottom
						><template v-slot:activator="{ attrs, on }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								size="30"
								color="error"
								class="mr-3"
								@click="deleteRoster()"
								>mdi-delete</v-icon
							> </template
						><span>Delete roster</span></v-tooltip
					>

					<v-tooltip bottom
						><template v-slot:activator="{ attrs, on }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								size="30"
								class="mr-3"
								color="primary"
								@click="saveRoster()"
								>mdi-content-save</v-icon
							> </template
						><span>Save roster</span></v-tooltip
					>

					<v-tooltip bottom
						><template v-slot:activator="{ attrs, on }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								size="30"
								color="primary"
								@click="
									rosterDialog = false;
									resetSelectedRoster();
								"
								>mdi-close</v-icon
							> </template
						><span>Exit</span></v-tooltip
					>
				</v-card-title>

				<!------------------------------------
                  fields starts here
          ------------------------------------>
				<v-card-text>
					<v-container v-if="selectedRoster">
						<v-form ref="form" lazy-validation>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<v-autocomplete
										v-model="selectedRoster.guard"
										:item-text="
											item => {
												return item.firstName + ' ' + item.lastName;
											}
										"
										:items="enabledGuards"
										label="Select Guard"
										return-object
										single-line
										outlined
										:rules="[val => !!val['_id'] || `Required`]"
									></v-autocomplete>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<v-autocomplete
										v-model="selectedRoster.siteLocation"
										item-text="siteName"
										:items="enabledSites"
										return-object
										single-line
										outlined
										label="Select Site"
										:rules="[val => !!val['_id'] || `Required`]"
										:hint="selectedRoster.siteLocation.siteAddress"
										:persistent-hint="true"
									>
										<template v-slot:item="{ item }">
											<v-list-item-content>
												<v-list-item-title v-text="item.siteName"></v-list-item-title>
												<v-list-item-subtitle v-text="item.siteAddress"></v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<v-icon>mdi-map-marker-radius</v-icon>
											</v-list-item-action>
										</template>
									</v-autocomplete>
								</v-col>

								<v-col cols="12" sm="6">
									<h5>Date</h5>

									<v-dialog
										ref="dateDialogRef"
										v-model="dateDialog"
										:return-value.sync="selectedRoster.date"
										persistent
										width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												:value="convertDate(selectedRoster.date)"
												outlined
												readonly
												v-bind="attrs"
												v-on="on"
												append-icon="mdi-calendar"
												:rules="[val => !!val || `Required`]"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="selectedRoster.date"
											scrollable
											:allowed-dates="allowedDates"
										>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="dateDialog = false"> Cancel </v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.dateDialogRef.save(selectedRoster.date)"
											>
												OK
											</v-btn>
										</v-date-picker>
									</v-dialog>
								</v-col>
								<v-col cols="12" sm="6">
									<h5>Start Time</h5>
									<v-dialog
										ref="startTimeRef"
										v-model="startTimeDialog"
										:return-value.sync="selectedRoster.startTime"
										persistent
										width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
											<h1>
												<v-text-field
													v-model="selectedRoster.startTime"
													append-icon="mdi-clock-time-nine-outline"
													readonly
													v-bind="attrs"
													v-on="on"
													outlined
													:rules="[val => !!val || `Required`]"
												></v-text-field>
											</h1>
										</template>
										<v-time-picker
											v-if="startTimeDialog"
											format="24hr"
											v-model="selectedRoster.startTime"
											full-width
										>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="startTimeDialog = false"> Cancel </v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.startTimeRef.save(selectedRoster.startTime)"
											>
												OK
											</v-btn>
										</v-time-picker>
									</v-dialog>
									<h5>End Time</h5>
									<v-dialog
										ref="endTimeRef"
										v-model="endTimeDialog"
										:return-value.sync="selectedRoster.endTime"
										persistent
										width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
											<h1>
												<v-text-field
													v-model="selectedRoster.endTime"
													append-icon="mdi-clock-time-five-outline"
													readonly
													v-bind="attrs"
													v-on="on"
													outlined
													:rules="[val => !!val || `Required`]"
												></v-text-field>
											</h1>
										</template>
										<v-time-picker
											v-if="endTimeDialog"
											format="24hr"
											v-model="selectedRoster.endTime"
											full-width
										>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="endTimeDialog = false"> Cancel </v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.endTimeRef.save(selectedRoster.endTime)"
											>
												OK
											</v-btn>
										</v-time-picker>
									</v-dialog>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-row>
</template>

<script>
	import { enabledGuards } from '../graphql/guard';
	import { enabledSites } from '../graphql/sites';
	import { rosterList, addRoster, updateRoster, deleteRoster } from '../graphql/roster';
	import moment from 'moment';

	export default {
		data: () => ({
			weekdays: [1, 2, 3, 4, 5, 6, 0],
			//parent vue page
			loading: false,
			focus: '',
			selectedDate: null,
			events: [],
			rosterList: [],

			//first day calendar dialog
			dialog: false,
			notifications: false,
			sound: true,
			widgets: false,

			//second roster dialog
			rosterDialog: false,
			selectedRoster: null,
			enabledSites: [],
			enabledGuards: [],

			startTime: null,
			endTime: null,
			startTimeDialog: false,
			endTimeDialog: false,
			dateDialog: false,

			//notification
			notify: false,
			notificationText: '',
			color: '',
		}),
		mounted() {
			// this.$refs.calendar.checkChange();
			this.resetSelectedRoster();
		},

		apollo: {
			rosterList: rosterList,
			enabledGuards: enabledGuards,
			enabledSites: enabledSites,
		},
		watch: {
			rosterList: function (val) {
				let list = [];
				val.forEach(item => {
					let newEvent = {
						name: item.guard ? item.guard.firstName + ' ' + item.guard.lastName : 'Unknown Guard',
						start: item.date + ' ' + item.startTime,
						end: item.date + ' ' + item.endTime,
						id: item.id ? item.id : item['_id'],
						color: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
					};
					list.push(newEvent);
				});
				this.events = list;
			},
			'selectedRoster.guard': function (val) {
				if (val) {
					this.selectedRoster.guardId = val['_id'] ? val['_id'] : val.id;
				}
			},
			'selectedRoster.siteLocation': function (val) {
				if (val) {
					this.selectedRoster.siteLocationId = val['_id'] ? val['_id'] : val.id;
				}
			},
		},
		computed: {
			convertDateTitle: {
				get() {
					return moment(this.selectedDate).format('MMMM DD - dddd');
				},
			},
		},
		methods: {
			formatEventTime(date) {
				return new Date(date).toLocaleTimeString('en-US', {
					hour: '2-digit',
					minute: '2-digit',
					hour12: false,
				});
			},
			intervalFormat(locale) {
				console.log(locale.time);
				return locale.time;
			},
			allowedDates: val => {
				if (val >= moment(Date.now()).format('YYYY-MM-DD')) {
					return val;
				}
			},
			setSelectedRoster({ nativeEvent, event }) {
				const rosterId = event.id;

				let selected = this.rosterList.filter(roster => roster.id == event.id);
				selected[0].id = selected[0]['_id'];
				this.selectedRoster = selected[0];
				this.rosterDialog = true;
			},
			convertDate(d) {
				return d && d.length > 0 ? moment(d).format('DD/MM/yyyy') : '';
			},
			resetSelectedRoster() {
				this.selectedRoster = {
					guardId: null,
					id: null,
					siteLocationId: null,
					siteLocation: {},
					guard: {},
					startTime: '00:00',
					endTime: '00:00',
					date: this.selectedDate,
				};
			},
			async deleteRoster() {
				if (confirm('Are you sure to delete this roster?')) {
					this.rosterDialog = false;
					await this.$apollo.mutate({
						mutation: deleteRoster,
						variables: {
							id: this.selectedRoster.id,
						},
						update: (store, { data: { deleteRoster } }) => {
							let index = this.rosterList.findIndex(roster => roster.id == deleteRoster['_id']);
							this.rosterList.splice(index, 1);
							this.rosterDialog = false;
							this.resetSelectedRoster();
						},
					});
				}
			},

			async saveRoster() {
				let valid = this.$refs.form.validate();
				if (!this.selectedRoster.id && valid) {
					await this.$apollo.mutate({
						mutation: addRoster,
						variables: this.selectedRoster,
						update: (store, { data: { addRoster } }) => {
							addRoster.id = addRoster['_id'];
							this.rosterList.push(addRoster);
							this.rosterDialog = false;
							this.resetSelectedRoster();
							setTimeout(() => {
								(this.notificationText = 'Roster Added'),
									(this.color = 'green darken-1'),
									(this.notify = true);
							}, 250);
						},
					});
				} else {
					await this.$apollo.mutate({
						mutation: updateRoster,
						variables: this.selectedRoster,
						update: (store, { data: { updateRoster } }) => {
							updateRoster.id = updateRoster['_id'];

							let index = this.rosterList.findIndex(roster => roster.id == updateRoster.id);
							this.rosterList.splice(index, 1);
							this.rosterList.push(updateRoster);
							this.rosterDialog = false;
							this.resetSelectedRoster();
							setTimeout(() => {
								(this.notificationText = 'Roster Updated'),
									(this.color = 'green darken-1'),
									(this.notify = true);
							}, 250);
						},
					});
				}
			},

			async openDialog() {
				this.loading = true;
				setTimeout(() => {
					this.dateValue = this.$refs.calendar.value;
					if (this.dateValue < moment(Date.now()).format('YYYY-MM-DD')) {
						return false;
					} else {
						this.dialog = true;
						setTimeout(() => {
							this.selectedDate = this.$refs.calendar.value;
							this.selectedRoster.date = this.selectedDate;
							this.loading = false;
						}, 1);
					}
				}, 1);
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width <= 800) {
					return true;
				}
			},
			prev() {
				this.$refs.calendar.prev();
			},
			// prevDay () {
			//   setTimeout(() => {
			//     this.selectedDate = this.$refs.calendar.value;
			//   }, 1);
			// },
			next() {
				this.$refs.calendar.next();
			},
			// nextDay () {
			//   setTimeout(() => {
			//     this.selectedDate = this.$refs.calendar.value;
			//   }, 1);
			// },
		},
	};
</script>
