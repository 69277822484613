<template>
  <v-col
    v-if="isAdmin"
    style="height: 100%"
  >
    <div
      :style="isMobile() ? `width: 100%` : `width: 70vw`"
      class="container"
    >
      <div :class="isMobile() ? `text-center` : `d-flex`">
        <v-icon
          :size="isMobile() ? `40` : `50`"
          color="secondary"
        >mdi-cctv</v-icon>
        <h1
          v-if="!isMobile()"
          class="ma-4"
        >Monitoring Company</h1>
        <h3
          v-else
          class="ma-4"
        >Monitoring Company</h3>
      </div>
      <v-fade-transition>
        <v-row>
          <v-col
            :cols="isMobile() ? `` : `6`"
            class="mt-4"
          >
            <!-- @keyup="initSearch()" -->
            <v-text-field
              v-model="search"
              outlined
              dense
              label="Search Company"
              @input="
                loading = true;
                searchList = [];
              "
            >
            </v-text-field>
          </v-col>

          <!-- dropdown filter -->
          <v-col
            cols="3"
            class="text-center mt-4"
            v-if="!isMobile()"
          >
            <v-select
              v-model="companyStatus"
              :items="filterCompany"
              color="#FF9900"
              width="100%"
              height="40px"
              outlined
              dense
            >
            </v-select>
          </v-col>

          <!-- add company button -->
          <v-col
            cols="3"
            class="text-center mt-4"
            v-if="!isMobile()"
          >
            <v-btn
              color="#FF9900"
              width="100%"
              height="40px"
              @click="initAddCompany()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Company
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>

      <v-dialog
        v-model="companyDialog"
        max-width="800"
        persistent
      >
        <v-card min-height="65vh">
          <div
            class="pa-6"
            v-if="selectedCompany"
          >
            <v-row>
              <v-col
                cols="12"
                :class="
                  $vuetify.breakpoint.width < 600 ? `text-center` : `text-right`
                "
              >
                <!-- <v-tooltip bottom v-if="!isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="deleteCompanyConfirmation()"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete-alert</v-icon
                    >
                  </template>
                  <span>Remove Company</span>
                </v-tooltip> -->

                <!-- enable / disable company -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="
                        selectedCompany.isEnabled = !selectedCompany.isEnabled
                      "
                      :color="
                        selectedCompany.isEnabled ? `green darken-1` : `red`
                      "
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >mdi-cctv</v-icon>
                  </template>
                  <span>
                    {{
                      selectedCompany.isEnabled
                        ? `Disable Company`
                        : `Enable Company`
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        () => {
                          isAdd ? addCompany() : updateCompany();
                        }
                      "
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      :loading="loading"
                      icon
                    >
                      <v-icon size="32">mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ isAdd ? `Add Company` : `Update Company` }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="companyDialog = false"
                      large
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >mdi-close</v-icon>
                  </template>
                  <span>Exit</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                :class="$vuetify.breakpoint.width < 600 ? `text-center` : ``"
              >
                <h3>
                  {{ isAdd ? `Add Company` : `Update Company` }}
                </h3>
              </v-col>
            </v-row>

            <v-form
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <p
                    class="ma-0 mb-2"
                    style="font-size: 14px"
                  >
                    Company Details
                  </p>
                  <v-text-field
                    v-model="selectedCompany.companyName"
                    :rules="[rules.required]"
                    outlined
                    label="Company Name"
                  />
                  <p style="font-size: 14px">Company Address</p>
                  <v-text-field
                    v-model="selectedCompany.address"
                    :rules="[rules.required]"
                    outlined
                    label="Company Address"
                  />

                  <v-dialog
                    v-model="deleteCompanyDialog"
                    persistent
                    max-width="320"
                  >
                    <v-card color="red">
                      <v-card-title class="text-h5">
                        <v-icon
                          class="mr-2"
                          color="yellow"
                          large
                        >
                          mdi-alert
                        </v-icon>
                        Delete Confirmation
                      </v-card-title>
                      <v-card-text class="black--text mt-2"><b>
                          Are you sure you want to delete
                          {{ selectedCompany.companyName }} ?
                        </b>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="black "
                          text
                          @click="deleteCompanyDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          class="white--text"
                          color="yellow darken-1"
                          text
                          :loading="loading"
                          @click="deleteCompany()"
                        >
                          Confirm
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-dialog>

      <div
        :style="
          isMobile()
            ? `
          max-height: 61vh;
          overflow-y: scroll;
          border-top-style: solid;
          border-bottom-style: solid;
          border-color: gray;
          border-width: 1px;
          `
            : ``
        "
        class="hide-scrollbar pa-1"
      >
        <v-data-table
          :headers="headers"
          :items="companyList"
          :search="search"
          :items-per-page="15"
          :footer-props="{
    'items-per-page-options': [15, 25, 50, -1]
  }"
          class="elevation-1 mt-1 mb-5"
          item-key="name"
          mobile-breakpoint="0"
          loading-text="Loading... Please wait"
        >
          <template #[`item.edit`]="{ item, index }">
            <td>
              <v-icon
                @click="editCompany(item, index)"
                class="ml-1"
                small
              >mdi-eye</v-icon>
            </td>
          </template>
        </v-data-table>
      </div>

      <v-fab-transition>
        <v-btn
          v-show="isMobile()"
          color="secondary"
          dark
          fixed
          bottom
          right
          fab
          class="mb-12"
          @click="initAddCompany()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>
  </v-col>
</template>

<script>
import {
  monitoringCompanyList,
  addMonitoringCompany,
  updateMonitoringCompany,
  deleteMonitoringCompany,
  enabledMonitoringCompanyList,
  disabledMonitoringCompanyList,
} from "../graphql/monitoringCompany";
export default {
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    showEnabledCompanies() {
      this.$apollo.addSmartQuery("companyList", enabledMonitoringCompanyList);
    },

    showDisabledCompanies() {
      this.$apollo.addSmartQuery("companyList", disabledMonitoringCompanyList);
    },

    initAddCompany() {
      this.isAdd = true;
      this.selectedCompany = {
        companyName: null,
        address: null,
        id: null,
        isEnabled: true,
      };
      this.companyDialog = true;
    },

    async deleteCompany() {
      alert("Cannot delete monitoring company this time..");
      // this.loading = true;
      // let res = await this.$apollo.mutate({
      //   mutation: deleteMonitoringCompany,
      //   variables: {
      //     id: this.selectedCompany.id,
      //   },
      //   update: (store, { data: { deleteMonitoringCompany } }) => {
      //     this.companyList = this.companyList.filter(
      //       (obj) => obj.id != deleteMonitoringCompany["_id"]
      //     );
      //     this.companyList = this.companyList.filter(
      //       (obj) => obj["_id"] != deleteMonitoringCompany["_id"]
      //     );
      //   },
      // });

      // this.deleteCompanyDialog = false;
      // this.companyDialog = false;
      // (this.loading = false),
      //   (this.color = "red "),
      //   (this.notificationText = "Monitoring Company Deleted"),
      //   (this.notify = true);
    },

    async addCompany() {
      this.loading = true;
      let valid = this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }
      if (this.isAdd) {
        await this.$apollo.mutate({
          mutation: addMonitoringCompany,
          variables: {
            ...this.selectedCompany,
          },
          update: (store, { data: { addMonitoringCompany } }) => {
            let newCompany = {
              ...this.selectedCompany,
              id: addMonitoringCompany["_id"],
            };
            this.companyList.push(newCompany);
          },
        });

        this.companyDialog = false;

        this.loading = false;
        this.color = "green darken-1";
        this.notificationText = "Monitoring Company Added";
        this.notify = true;
      } else {
        this.updateCompany();
      }
    },

    async updateCompany() {
      this.loading = true;
      let valid = this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }
      if (valid) {
        await this.$apollo.mutate({
          mutation: updateMonitoringCompany,
          variables: this.selectedCompany,
        });

        if (
          (this.companyStatus == "Active" &&
            this.selectedCompany.isEnabled == false) ||
          (this.companyStatus == "Inactive" &&
            this.selectedCompany.isEnabled == true)
        ) {
          this.companyList.splice(this.selectedCompanyIndex, 1);
          this.selectedCompanyIndex = null;
        }

        this.companyDialog = false;
        this.loading = false;
        this.color = "green darken-1";
        this.notificationText = "Monitoring Company Updated";
        this.notify = true;
      }
    },

    editCompany(company, i) {
      this.selectedCompanyIndex = i;
      this.isAdd = false;
      this.selectedCompany = company;
      this.companyDialog = true;
    },
    deleteCompanyConfirmation() {
      this.deleteCompanyDialog = true;
    },
  },

  // apollo: {
  //   companyList: monitoringCompanyList,
  // },
  async mounted() {
    this.showEnabledCompanies();
    this.selectedCompany = null;
  },

  computed: {
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
  },

  watch: {
    companyDialog: function (val) {
      if (!val) {
        this.selectedCompany = null;
      }
    },
    companyStatus: function () {
      if (this.companyStatus === "Active") {
        this.showEnabledCompanies();
      } else if (this.companyStatus === "Inactive") {
        this.showDisabledCompanies();
      } else return this.showEnabledCompanies();
    },
  },

  data() {
    return {
      selectedCompanyIndex: null,
      filterCompany: ["Active", "Inactive"],
      companyStatus: "Active",
      hints: false,
      headers: [
        {
          text: "Company Name",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        { text: "Company Address", sortable: false, value: "address" },
        { text: "Edit", sortable: false, value: "edit" },
      ],
      expanded: [],
      notify: false,
      notificationText: "",
      color: "",
      search: "",
      companyDialog: false,
      confirmationDialog: false,
      isAdd: false,
      loading: false,
      companyList: [],
      selectedCompany: null,
      deleteCompanyDialog: false,
      rules: {
        required: (v) => !!v || "This is Required!",
      },
    };
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
