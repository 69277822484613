import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://apigw.guardex.co.nz/graphql';

// Config
const defaultOptions = {
	httpEndpoint,
	wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'wss://apigw.guardex.co.nz/graphql',
	tokenName: AUTH_TOKEN,
	persisting: false,
	websocketsOnly: false,
	// Is being rendered on the server?
	ssr: false,
	// link: myLink
	// cache: myCache
	// Override the way the Authorization header is set
	// getAuth: tokenName => {
	// 	const token = 'Bearer ' + localStorage.getItem(AUTH_TOKEN);
	// 	return token || '';
	// },
	// Additional ApolloClient options
	// apollo: { ... }
	// Client local data (see apollo-link-state)
	// clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
	// Create apollo client
	const { apolloClient, wsClient } = createApolloClient({
		...defaultOptions,
		//...options,
	});

	wsClient.connectionParams = async () => {
		let token = localStorage.getItem('apollo-token');
		return {
			authorization: `Bearer ${token}`,
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
	};

	apolloClient.wsClient = wsClient;

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		defaultOptions: {
			$query: {
				fetchPolicy: 'no-cache',
			},
		},
		errorHandler(error) {
			// eslint-disable-next-line no-console
			console.log(
				'%cError',
				'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
				error.message
			);
		},
	});

	return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
	if (typeof localStorage !== 'undefined' && token) {
		localStorage.setItem(AUTH_TOKEN, token);
	}
	if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
	try {
		await apolloClient.resetStore();
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log('%cError on cache reset (login)', 'color: orange;', e.message);
	}
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
	if (typeof localStorage !== 'undefined') {
		localStorage.removeItem(AUTH_TOKEN);
	}
	if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
	try {
		await apolloClient.resetStore();
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
	}
	{
		console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
	}
}
