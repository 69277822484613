<template>
	<v-bottom-navigation :background-color="!sosBtn ? `#EA690B` : `#f06262`" app dark>
		<!-- nav icons -->
		<v-slide-group center-active>
			<!-- :style="`background-color: #f06262` " -->
			<!-- :style="`${sosBtn ? `background-color: #f06262` : `background-color: #EA690B`}`" -->
			<div v-show="!sosBtn">
				<!-- <v-btn @click="drawer = !drawer" class="ml-6" icon>
        <span>Menu</span>

        <v-icon>mdi-menu</v-icon>
      </v-btn> -->
				<v-btn
					v-if="isAdmin"
					class="mr-6 ml-8"
					@click="
						() => {
							this.$router.push('./Dashboard');
						}
					"
					:disabled="checkRoute('Dashboard')"
					icon
				>
					<span>Dashboard</span>

					<v-icon>mdi-earth</v-icon>
				</v-btn>
				<v-btn
					v-if="!isAdmin"
					class="mr-6 ml-8"
					@click="
						() => {
							this.$router.push('./GuardDashboard');
						}
					"
					:disabled="checkRoute('GuardDashboard')"
					icon
				>
					<span>Dashboard</span>

					<v-icon>mdi-earth</v-icon>
				</v-btn>

				<v-btn
					v-if="isAdmin"
					class="mr-6"
					:disabled="checkRoute('Guards')"
					@click="
						() => {
							this.$router.push('./Guards');
						}
					"
					icon
				>
					<span>Guards</span>

					<v-icon>mdi-shield-account-variant</v-icon>
				</v-btn>

				<v-btn
					v-if="isAdmin"
					class="mr-6"
					:disabled="checkRoute('Organizations')"
					@click="
						() => {
							this.$router.push('./Organizations');
						}
					"
					icon
				>
					<span>Organizations</span>

					<v-icon>mdi-home-account</v-icon>
				</v-btn>

				<v-btn
					class="mr-6"
					:disabled="checkRoute('Reports')"
					@click="
						() => {
							this.$router.push('./Reports');
						}
					"
					icon
				>
					<span>Reports</span>

					<v-icon>mdi-alert</v-icon>
				</v-btn>
			</div>
			<!-- 				:style="!sosBtn ? `` : `display:none;`"
				v-touch="{
					start: () => showSos(),
					end: () => end('End'),
				}" -->
			<div>
				<v-btn
					v-if="isAdmin"
					class="mr-6"
					:disabled="checkRoute('Vehicles')"
					@click="
						() => {
							this.$router.push('./Vehicles');
						}
					"
					icon
				>
					<span>Vehicles</span>

					<v-icon>mdi-car</v-icon>
				</v-btn>

				<v-btn
					v-if="!isAdmin"
					class="mr-6"
					:disabled="checkRoute('GuardCalendar')"
					@click="
						() => {
							this.$router.push('GuardCalendar');
						}
					"
					icon
				>
					<span>Calendar</span>

					<v-icon>mdi-calendar-month</v-icon>
				</v-btn>

				<v-btn
					v-if="isAdmin"
					class="mr-6"
					:disabled="checkRoute('Calendar')"
					@click="
						() => {
							this.$router.push('Calendar');
						}
					"
					icon
				>
					<span>Calendar</span>

					<v-icon>mdi-calendar-month</v-icon>
				</v-btn>

				<!-- <v-btn
          v-if="isAdmin"
          class="mr-6"
          :disabled="checkRoute('LocationHistory')"
          @click="  () => {
            this.$router.push('./LocationHistory');
          }"
          icon
        >
          <span> Location History </span>
          <v-icon> mdi-history </v-icon>
        </v-btn> -->

				<v-btn
					class="mr-6"
					:disabled="checkRoute('Settings')"
					icon
					@click="
						() => {
							this.$router.push('./Settings');
						}
					"
				>
					<span>Settings</span>

					<v-icon>mdi-cog</v-icon>
				</v-btn>
			</div>

			<!-- nav icons -->
			<!-- <div v-if="isMobile()" style="width: 65px">
				<v-btn @click="hideSos" v-if="sosBtn" class="pt-10 pr-4" icon large>
					<v-icon> mdi-close </v-icon>
				</v-btn>
				<v-icon
					v-else
					class="pt-4 pl-5"
					v-touch="{
						start: () => showSos(),
						end: () => end('End'),
					}"
				>
					mdi-arrow-left
				</v-icon>
			</div> -->
		</v-slide-group>

		<!-- SOS Button -->
		<v-scroll-x-transition>
			<!--@mousedown="calculateHold()"
              @mouseleave="cancelHold()"
              @mouseup="cancelHold()" -->

			<!-- @mousedown="mouseDown"
            @mouseup="mouseUp" -->
			<v-card
				class="disable-select"
				style="padding-right: 80px"
				v-show="sosBtn"
				:color="sosBtnColor"
				height="125"
				width="600"
				v-touch="{
					start: () => start('Start'),
					end: () => end('End'),
				}"
			>
				<v-card-title class="justify-center">
					<h1 class="ml-14 mt-2">S0S</h1>
				</v-card-title>
				<v-card-title class="justify-center">
					<!-- v-show="counter < 5" -->
					<!-- <p
            class="ml-14"
            v-if="counter <= 4"
          > Activating in: {{ counter }} </p> -->
					<p v-show="counter > 4" style="font-size: 12px" class="ml-14">
						Press and Hold to Activate
					</p>
				</v-card-title>
			</v-card>
		</v-scroll-x-transition>

		<!-- drawer -->
		<v-dialog v-model="sosCounter" class="ma-2" max-height="300" max-width="270">
			<v-card color="#ff0800">
				<v-card-title class="justify-center">
					<h3 class="white--text">
						SOS Activating in:
						{{ counter }}
					</h3>
				</v-card-title>
			</v-card>
		</v-dialog>

		<v-btn
			v-if="!isAdmin && isMobile() && isClockedIn && $route.path == '/GuardDashboard'"
			elevation="10"
			fab
			width="100px"
			height="100px"
			style="position: fixed; top: 15vh; border-radius: 100%"
			color="red"
			v-touch="{
				start: () => start('Start'),
				end: () => end('End'),
			}"
		>
			<h2>SOS</h2>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { addSOS } from '../graphql/sos';

	export default {
		methods: {
			start() {
				clearInterval(this.time);
				// this.counter = 5
				this.sosBtnColor = '#ff0800';
				this.time = setInterval(() => {
					this.counter -= 1;
				}, 1000);
			},
			end() {
				this.sosCounter = false;
				this.sosBtnColor = 'red';
				clearInterval(this.time);
				this.counter = 5;
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			cancelHold() {
				clearTimeout(this.timeoutId);
			},
			calculateHold() {
				this.timeoutId = setTimeout(this.activateSOS, this.holdTime);
			},
			async activateSOS() {
				try {
					let id = this.userInfo['http://guardex.com/custom']['app_metadata'].guard_id;
					let obj = {
						guardId: id,
						geolocation: {
							lat: this.myGeolocation[0].location.lat,
							lng: this.myGeolocation[0].location.lng,
							timestamp: Date.now().toString(),
						},
					};
					await this.$apollo.mutate({
						mutation: addSOS,
						variables: obj,
					});
				} catch (e) {
					console.log('error updating location');
					console.log(e);
				}
			},
			showSos() {
				if ((this.sosBtn == false && this.isClockedIn) || this.isAdmin) {
					this.time = setInterval(() => {
						this.counter += 1;
					}, 320);
				}
			},
			// showSos (dir) {
			//   if (dir == 'Left' && this.sosBtn == false && this.isClockedIn || this.isAdmin) {
			//     this.time = setInterval(() => {
			//       this.counter += 1
			//     }, 50);
			//   }
			// },

			hideSos() {
				this.sosBtn = false;
			},
			checkRoute(name) {
				if (this.$route.name == name) {
					return true;
				} else {
					return false;
				}
			},
		},

		watch: {
			counter: async function (val) {
				if (val == 0) {
					clearInterval(this.time);
					this.counter = 5;
					this.sosBtn = false;
					await this.activateSOS();
				}
				if (val == 6) {
					clearInterval(this.time);
					this.counter = 5;
					this.sosBtn = true;
				}
				if (this.counter <= 4) {
					this.sosCounter = true;
				}
			},
		},

		components: {
			//
		},

		computed: {
			...mapGetters(['isAdmin', 'userInfo']),
			myGeolocation: {
				get() {
					return this.$store.getters['markers'];
				},
			},
			isClockedIn: {
				get() {
					return this.$store.getters['isClockedIn'];
				},
			},
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
		},

		data: () => ({
			holdTime: 2000,
			sosBtn: false,
			drawer: false,
			counter: 5,
			time: null,
			sosBtnColor: 'red',
			sosCounter: false,
		}),
	};
</script>

<style scoped>
	/* .safe {
  height: auto !important;
  position: fixed;
  bottom: 80px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-top: env(safe-area-inset-bottom) !important;
} */

	.v-bottom-navigation {
		height: auto !important;
	}

	.v-bottom-navigation >>> .v-btn__content {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: constant(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.0 */
		margin-bottom: env(safe-area-inset-bottom); /* move footer up on iPhone X - iOS 11.2 */
	}

	.disable-select {
		user-select: none; /* supported by Chrome and Opera */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
	}
</style>
