<template>
	<v-container v-if="isAdmin">
		<v-tabs v-model="tab" icons-and-text centered grow>
			<v-tabs-slider></v-tabs-slider>
			<v-tab @click="selectedTab(0)"> Clients <v-icon> mdi-account-multiple </v-icon> </v-tab>
			<v-divider vertical inset> </v-divider>
			<v-tab @click="selectedTab(1)">Sites<v-icon> mdi-office-building-marker </v-icon> </v-tab>
			<v-divider vertical inset> </v-divider>
			<v-tab @click="selectedTab(2)">Monitoring Company<v-icon> mdi-cctv </v-icon> </v-tab>
		</v-tabs>
		<v-card>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<Clients />
				</v-tab-item>
				<v-tab-item>
					<Sites />
				</v-tab-item>
				<v-tab-item>
					<MonitoringCompany />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>

<script>
	import Clients from '../views/Clients.vue';
	import Sites from '../views/Sites.vue';
	import MonitoringCompany from '../views/MonitoringCompany.vue';
	export default {
		methods: {
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},
			selectedTab(tab) {
				localStorage.setItem('organizationsTab', tab);
			},
		},

		computed: {
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
		},

		components: {
			Clients,
			Sites,
			MonitoringCompany,
		},

		async mounted() {
			this.tab = parseInt(localStorage.getItem('organizationsTab'));
		},

		watch: {},

		data: () => ({
			tab: null,
		}),
	};
</script>
