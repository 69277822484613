import gql from 'graphql-tag';

// export const listSettings = {
// 	query: gql`
// 		{
// 			listSettings {
// 				_id
// 				guardId
// 				coaExpiryNotificationTime
// 				regoExpiryNotificationTime
// 				wofExpiryNotificationTime
// 				vehicleServiceNotificationTime
// 				emailSendTime
// 				emailHeader
// 				emailSubject
// 				emailBody
// 				emailFooter
// 			}
// 		}
// 	`,
// 	update: data => {
// 		data.listSettings.forEach(settings => {
// 			if (settings['_id']) {
// 				settings.id = settings['_id'];
// 			}
// 		});
// 		return data.listSettings;
// 	},
// };

export const getSettingsById = gql`
	query getSettingsById($guardId: String!) {
		selectedAlertTime: getSettingsById(guardId: $guardId) {
			_id
			guardId
			coaExpiryNotificationTime
			regoExpiryNotificationTime
			wofExpiryNotificationTime
			vehicleServiceNotificationTime
			incidentEmailTemplate {
				# emailSendTime
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			alarmEmailTemplate {
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			dailyEmailTime {
				hour
				minutes
			}
			weeklyEmailTime {
				day
				hour
				minutes
			}
			devices
		}
	}
`;

export const addSettings = gql`
	mutation (
		$guardId: String
		$coaExpiryNotificationTime: String
		$regoExpiryNotificationTime: String
		$wofExpiryNotificationTime: String
		$vehicleServiceNotificationTime: String
		$incidentEmailTemplate: IncidentSettingsInput
		$alarmEmailTemplate: AlarmSettingsInput
		$dailyEmailTime: DailyEmailTimeInput
		$weeklyEmailTime: WeeklyEmailTimeInput
		$devices: [String]
	) {
		addSettings(
			guardId: $guardId
			coaExpiryNotificationTime: $coaExpiryNotificationTime
			regoExpiryNotificationTime: $regoExpiryNotificationTime
			wofExpiryNotificationTime: $wofExpiryNotificationTime
			vehicleServiceNotificationTime: $vehicleServiceNotificationTime
			incidentEmailTemplate: $incidentEmailTemplate
			alarmEmailTemplate: $alarmEmailTemplate
			dailyEmailTime: $dailyEmailTime
			weeklyEmailTime: $weeklyEmailTime
			devices: $devices
		) {
			_id
			guardId
			coaExpiryNotificationTime
			regoExpiryNotificationTime
			wofExpiryNotificationTime
			vehicleServiceNotificationTime
			incidentEmailTemplate {
				# emailSendTime
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			alarmEmailTemplate {
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			dailyEmailTime {
				hour
				minutes
			}
			weeklyEmailTime {
				day
				hour
				minutes
			}
			devices
		}
	}
`;

export const updateSettings = gql`
	mutation (
		$id: ID!
		$guardId: String
		$coaExpiryNotificationTime: String
		$regoExpiryNotificationTime: String
		$wofExpiryNotificationTime: String
		$vehicleServiceNotificationTime: String
		$incidentEmailTemplate: IncidentSettingsInput
		$alarmEmailTemplate: AlarmSettingsInput
		$dailyEmailTime: DailyEmailTimeInput
		$weeklyEmailTime: WeeklyEmailTimeInput
		$devices: [String]
	) {
		updateSettings(
			id: $id
			guardId: $guardId
			coaExpiryNotificationTime: $coaExpiryNotificationTime
			regoExpiryNotificationTime: $regoExpiryNotificationTime
			wofExpiryNotificationTime: $wofExpiryNotificationTime
			vehicleServiceNotificationTime: $vehicleServiceNotificationTime
			incidentEmailTemplate: $incidentEmailTemplate
			alarmEmailTemplate: $alarmEmailTemplate
			dailyEmailTime: $dailyEmailTime
			weeklyEmailTime: $weeklyEmailTime
			devices: $devices
		) {
			_id
			guardId
			coaExpiryNotificationTime
			regoExpiryNotificationTime
			wofExpiryNotificationTime
			vehicleServiceNotificationTime
			incidentEmailTemplate {
				# emailSendTime
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			alarmEmailTemplate {
				emailHeader
				emailSubject
				emailBody
				emailFooter
			}
			dailyEmailTime {
				hour
				minutes
			}
			weeklyEmailTime {
				day
				hour
				minutes
			}
			devices
		}
	}
`;
