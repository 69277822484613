<template>
  <v-container>
    <v-col cols="12">
      <v-tabs
        v-model="tab"
        icons-and-text
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab> Roster <v-icon> mdi-calendar-account</v-icon>
        </v-tab>
        <!-- <v-divider vertical inset> </v-divider>
        <v-tab>Timesheet<v-icon> mdi-clipboard-text-clock</v-icon> </v-tab> -->
      </v-tabs>
      <v-card>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div>
              <v-card
                v-for="(item, i) in roster"
                :key="i"
                elevation="10"
                class="ma-2"
                color="secondary"
                dark
              >
                <v-card-title> {{ item.siteLocation.siteName }}</v-card-title>
                <v-card-subtitle>{{ item.siteLocation.siteAddress }} <br />{{
                    convertDate(item.date)
                  }}</v-card-subtitle>
                <v-card-subtitle>Start: {{ item.startTime }} <br />End:
                  {{ item.endTime }}</v-card-subtitle>
              </v-card>
            </div>
          </v-tab-item>
          <!-- <v-tab-item></v-tab-item> -->
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
import { rosterListByGuard } from "../graphql/roster";
import gql from "graphql-tag";

export default {
  name: "Calendar",

  apollo: {
    roster: {
      query: gql`
        query rosterListByGuard($guardId: String) {
          roster: rosterListByGuard(guardId: $guardId) {
            _id
            guard {
              _id
              firstName
              lastName
            }
            siteLocation {
              _id
              siteName
              siteAddress
            }
            date
            startTime
            endTime
          }
        }
      `,
      variables() {
        return {
          guardId:
            this.$store.getters["userInfo"]["http://guardex.com/custom"]
              .app_metadata.guard_id,
        };
      },
      update: (data) => {
        let sortedRoster = data.roster.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        return sortedRoster;
      },
    },
  },

  computed: {
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
  },

  methods: {
    colorRandomizer() {
      const hexColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

      return hexColor;
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },

  data: () => ({
    timesheet: [],
    roster: [],
    tab: null,
  }),
};
</script>
