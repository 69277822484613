export default {
	namespaced: true,
	state: {
		selectedAlarm: null,
	},

	mutations: {
		setSelectedAlarm(state, payload) {
			if (payload['_id']) {
				payload.id = payload['_id'];
				delete payload['_id'];
				for (var i = 0; i < payload.policeInvolved.length; i++) {
					delete payload.policeInvolved[i]['__typename'];
				}
				for (var i = 0; i < payload.peopleInjured.length; i++) {
					delete payload.peopleInjured[i]['__typename'];
				}
				for (var i = 0; i < payload.damagedProperties.length; i++) {
					delete payload.damagedProperties[i]['__typename'];
				}
				// for (var i = 0; i < payload.monitoringCompany.length; i++) {
				//   delete payload.monitoringCompany[i]["__typename"];
				// }
			}
			state.selectedAlarm = payload;
		},

		resetSelectedAlarm(state) {
			state.selectedAlarm = {
				id: null,
				dateIssued: '',
				alarmNotificationTime: '',
				siteLocationId: '',
				currentGeoLocation: '',
				siteArrivalTime: '',
				guardOnDuty: '',
				alarmIncidentDescription: '',
				policeRequired: false,
				policeInvolved: [],
				anyoneInjured: false,
				peopleInjured: [],
				damagedProperties: [],
				monitoringCompanyCombo: {
					monitoringCompanyId: null,
					personSpokenTo: {
						firstName: '',
						lastName: '',
						telephone: '',
					},
				},
				hasMonitoringCompanyContacted: false,
				monitoringCompany: null,
				hasAlarmReactivated: false,
				additionalDetails: '',
				dateCreated: "",
			};
		},

		resetRequiredPolice(state) {
			state.selectedAlarm.policeInvolved = [];
		},

		resetInjuredPerson(state) {
			state.selectedAlarm.peopleInjured = [];
		},

		resetDamagedProperties(state) {
			state.selectedAlarm.damagedProperties = [];
		},

		resetMonitoringCompany(state) {
			state.selectedAlarm.monitoringCompany = [];
		},
	},

	actions: {
		//
	},

	getters: {
		selectedAlarm: state => state.selectedAlarm,
	},
};