import gql from 'graphql-tag';

export const listCheckpointEntries = {
	query: gql`
		{
			listCheckpointEntries {
				_id
				checkpoint
				siteLocation
				siteId
				scanTime
				guard
				guardId
				currentGeoLocation
			}
		}
	`,
	update: data => {
		let sorted = data.listCheckpointEntries.sort((a, b) => {
			return new Date(b.scanTime) - new Date(a.scanTime);
		});

		return sorted;
	},
};

export const filterCheckpointEntries = gql`
	query entriesList($siteId: String, $checkpoint: String, $guardId: String, $scanTime: String) {
		entriesList: filterCheckpointEntries(
			siteId: $siteId
			checkpoint: $checkpoint
			guardId: $guardId
			scanTime: $scanTime
		) {
			_id
			checkpoint
			siteLocation
			siteId
			scanTime
			guard
			guardId
			currentGeoLocation
		}
	}
`;

export const createCheckpointEntry = gql`
	mutation (
		$checkpoint: String
		$siteLocation: String
		$siteId: String
		$scanTime: String
		$guard: String
		$guardId: String
		$currentGeoLocation: String
	) {
		createCheckpointEntry(
			checkpoint: $checkpoint
			siteLocation: $siteLocation
			siteId: $siteId
			scanTime: $scanTime
			guard: $guard
			guardId: $guardId
			currentGeoLocation: $currentGeoLocation
		) {
			checkpoint
			siteLocation
			siteId
			scanTime
			guard
			guardId
			currentGeoLocation
		}
	}
`;

export const WATCH_CHECKPOINT_ENTRIES = gql`
	subscription {
		checkpointEntries {
			_id
			checkpoint
			siteLocation
			siteId
			scanTime
			guard
			guardId
			currentGeoLocation
		}
	}
`;

export const deleteCheckpointEntry = gql`
	mutation ($id: String) {
		deleteCheckpointEntry(id: $id) {
			checkpoint
			siteLocation
			siteId
			scanTime
			guard
			guardId
			currentGeoLocation
		}
	}
`;
