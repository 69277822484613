import gql from 'graphql-tag';

export const guard = gql`
	query guard($id: ID!) {
		selectedGuard: guard(id: $id) {
			isEnabled
			firstName
			lastName
			mobile
			email
			_id
			coaExp
			coaNumber
			licenseNumber
			isClockedIn
			nextOfKin {
				firstName
				lastName
				mobile
			}
		}
	}
`;

export const guards = {
	query: gql`
		{
			guards {
				isEnabled
				firstName
				lastName
				mobile
				email
				_id
				coaExp
				coaNumber
				licenseNumber
				isClockedIn
				nextOfKin {
					firstName
					lastName
					mobile
				}
			}
		}
	`,
	update: data => {
		return data.guards;
	},
};

export const enabledGuards = {
	query: gql`
		{
			enabledGuards {
				isEnabled
				firstName
				lastName
				mobile
				email
				_id
				coaExp
				coaNumber
				licenseNumber
				isClockedIn
				nextOfKin {
					firstName
					lastName
					mobile
				}
			}
		}
	`,
	update: data => {
		data.enabledGuards.sort((a, b) => a.firstName.localeCompare(b.firstName));
		return data.enabledGuards;
	},
};

export const disabledGuards = {
	query: gql`
		{
			disabledGuards {
				isEnabled
				firstName
				lastName
				mobile
				email
				_id
				coaExp
				coaNumber
				licenseNumber
				isClockedIn
				nextOfKin {
					firstName
					lastName
					mobile
				}
			}
		}
	`,
	update: data => {
		data.disabledGuards.sort((a, b) => a.firstName.localeCompare(b.firstName));
		return data.disabledGuards;
	},
};

export const getGuardsLocation = {
	query: gql`
		{
			guardsForMap {
				_id
				firstName
				lastName
				isClockedIn
				mobile
				location {
					lat
					lng
					timestamp
				}
			}
		}
	`,
	update: data => {
		let guardsList = data.guardsForMap;
		let filtered = guardsList.filter(guard => guard.isClockedIn === true);
		return filtered;
	},
};

export const addGuard = gql`
	mutation (
		$firstName: String!
		$isEnabled: Boolean!
		$lastName: String!
		$mobile: String!
		$email: String
		$coaExp: String
		$coaNumber: String
		$licenseNumber: String
		$nextOfKin: NextOfKinInput
		$notes: String
	) {
		addGuard(
			firstName: $firstName
			isEnabled: $isEnabled
			lastName: $lastName
			mobile: $mobile
			email: $email
			coaExp: $coaExp
			coaNumber: $coaNumber
			licenseNumber: $licenseNumber
			nextOfKin: $nextOfKin
			notes: $notes
		) {
			_id
			isEnabled
			firstName
			lastName
			mobile
			email
			coaExp
			coaNumber
			licenseNumber
			nextOfKin {
				firstName
				lastName
				mobile
			}
			notes
		}
	}
`;

export const updateGuard = gql`
	mutation (
		$id: ID!
		$isEnabled: Boolean!
		$firstName: String!
		$lastName: String!
		$mobile: String!
		$email: String
		$coaExp: String
		$coaNumber: String
		$licenseNumber: String
		$nextOfKin: NextOfKinInput
		$notes: String
		$isClockedIn: Boolean
	) {
		updateGuard(
			id: $id
			isEnabled: $isEnabled
			firstName: $firstName
			lastName: $lastName
			mobile: $mobile
			email: $email
			coaExp: $coaExp
			coaNumber: $coaNumber
			licenseNumber: $licenseNumber
			nextOfKin: $nextOfKin
			notes: $notes
			isClockedIn: $isClockedIn
		) {
			_id
			isEnabled
			firstName
			lastName
			mobile
			email
			coaExp
			isClockedIn
			coaNumber
			licenseNumber
			nextOfKin {
				firstName
				lastName
				mobile
			}
			notes
		}
	}
`;

export const deleteGuard = gql`
	mutation ($id: ID!) {
		deleteGuard(id: $id) {
			_id
			isEnabled
			firstName
			lastName
			mobile
			email
			coaExp
			coaNumber
			licenseNumber
			nextOfKin {
				firstName
				lastName
				mobile
			}
			notes
		}
	}
`;

export const SUBSCRIPTION_GUARD_UPDATE = gql`
	subscription {
		guardUpdates {
			_id
			isEnabled
			isClockedIn
		}
	}
`;

export const SUBSCRIPTION_GUARD_LOCATION = gql`
	subscription {
		guardLocation {
			_id
			firstName
			lastName
			mobile
			isClockedIn
			location {
				lat
				lng
				timestamp
			}
		}
	}
`;

export const updateGuardLocation = gql`
	mutation ($id: ID!, $isClockedIn: Boolean, $location: LocationInput) {
		updateGuardLocation(id: $id, isClockedIn: $isClockedIn, location: $location) {
			_id
		}
	}
`;
