var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-bottom-navigation',{attrs:{"background-color":!_vm.sosBtn ? `#EA690B` : `#f06262`,"app":"","dark":""}},[_c('v-slide-group',{attrs:{"center-active":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sosBtn),expression:"!sosBtn"}]},[(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6 ml-8",attrs:{"disabled":_vm.checkRoute('Dashboard'),"icon":""},on:{"click":() => {
							this.$router.push('./Dashboard');
						}}},[_c('span',[_vm._v("Dashboard")]),_c('v-icon',[_vm._v("mdi-earth")])],1):_vm._e(),(!_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6 ml-8",attrs:{"disabled":_vm.checkRoute('GuardDashboard'),"icon":""},on:{"click":() => {
							this.$router.push('./GuardDashboard');
						}}},[_c('span',[_vm._v("Dashboard")]),_c('v-icon',[_vm._v("mdi-earth")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Guards'),"icon":""},on:{"click":() => {
							this.$router.push('./Guards');
						}}},[_c('span',[_vm._v("Guards")]),_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Organizations'),"icon":""},on:{"click":() => {
							this.$router.push('./Organizations');
						}}},[_c('span',[_vm._v("Organizations")]),_c('v-icon',[_vm._v("mdi-home-account")])],1):_vm._e(),_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Reports'),"icon":""},on:{"click":() => {
							this.$router.push('./Reports');
						}}},[_c('span',[_vm._v("Reports")]),_c('v-icon',[_vm._v("mdi-alert")])],1)],1),_c('div',[(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Vehicles'),"icon":""},on:{"click":() => {
							this.$router.push('./Vehicles');
						}}},[_c('span',[_vm._v("Vehicles")]),_c('v-icon',[_vm._v("mdi-car")])],1):_vm._e(),(!_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('GuardCalendar'),"icon":""},on:{"click":() => {
							this.$router.push('GuardCalendar');
						}}},[_c('span',[_vm._v("Calendar")]),_c('v-icon',[_vm._v("mdi-calendar-month")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Calendar'),"icon":""},on:{"click":() => {
							this.$router.push('Calendar');
						}}},[_c('span',[_vm._v("Calendar")]),_c('v-icon',[_vm._v("mdi-calendar-month")])],1):_vm._e(),_c('v-btn',{staticClass:"mr-6",attrs:{"disabled":_vm.checkRoute('Settings'),"icon":""},on:{"click":() => {
							this.$router.push('./Settings');
						}}},[_c('span',[_vm._v("Settings")]),_c('v-icon',[_vm._v("mdi-cog")])],1)],1)]),_c('v-scroll-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.sosBtn),expression:"sosBtn"},{name:"touch",rawName:"v-touch",value:({
					start: () => _vm.start('Start'),
					end: () => _vm.end('End'),
				}),expression:"{\n\t\t\t\t\tstart: () => start('Start'),\n\t\t\t\t\tend: () => end('End'),\n\t\t\t\t}"}],staticClass:"disable-select",staticStyle:{"padding-right":"80px"},attrs:{"color":_vm.sosBtnColor,"height":"125","width":"600"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('h1',{staticClass:"ml-14 mt-2"},[_vm._v("S0S")])]),_c('v-card-title',{staticClass:"justify-center"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.counter > 4),expression:"counter > 4"}],staticClass:"ml-14",staticStyle:{"font-size":"12px"}},[_vm._v(" Press and Hold to Activate ")])])],1)],1),_c('v-dialog',{staticClass:"ma-2",attrs:{"max-height":"300","max-width":"270"},model:{value:(_vm.sosCounter),callback:function ($$v) {_vm.sosCounter=$$v},expression:"sosCounter"}},[_c('v-card',{attrs:{"color":"#ff0800"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('h3',{staticClass:"white--text"},[_vm._v(" SOS Activating in: "+_vm._s(_vm.counter)+" ")])])],1)],1),(!_vm.isAdmin && _vm.isMobile() && _vm.isClockedIn && _vm.$route.path == '/GuardDashboard')?_c('v-btn',{directives:[{name:"touch",rawName:"v-touch",value:({
				start: () => _vm.start('Start'),
				end: () => _vm.end('End'),
			}),expression:"{\n\t\t\t\tstart: () => start('Start'),\n\t\t\t\tend: () => end('End'),\n\t\t\t}"}],staticStyle:{"position":"fixed","top":"15vh","border-radius":"100%"},attrs:{"elevation":"10","fab":"","width":"100px","height":"100px","color":"red"}},[_c('h2',[_vm._v("SOS")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }