<template>
	<v-container>
		<v-col cols="12" v-if="isAdmin">
			<v-tabs v-model="tab" icons-and-text centered grow>
				<v-tabs-slider></v-tabs-slider>
				<v-tab @click="selectedTab(0)"> Roster <v-icon> mdi-calendar-account</v-icon> </v-tab>
				<v-divider vertical inset> </v-divider>
				<v-tab @click="selectedTab(1)">Timesheet<v-icon> mdi-clipboard-text-clock</v-icon> </v-tab>
			</v-tabs>
			<v-card>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<Roster />
					</v-tab-item>
					<v-tab-item>
						<Timesheet />
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-col>
	</v-container>
</template>

<script>
	import moment from 'moment';
	import Roster from '../views/Roster.vue';
	import Timesheet from '../views/Timesheet.vue';

	export default {
		name: 'Calendar',
		components: {
			Roster,
			Timesheet,
		},

		computed: {
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
			rosterList: {
				get() {
					return this.$store.getters['rosterList'];
				},
				set(val) {
					this.$store.commit('roster/addToRosterList', val);
				},
			},
		},

		async mounted() {
			this.tab = parseInt(localStorage.getItem('calendarTab'));
		},

		methods: {
			selectedTab(tab) {
				localStorage.setItem('calendarTab', tab);
			},
		},

		data: () => ({
			tab: null,
		}),
	};
</script>
