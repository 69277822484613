export default {
    namespaced: true,
    state: {
        selectedCheckpoint: null,
        selectedCheckpointEntry: null,
    },

    mutations: {
        setSelectedCheckpoint (state, payload) {
            state.selectedCheckpoint = payload;
        },
        setSelectedCheckpointEntry (state, payload) {
            state.selectedCheckpointEntry = payload
        },
        resetSelectedCheckpointEntry (state) {
            state.selectedCheckpointEntry = {
                checkpoint: "",
                siteLocation: null,
                siteId: "",
                scanTime: "",
                guard: null,
                guardId: "",
            };
        },
        resetSelectedCheckpoint (state) {
            state.selectedCheckpoint = {
                checkpoint: "",
                siteLocation: null,
                qrCodeUrl: "",
                siteId: "",
            };
        },
    },

    actions: {
        //
    },

    getters: {
        selectedCheckpoint: (state) => state.selectedCheckpoint,
        selectedCheckpointEntry: (state) => state.selectedCheckpointEntry,
    },
};
