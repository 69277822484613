<template>
	<v-container>
		<v-col v-if="isAdmin">
			<div :class="isMobile() ? `text-center` : `d-flex`">
				<v-icon color="secondary" :size="isMobile() ? `40` : `50`">mdi-train-car</v-icon>
				<h1 v-if="!isMobile()" class="ma-4">Vehicles</h1>
				<h3 v-else class="ma-4">Vehicles</h3>
			</div>
			<!-- Add Vehicle Dialog -->
			<v-dialog style="overflow: auto" v-model="addVehicleDialog" max-width="800" persistent>
				<v-card class="pa-6 d-flex flex-column">
					<div v-if="selectedVehicle">
						<v-row class="pa-4">
							<v-col
								cols="12"
								:class="$vuetify.breakpoint.width < 600 ? `text-center` : `text-right`"
							>
								<v-tooltip bottom v-if="!isAdd">
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											size="32"
											@click="photosPage = !photosPage"
											color="secondary"
											class="mr-2"
											v-bind="attrs"
											v-on="on"
											>{{ photosPage ? `mdi-format-align-right` : `mdi-image-multiple` }}</v-icon
										>
									</template>
									<span>{{ !photosPage ? `to Gallery` : `to Form` }}</span>
								</v-tooltip>
								<v-tooltip bottom v-if="!isAdd">
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											size="32"
											@click="confirmation()"
											color="secondary"
											class="mr-2"
											v-bind="attrs"
											v-on="on"
											:loading="isLoading"
											>mdi-delete-alert</v-icon
										>
									</template>
									<span>Delete Vehicle </span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											@click="
												() => {
													isAdd ? addVehicle() : updateVehicle();
												}
											"
											large
											color="secondary"
											class="mr-2"
											v-bind="attrs"
											v-on="on"
											:loading="isLoading"
											icon
										>
											<v-icon size="32">mdi-content-save</v-icon>
										</v-btn>
									</template>
									<span>{{ isAdd ? `Save Vehicle` : `Save Changes` }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											@click="
												() => {
													addVehicleDialog = false;
												}
											"
											large
											color="secondary"
											class="mr-2"
											v-bind="attrs"
											v-on="on"
											>mdi-close</v-icon
										>
									</template>
									<span>Exit</span>
								</v-tooltip>
							</v-col>
							<v-col cols="12" :class="$vuetify.breakpoint.width < 600 ? `text-center` : ``">
								<h3>
									{{
										isAdd && !photosPage
											? `Add Vehicle`
											: photosPage
											? `Gallery`
											: `Change Vehicle Details`
									}}
								</h3>
							</v-col>
						</v-row>

						<div v-if="selectedVehicle && !photosPage">
							<v-container>
								<v-form ref="form" lazy-validation style="min-height: 478px">
									<v-row>
										<v-col cols="12" xs="12" sm="12" md="6" lg="6">
											<v-text-field
												v-model="selectedVehicle.make"
												outlined
												label="Make"
												:rules="[val => !!val || `Required`]"
											/>
											<v-text-field
												v-model="selectedVehicle.model"
												outlined
												label="Model"
												:rules="[val => !!val || `Required`]"
											/>
											<v-text-field
												v-model="selectedVehicle.year"
												outlined
												label="Year"
												:rules="[val => !!val || `Required`]"
											/>
											<v-text-field v-model="selectedVehicle.color" outlined label="Colour" />

											<v-text-field
												v-model="selectedVehicle.rego"
												outlined
												label="Rego Number"
												:rules="[val => !!val || `Required`]"
											/>
										</v-col>
										<v-col cols="12" xs="12" sm="12" md="6" lg="6">
											<v-dialog
												ref="nextServiceDateDialog"
												v-model="nextServiceDateDialog"
												:return-value.sync="selectedVehicle.nextServiceDate"
												persistent
												width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														:value="convertDate(selectedVehicle.nextServiceDate)"
														outlined
														label="Next Service Date"
														readonly
														v-bind="attrs"
														v-on="on"
														append-icon="mdi-calendar"
														:rules="[val => !!val || `Required`]"
													></v-text-field>
												</template>
												<v-date-picker v-model="selectedVehicle.nextServiceDate" scrollable>
													<v-spacer></v-spacer>
													<v-btn text color="primary" @click="nextServiceDateDialog = false">
														Cancel
													</v-btn>
													<v-btn
														text
														color="primary"
														@click="
															$refs.nextServiceDateDialog.save(selectedVehicle.nextServiceDate)
														"
													>
														OK
													</v-btn>
												</v-date-picker>
											</v-dialog>

											<v-dialog
												ref="regoExpiryDialog"
												v-model="regoExpiryDialog"
												:return-value.sync="selectedVehicle.regoExpiry"
												persistent
												width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														:value="convertDate(selectedVehicle.regoExpiry)"
														outlined
														label="Rego Expiry Date"
														readonly
														v-bind="attrs"
														v-on="on"
														append-icon="mdi-calendar"
														:rules="[val => !!val || `Required`]"
													></v-text-field>
												</template>
												<v-date-picker v-model="selectedVehicle.regoExpiry" scrollable>
													<v-spacer></v-spacer>
													<v-btn text color="primary" @click="regoExpiryDialog = false">
														Cancel
													</v-btn>
													<v-btn
														text
														color="primary"
														@click="$refs.regoExpiryDialog.save(selectedVehicle.regoExpiry)"
													>
														OK
													</v-btn>
												</v-date-picker>
											</v-dialog>

											<!-- <v-text-field
                      v-model="selectedVehicle.wofExpiry"
                      outlined
                      label="WoF Expiry"
                    /> -->

											<v-dialog
												ref="wofExpiryDialog"
												v-model="wofExpiryDialog"
												:return-value.sync="selectedVehicle.wofExpiry"
												persistent
												width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														:value="convertDate(selectedVehicle.wofExpiry)"
														outlined
														label="WoF Expiry Date"
														readonly
														v-bind="attrs"
														v-on="on"
														append-icon="mdi-calendar"
														:rules="[val => !!val || `Required`]"
													></v-text-field>
												</template>
												<v-date-picker v-model="selectedVehicle.wofExpiry" scrollable>
													<v-spacer></v-spacer>
													<v-btn text color="primary" @click="wofExpiryDialog = false">
														Cancel
													</v-btn>
													<v-btn
														text
														color="primary"
														@click="$refs.wofExpiryDialog.save(selectedVehicle.wofExpiry)"
													>
														OK
													</v-btn>
												</v-date-picker>
											</v-dialog>
											<v-textarea
												v-model="selectedVehicle.notes"
												height="142"
												outlined
												overflow
												label="Description"
												no-resize
											/>
										</v-col>
										<v-col md="6"> </v-col>
									</v-row>
								</v-form>
							</v-container>
						</div>
						<div
							v-if="selectedVehicle && photosPage"
							style="
								minheight: 475px;
								maxheight: 475px;
								overflow-y: scroll;
								margin: 0 auto;
								min-height: 478px;
							"
							class="text-center"
						>
							<template>
								<v-btn color="orange" dark @click="addPhoto">
									Add Photo
									<input
										class="d-none"
										ref="uploader"
										type="file"
										accept="image/*"
										@change="onFileChanged"
									/>
								</v-btn>
							</template>
							<v-row class="ma-6 d-flex">
								<v-col
									:cols="isMobile() ? `12` : `4`"
									v-for="(item, i) in displayList"
									:key="i"
									class="text-center"
									style="background-color: #669acc"
								>
									<a :href="item" target="_blank">
										<v-img
											width="100%"
											height="250px"
											max-width="100%"
											max-height="250px"
											:src="item"
											:alt="`carphoto ` + i"
										/>
									</a>
									<v-icon color="error" @click="removePhoto(i)">mdi-close</v-icon>
								</v-col>
							</v-row>
							<!-- <v-row
                class="ma-6"
                v-for="(photo, i) in selectedVehicle.photos"
                :key="i"
              >
                <a :href="photo">{{photo}}</a> <span class="ml-4">
                  <v-icon
                    color="error"
                    @click="removePhoto(i)"
                  >mdi-close</v-icon>
                </span>
              </v-row> -->
						</div>
					</div>
				</v-card>
			</v-dialog>

			<!-- delete confirmation dialog -->
			<v-dialog color="red" v-model="confirmDelete" persistent max-width="290">
				<v-card>
					<v-card-title class="text-h5"> Delete Vehicle </v-card-title>
					<v-card-text>Are you sure you want to remove this vehicle?</v-card-text>
					<v-card-actions class="justify-center">
						<v-spacer></v-spacer>
						<v-btn class="white--text" color="green darken-1" text @click="confirmDelete = false">
							Cancel
						</v-btn>
						<v-btn class="white--text" color="red darken-1" text @click="deleteVehicle">
							Confirm
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<!-- container carrying vehicle cards -->
			<v-container fluid>
				<v-row>
					<v-col
						v-for="(vehicle, i) in vehiclesList"
						:key="i"
						cols="12"
						lg="3"
						md="4"
						sm="4"
						xs="12"
					>
						<v-expansion-panels accordion hover>
							<v-expansion-panel>
								<v-expansion-panel-header hide-actions class="pa-0">
									<!-- If you want image-->
									<v-img
										class="rounded"
										height="145px"
										alt="Vehicle Image"
										:src="
											vehicle.photos.length > 0
												? vehicle.photos[0]
												: `https://img.freepik.com/free-vector/car-concept-car-sketch-vector-hand-drawn-autodesign-automobile-drawing-modern-car-vector-sketch-illustration_231873-2146.jpg`
										"
									>
										<h2 class="black--text pt-2 pl-2" style="-webkit-text-stroke: 0.02em white">
											{{ vehicle.year }}
											{{ vehicle.make }}
											{{ vehicle.model }}
										</h2>
									</v-img>
								</v-expansion-panel-header>
								<!-- Expansion Panel Content -->
								<v-expansion-panel-content class="text--black mt-4">
									<p>
										<b>Make:</b> {{ vehicle.make }} <br />

										<b>Model:</b> {{ vehicle.model }} <br />

										<b>Year:</b> {{ vehicle.year }} <br />

										<b>Colour:</b> {{ vehicle.color }} <br />

										<b>Rego Number:</b> {{ vehicle.rego }} <br />

										<b>Rego Expiry:</b> {{ convertDate(vehicle.regoExpiry) }}
										<br />

										<b>WoF Due:</b> {{ convertDate(vehicle.wofExpiry) }} <br />

										<b>Next Service Due:</b>
										{{ convertDate(vehicle.nextServiceDate) }} <br />

										<b>Vehicle Description:</b> <br />
										{{ vehicle.notes | limitText }}
									</p>

									<v-row class="d-flex flex-row-reverse pb-2">
										<div style="width: 100%" :class="isMobile() ? `text-center` : `text-right`">
											<v-tooltip top>
												<template #activator="{ on, attrs }">
													<!-- <v-btn
                        
                        v-bind="attrs"
                        v-on="on"
                        icon
                        width="100%"
                      > -->
													<v-icon v-bind="attrs" v-on="on" @click="selectVehicle(vehicle)">
														mdi-pencil
													</v-icon>
													<!-- </v-btn> -->
												</template>
												Edit Vehicle
											</v-tooltip>
										</div>
									</v-row>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>
					<v-tooltip v-if="!isMobile() && showAddBtn" top>
						<template #activator="{ on, attrs }">
							<v-btn
								class="mt-3 ml-3 mr-3"
								@click="initAddVehicle()"
								v-bind="attrs"
								v-on="on"
								color="#EA690B"
								width="260px"
								height="145px"
							>
								<v-icon color="white"> mdi-plus </v-icon>
							</v-btn>
						</template>
						Add Vehicle
					</v-tooltip>
				</v-row>
			</v-container>
			<v-fab-transition>
				<v-btn
					v-show="isMobile()"
					color="secondary"
					dark
					fixed
					bottom
					right
					fab
					class="mb-12"
					@click="initAddVehicle()"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>
		</v-col>
		<!-- notification  -->
		<v-snackbar v-model="notify" class="pt-16" top right :timeout="2500" :color="color">
			{{ notificationText }}
		</v-snackbar>
	</v-container>
</template>

<script>
	import { vehicles, addVehicle, updateVehicle, deleteVehicle } from '../graphql/vehicles';
	import moment from 'moment';
	import axios from 'axios';

	export default {
		methods: {
			convertDate(d) {
				return d && d.length > 0 ? moment(d).format('DD/MM/yyyy') : '';
			},
			removePhoto(i) {
				this.filesList.splice(i, 1);
				this.selectedVehicle.photos.splice(i, 1);
				this.displayList.splice(i, 1);
			},
			addPhoto() {
				window.addEventListener('focus', () => {
					once: true;
				});
				this.$refs.uploader.click();
			},
			async onFileChanged(e) {
				let selectFile = e.target.files[0];
				this.filesList.push(selectFile);
				this.displayList.push(URL.createObjectURL(selectFile));
			},
			isMobile() {
				if (this.$vuetify.breakpoint.width < 800) {
					return true;
				}
			},

			initAddVehicle() {
				this.isAdd = true;
				this.$store.commit('resetSelectedVehicle');
				this.addVehicleDialog = true;
			},

			async addVehicle() {
				this.isLoading = true;
				let valid = this.$refs.form.validate();
				if (!valid) {
					this.isLoading = false;
					return;
				}
				if (this.isAdd) {
					await this.$apollo.mutate({
						mutation: addVehicle,
						variables: {
							...this.selectedVehicle,
						},
						update: (store, { data: { addVehicle } }) => {
							this.vehiclesList.push(addVehicle);
							(this.notificationText = 'Vehicle Saved'),
								(this.color = 'green darken-1'),
								(this.notify = true);
						},
					});
					this.isLoading = false;
					this.addVehicleDialog = false;
				} else {
					this.updateVehicle();
				}
			},

			async deleteVehicle() {
				this.isLoading = true;

				let res = await this.$apollo.mutate({
					mutation: deleteVehicle,
					variables: {
						id: this.selectedVehicle.id,
					},
				});
				this.vehiclesList = await this.vehiclesList.filter(
					obj => obj.id != res.data.deleteVehicle['_id']
				);
				this.vehiclesList = await this.vehiclesList.filter(
					obj => obj['_id'] != res.data.deleteVehicle['_id']
				);
				this.confirmDelete = false;
				this.isLoading = false;
				this.addVehicleDialog = false;
				(this.notificationText = 'Vehicle Removed'), (this.color = 'red'), (this.notify = true);
			},

			async uploadFiles() {
				for (var i = 0; i < this.filesList.length; i++) {
					var formData = new FormData();
					formData.append('file', this.filesList[i]);

					let res = await axios.post('https://apigw.guardex.co.nz/uploadCar', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: 'Bearer ' + localStorage.getItem('apollo-token'),
						},
					});

					if (res.data.responseCode == 200) {
						this.selectedVehicle.photos.push(
							'https://apigw.guardex.co.nz/' + res.data.body.filepath
						);
					}
				}
			},

			async updateVehicle() {
				this.isLoading = true;
				this.photosPage = false;
				setTimeout(async () => {
					let valid = this.$refs.form.validate();
					await this.uploadFiles();
					if (valid) {
						await this.$apollo.mutate({
							mutation: updateVehicle,
							variables: {
								...this.selectedVehicle,
							},
						});
						this.addVehicleDialog = false;
						this.isLoading = false;
						setTimeout(() => {
							(this.notificationText = 'Vehicle Updated'),
								(this.color = 'green darken-1'),
								(this.notify = true);
						}, 25);
					}
				}, 30);
			},

			selectVehicle(vehicle) {
				this.photosPage = false;
				this.$store.commit('setSelectedVehicle', vehicle);
				(this.isAdd = false), (this.addVehicleDialog = true);

				this.displayList = this.displayList.concat(this.selectedVehicle.photos);
			},

			confirmation() {
				this.confirmDelete = true;
			},
		},

		apollo: {
			vehiclesList: vehicles,
		},

		watch: {
			addVehicleDialog: function (val) {
				if (!val) {
					this.filesList = [];
					this.displayList = [];
					this.photosPage = false;
					this.$store.commit('resetSelectedVehicle');
				}
			},
			vehiclesList: function (val) {
				this.showAddBtn = true;
			},
		},

		computed: {
			selectedVehicle: {
				get() {
					return this.$store.getters['selectedVehicle'];
				},
			},
			isAdmin: {
				get() {
					return this.$store.getters['isAdmin'];
				},
			},
		},

		filters: {
			limitText: function (value) {
				if (value.length > 20) {
					value = value.substring(0, 64) + '...';
				}
				return value;
			},
		},

		components: {
			//
		},

		data: () => ({
			isLoading: false,
			rules: {
				important: val => !!val || `Required`,
			},
			showAddBtn: false,
			displayList: [],
			filesList: [],
			rules: [value => !value || value.size < 3000000 || 'Photo size should be less than 3 MB!'],
			photosPage: false,
			isAdd: false,
			addVehicleDialog: false,
			vehiclesList: [],
			vehicleNote: '',
			vehicleIndex: null,
			confirmDelete: false,
			nextServiceDateDialog: false,
			regoExpiryDialog: false,
			wofExpiryDialog: false,

			//snackbar notification
			notify: false,
			notificationText: '',
			color: '',
		}),
	};
</script>

<style scoped>
	/* Hide scrollbar for Chrome, Safari and Opera */
	.hide-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.hide-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
</style>
