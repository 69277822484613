<template>

  <!-- Incident Report Email Template -->
  <v-container class="pl-2">
    <v-card-title class="px-0">
      <h3 class="pb-5 pt-4"> {{ switchModel ? 'Set Alarm Email Template' : 'Set Incident Email Template' }} </h3>
      <v-spacer></v-spacer>
      <v-tooltip
        color="secondary"
        bottom
      >
        <template #activator="{ on, attrs}">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mr-3"
            @click="showHint = true"
          > mdi-help-circle</v-icon>
        </template>
        <span> Formatting tips </span>
      </v-tooltip>
      <v-switch
        v-model="switchModel"
        inset
      />
    </v-card-title>
    <!-- incident email template -->
    <div v-show="!switchModel">
      <v-text-field
        v-model="selectedAlertTime.incidentEmailTemplate.emailSubject"
        color="secondary"
        label="Incident Report Subject"
        outlined
      />

      <v-text-field
        v-model="selectedAlertTime.incidentEmailTemplate.emailHeader"
        color="secondary"
        label="Incident Report Header"
        outlined
      />

      <v-textarea
        v-model="selectedAlertTime.incidentEmailTemplate.emailBody"
        color="secondary"
        label="Incident Report Body"
        outlined
      />
      <v-text-field
        v-model="selectedAlertTime.incidentEmailTemplate.emailFooter"
        color="secondary"
        label="Incident Report Footer"
        outlined
      />
    </div>

    <!-- Alarm Report Email Template -->
    <div v-show="switchModel">
      <v-text-field
        v-model="selectedAlertTime.alarmEmailTemplate.emailSubject"
        color="secondary"
        label="Alarm Report Subject"
        outlined
      />

      <v-text-field
        v-model="selectedAlertTime.alarmEmailTemplate.emailHeader"
        color="secondary"
        label="Alarm Report Header"
        outlined
      />

      <v-textarea
        v-model="selectedAlertTime.alarmEmailTemplate.emailBody"
        color="secondary"
        label="Alarm Report Body"
        outlined
      />
      <v-text-field
        v-model="selectedAlertTime.alarmEmailTemplate.emailFooter"
        color="secondary"
        label="Alarm Report Footer"
        outlined
      />
    </div>

    <v-dialog
      v-model="showHint"
      max-width="400"
    >
      <v-card class="mx-auto pa-6">
        <h2>Variables</h2>
        <br>
        <h4><span style="color:blue">MANAGERNAME</span> = Default Manager Name</h4>
        <h4><span style="color:blue">SITENAME</span> = Site Location Name</h4>
        <h4><span style="color:blue">&lt;br&gt;</span> = New Line</h4>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  methods: {
    saveEmailSendTime () {
      this.expiryNotificationDialog = false;
    },

    resetFields () {
      this.selectedNumberDate = "";
      this.selectedTime = "";
    },

    addCustomAlert () {
      if (this.selectedTime.length < 3) {
        return false;
      } else if (this.selectedNumberDate.length < 0) {
        return false;
      } else this.customAlert = false;
      this.timeFrameList.push({
        customLabel:
          this.selectedNumberDate + " " + this.selectedTime + " before",
      });

      this.selectedNumberDate = "";
      this.selectedTime = "";
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 58) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted () {
    //
  },

  computed: {
    hourListComputed () {
      let hourList = [];
      for (var i = 0; i < 24; i++) {
        hourList.push(("0" + i).slice(-2));
      }
      return hourList;
    },

    minutesListComputed () {
      let minutesList = [];
      for (var i = 0; i < 60; i++) {
        minutesList.push(("0" + i).slice(-2));
      }
      return minutesList;
    },

    selectedAlertTime: {
      get () {
        return this.$store.getters["selectedAlertTime"];
      },
    },
  },

  data: () => ({
    showHint: false,
    switchModel: false,
    tabs: null,
    setSendEmailTimeDialog: false,
    customAlert: false,
    alertBefore: false,
    weekBefore: "",
    dayBefore: "",
    monthBefore: "",
    max: 3,
    timeFrameList: [],
    timesList: ["Days", "Weeks", "Months"],
    selectedNumberDate: "",
    selectedTime: "",
    regoExpiry: null,
    nextService: null,
    wofDue: null,
    sendEmailTime: null,
  }),
};
</script>
